import React, { useState } from 'react';
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col
} from 'react-bootstrap';
import { Youtube, Copy, Download } from 'lucide-react';
import axios from 'axios';

const YouTubeTranscript = () => {
  const [url, setUrl] = useState('');
  const [transcript, setTranscript] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setTranscript(null);

    try {
        const dev = 'https://yt-api-eight.vercel.app/api/youtube/transcript';
        const local = 'http://localhost:3001/api/youtube/transcript';        
        // const response = await axios.get(`http://localhost:3001/api/youtube/transcript?url=${encodeURIComponent(url)}`);

        const response = await axios.get(`${dev}?url=${encodeURIComponent(url)}`);
      setTranscript(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to get transcript');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    if (transcript) {
      navigator.clipboard.writeText(transcript.transcript.map(t => t.text).join('\n'));
    }
  };

  const downloadTranscript = () => {
    if (transcript) {
      const text = transcript.transcript.map(t => t.text).join('\n');
      const blob = new Blob([text], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${transcript.title.replace(/[^\w\s-]/g, '')}-transcript.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  return (
    <Container className="py-5">
      <Card className="shadow">
        <Card.Header className="bg-primary text-white text-center py-3">
          <h2 className="mb-0 d-flex align-items-center justify-content-center">
            <Youtube size={32} className="me-2" />
            YouTube Transcript Generator
          </h2>
        </Card.Header>

        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter YouTube URL..."
                  disabled={loading}
                />
                <Button 
                  type="submit" 
                  variant="primary" 
                  disabled={loading}
                  style={{ minWidth: '120px' }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Generate'
                  )}
                </Button>
              </div>
            </Form.Group>

            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}

            {transcript && (
              <div className="mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="mb-0">{transcript.title}</h4>
                  <div className="d-flex gap-2">
                    <Button
                      variant="outline-secondary"
                      onClick={copyToClipboard}
                      className="d-flex align-items-center gap-2"
                    >
                      <Copy size={16} />
                      Copy
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={downloadTranscript}
                      className="d-flex align-items-center gap-2"
                    >
                      <Download size={16} />
                      Download
                    </Button>
                  </div>
                </div>

                <div className="bg-light p-3 rounded" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {transcript.transcript.map((line, index) => (
                    <p key={index} className="mb-2">
                      {line.text}
                    </p>
                  ))}
                </div>

                {transcript.isAutoGenerated && (
                  <Alert variant="info" className="mt-3">
                    This transcript was automatically generated and may contain errors.
                  </Alert>
                )}
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default YouTubeTranscript;