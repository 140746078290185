import {React, useState, useEffect, useContext} from "react";
import {Button, Card, Container, NavLink, Row, Col, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast'; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";  
import CommonDataServices from "../../../services/common.services";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel";  
import Modal from 'react-bootstrap/Modal';

    import "./Admin.css"; 
    import ProgressBar from 'react-bootstrap/ProgressBar'
    import {
      Chart as ChartJS,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
    } from 'chart.js';
    import { Bar } from 'react-chartjs-2';
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import { PaperPlaneIcon } from "../../../assets/svg/SvgIcons";

    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );

    export const options = {
      responsive: true,
      plugins: {         
        title: {
          display: true,
          text: 'Daily usage',
        },
      },
    };
     
const Admin = () => { 
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const { user } = useUserAuth();
  const navigate = useNavigate(); 
  const functions = getFunctions();  
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [subscription, setSubscription] = useState(null);   
  const [subState, setSubState] = useState(false)
  const [alertMsg, setAlertMsg] = useState()  
  const [showModal, setShowModal] = useState(false); 
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const [cusLoad, setCusLoad] = useState(false); 
  const [inputValue, setInputValue] = useState('');
  const [userDetails, setUserDetails] = useState(null);

  //['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan']
  //['20', '50', '60', '20', '30', '12', '60'] 
  
  const [offerCode, setOfferCode] = useState()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); 
  //  console.log('Account information updated:', { firstName, lastName, email });
    const userObj = { firstName, lastName, email }
    CommonDataServices.updateUserSubscription(user.uid, userObj)
    toast.success('Account information updated!') 
  };  
  const [barData, setBarData] = useState({
                          labels: [],
                          datasets: [
                            {
                              label: 'Usage',
                              barPercentage: 0.5,
                              barThickness: 8,
                              maxBarThickness: 8,
                              minBarLength: 2,
                              data: [],
                              backgroundColor: 'rgb(84 42 223)',
                            }
                          ],
                        });
   
 
  

 const getCodeData = async (e) => {
  e.preventDefault(); 
  setUserDetails();
  if (inputValue.length === 7) { 
   // console.log('Submitted input value:', inputValue);
    const offId = inputValue; 
    try{
            const getData = await CommonDataServices.getOfferCodeData(offId);  
            // console.log(offerCode)
           // console.log(getData.data())
            setOfferCode(getData.data())   
       } catch (error) {
        // console.log(error.message); 
      } 
  } else {
    alert('Please enter a 7-letter string.');
  } 
 }  
 
 
 useEffect(() => {
    fetchUserDetails(); 
 }, [offerCode])
 
    const handleClose = (e) => {
         setShowModal(false);    
    } 

    const handleInputChange = (e) => {
         setInputValue(e.target.value);
    }; 
    const formatDate = (timestamp) => {
         return new Date(timestamp.seconds * 1000).toLocaleDateString();
    };
    const fetchUserDetails = async () => {
         
        if (!offerCode){
            return
        }
        const userId = offerCode.redeemedBy;
       
        const functionsUrl = 'https://us-central1-wordkraft-bb683.cloudfunctions.net/getUserDetails'; // Replace with your Firebase Function URL
        try {
          const response = await fetch(`${functionsUrl}?uid=${userId}`);
          if (response.ok) {
            const data = await response.json();
             console.log(data)
            setUserDetails(data);
            return data;
          } else {
            throw new Error('Error fetching user details');
          }
        } catch (error) {
          console.error('Error getting user details:', error);
        }
      };

  const handleButtonDelete = async () => { 
    const updateData = {
      paidAmount: 0,
      totalWords: 0,
      wordsLimit: 1500,
      planType: "free",
      premiumState: true,
      codeStatus: "removed"
    };
    const fieldsToRemove = ['renewalDate', 'planDuration', 'redeemedCodes'];
    let userid;
    if(offerCode){
      userid = offerCode.redeemedBy;
    }  
    try {
      await CommonDataServices.updateAndDeleteUserData(userid, updateData, fieldsToRemove);
      console.log('User data updated successfully!');
      toast.success('User data updated successfully!');
    } catch (error) {
      console.error('Failed to update user data:', error);
      toast.error('Failed to update user data:', error);
    }
  };

  return (
    <>  
    {(user.uid == "T17GdgnPxLeO0EnnlogbYcHdM0Y2") && <> 
      <div className="main-layout profile page-layout">
                <div className="header-txt text-center"> 
                    <h2>Admin</h2> 
                    <p>Check user data </p>
               </div> 
               <div className="container justify-content-center">
            <div className="row">
                <div className="col-sm-12">
                    {/* <h5 className="pb-2">Account Information</h5> */}
                    <Card className="mb-5"> 
                      <Card.Body>
                      <div className="wrapper">
    
                      <div  className="py-4">
    <Row className="justify-content-md-center">
        <Col md="8">
          <Form onSubmit={getCodeData} className="text-center"> 
              <Form.Label>AppSumo Offer Code</Form.Label>
      <div className="input-group code-input-group">
            <Form.Group controlId="inputString" className="inputString">
              <div className="form-outline">
                        <input className="form-control" 
                        type="text"
                        maxLength="7"
                        value={inputValue} 
                        onChange={handleInputChange}
                        placeholder="Enter Offer Code"/> 
                </div> 
            </Form.Group>
            <button type="submit" className="btn btn-primary">
            <PaperPlaneIcon/> Submit 
          </button> 
            </div>
          </Form>
        </Col>
      </Row>
      {offerCode && 
      <Row className="justify-content-md-center">
        <Col md="auto" >
          <h5>Offer Code Details</h5>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Code</td>
                <td>{offerCode.code}</td>
              </tr>
              <tr>
                <td>Active</td>
                <td>{offerCode.isActive ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Redeemed</td>
                <td>{offerCode.isRedeemed ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Redemption Date</td>
                <td>{offerCode.redemptionDate && formatDate(offerCode.redemptionDate)}</td>
              </tr>
              <tr>
                <td>Expiry Date</td>
                <td>{formatDate(offerCode.expiryDate)}</td>
              </tr>
              <tr>
                <td>Redeemed By</td>
                <td>{offerCode.redeemedBy && <>
                  {offerCode.redeemedBy} 
                </>
                }</td>
              </tr> 
              {userDetails ? (
                <>
                    <tr>
                        <td>Email ID:</td>
                        <td>{userDetails.email}</td>
                    </tr> 
                    {/* <tr>
                        <td>Created Date:</td>
                        <td>{userDetails.createdDate}</td>
                    </tr> */}
                </> ) : (
                    <p>Loading user details...</p>          
                 )} 
            </tbody>
          </Table> 
        </Col>
      </Row>}

      {offerCode && offerCode.redeemedBy &&
         <Button variant="secondary" size="md" className="mr-4 float-right" onClick={handleButtonDelete}> Remove Code </Button> 
      } 
    </div>
                      </div>
                      </Card.Body>
                      </Card>
                </div>
               </div>
               </div>
        </div> 
      <Toaster position="top-center" reverseOrder={false} />
      <Modal
        show={showModal}
        onHide={handleClose} 
        keyboard={false}
        className="cancelSubscriptionModal">  
        <Modal.Body className="text-center">
              <h4>Cancel Subscription?</h4>
              <p>Your subscription will be cancelled at the end of your billing period. You can change your mind any time before this date.</p>  
          <div className="text-center">   
            <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Not Now </Button> 
         
         </div>
        </Modal.Body> 
      </Modal> 
</>   }; </> );
};

export default Admin;