import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubscriptionStatusHandler from './SubscriptionStatusHandler';

function PaymentStatus() {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h4 className="text-center mb-4">Payment Status</h4>
          <SubscriptionStatusHandler />
        </Col>
      </Row>
    </Container>
  );
}

export default PaymentStatus;