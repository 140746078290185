import React, { useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Alert,
  Spinner,
  Image
} from 'react-bootstrap';
import axios from 'axios';

const YouTubeDownloader = () => {
  const [url, setUrl] = useState('');
  const [videoInfo, setVideoInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const getVideoInfo = async () => {
    try {
      setLoading(true);
      setError('');    
      const dev = 'https://youtube-api-o77o.onrender.com/api/youtube/video-info';
      const response = await axios.get(`${dev}?url=${encodeURIComponent(url)}`);
      setVideoInfo(response.data);
      // Set first format as default
      if (response.data.formats && response.data.formats.length > 0) {
        setSelectedFormat(response.data.formats[0].itag);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch video information');
      setVideoInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!url.trim()) {
      setError('Please enter a YouTube URL');
      return;
    }
    getVideoInfo();
  };

  const handleDownload = async () => {
    if (!selectedFormat) return;
    
    try {
      setDownloading(true);
      setError('');

      // Create download URL
      const dev2 = 'https://yt-api-eight.vercel.app/api/youtube/download';
      const dev = 'https://youtube-api-o77o.onrender.com/api/youtube/download';
      const local = 'http://localhost:3001/api/youtube/download';  
      const downloadUrl = `${dev}?url=${encodeURIComponent(url)}&itag=${selectedFormat}`;
      
      // Create an invisible anchor element to trigger download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', ''); // This will preserve the server's suggested filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    } catch (err) {
      setError('Failed to start download. Please try again.');
      console.error('Download error:', err);
    } finally {
      setDownloading(false);
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Container className="py-5">
      <Card className="shadow-lg">
        <Card.Header className="bg-primary text-white text-center py-4">
          <h1 className="mb-0">Youtube Video Downloader</h1>
          <p className="mb-0 mt-2">
            Download YouTube Videos in HD Quality and ZERO Watermarks
          </p>
        </Card.Header>

        <Card.Body className="p-4">
          <Form onSubmit={handleSubmit}>
            <div className="d-flex gap-2">
              <Form.Group className="flex-grow-1 mb-0">
                <Form.Control
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="https://www.youtube.com/watch?v=..."
                  disabled={loading}
                />
              </Form.Group>
              <Button 
                type="submit" 
                variant="primary" 
                disabled={loading}
                className="px-4"
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Search'
                )}
              </Button>
            </div>
          </Form>

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}

          {videoInfo && (
            <div className="mt-4">
              <Card className="bg-light">
                <Card.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <Image
                        src={videoInfo.thumbnail}
                        alt={videoInfo.title}
                        fluid
                        className="rounded"
                      />
                    </div>
                    <div className="col-md-8">
                      <h5 className="mb-3">{videoInfo.title}</h5>
                      <p className="text-muted">
                        Duration: {formatDuration(videoInfo.lengthSeconds)}
                      </p>
                      
                      <div className="d-flex gap-2 mt-3">
                        <Form.Select
                          value={selectedFormat}
                          onChange={(e) => setSelectedFormat(e.target.value)}
                          className="flex-grow-1"
                          disabled={downloading}
                        >
                          <optgroup label="Video with Audio">
                            {videoInfo.groupedFormats?.videoWithAudio?.map(format => (
                              <option key={format.itag} value={format.itag}>
                                {format.quality} - {format.container} ({format.size})
                              </option>
                            ))}
                          </optgroup>
                          <optgroup label="Video Only">
                            {videoInfo.groupedFormats?.videoOnly?.map(format => (
                              <option key={format.itag} value={format.itag}>
                                {format.quality} - {format.container} ({format.size})
                              </option>
                            ))}
                          </optgroup>
                        </Form.Select>
                        
                        <Button
                          variant="success"
                          onClick={handleDownload}
                          disabled={!selectedFormat || downloading}
                          className="px-4"
                        >
                          {downloading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            'Download'
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default YouTubeDownloader;