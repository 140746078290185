import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Button, Form, Alert, Pagination } from 'react-bootstrap';
import { Trash, ArrowLeft } from 'lucide-react';
import { useUserAuth } from "../../context/UserAuthContext";
import * as chatService from './AIChatServices';

const RecentChats = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [chats, setChats] = useState([]);
  const [selectedChats, setSelectedChats] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    if (!user) {
      navigate('/chat');
      return;
    }

    const unsubscribe = chatService.listenToChats(
      user.uid,
      setChats,
      setError
    );

    return () => unsubscribe();
  }, [user, navigate]);

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentChats = chats.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(chats.length / itemsPerPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    let items = [];

    if (startPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => setCurrentPage(1)} />,
        <Pagination.Prev 
          key="prev" 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
        />
      );
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Next 
          key="next" 
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
        />,
        <Pagination.Last 
          key="last" 
          onClick={() => setCurrentPage(totalPages)} 
        />
      );
    }

    return items;
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedChats(currentChats.map(chat => chat.id));
    } else {
      setSelectedChats([]);
    }
  };

  const handleSelectChat = (chatId) => {
    setSelectedChats(prev => {
      if (prev.includes(chatId)) {
        return prev.filter(id => id !== chatId);
      }
      return [...prev, chatId];
    });
  };

  const handleDeleteSelected = async () => {
    setLoading(true);
    try {
      await Promise.all(
        selectedChats.map(chatId => chatService.deleteChat(chatId, user.uid))
      );
      setSelectedChats([]);
    } catch (error) {
      setError('Failed to delete chats: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    if (window.confirm('Are you sure you want to delete all chats? This cannot be undone.')) {
      setLoading(true);
      try {
        await Promise.all(
          chats.map(chat => chatService.deleteChat(chat.id, user.uid))
        );
      } catch (error) {
        setError('Failed to delete all chats: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container className='main-layout'>
      <div className="header">
        <Button 
          variant="link" 
          className="back-button"
          onClick={() => navigate('/chat')}
        >
          <ArrowLeft /> Back to Chat
        </Button>
        <h4 className='page-title'>All Chats</h4>
        <div className="actions">
          {selectedChats.length > 0 && (
            <Button 
              variant="danger" 
              onClick={handleDeleteSelected}
              disabled={loading}
            >
              Delete Selected ({selectedChats.length})
            </Button>
          )}
          <Button 
            variant="outline-danger" 
            onClick={handleDeleteAll}
            disabled={loading}
          >
            Delete All Chats
          </Button>
        </div>
      </div>

      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      <Table responsive>
        <thead>
          <tr>
            <th>
              <Form.Check
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedChats.length === currentChats.length}
              />
            </th>
            <th>Title</th>
            <th>Last Message</th>
            <th>Model</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentChats.map(chat => (
            <tr 
              key={chat.id}
              className={selectedChats.includes(chat.id) ? 'selected' : ''}
            >
              <td>
                <Form.Check
                  type="checkbox"
                  checked={selectedChats.includes(chat.id)}
                  onChange={() => handleSelectChat(chat.id)}
                />
              </td>
              <td 
                className="chat-title"
                onClick={() => navigate(`/chat/${chat.id}`)}
              >
                {chat.metadata?.title || `Chat ${chat.id.substring(0, 8)}`}
              </td>
              <td>{chat.metadata?.lastMessage?.text || 'No messages'}</td>
              <td>{chat.metadata?.model || 'Not specified'}</td>
              <td>
                {chat.metadata?.updatedAt?.toDate?.().toLocaleString() || 'Unknown'}
              </td>
              <td>
                <Button
                  variant="link"
                  className="delete-btn"
                  onClick={() => chatService.deleteChat(chat.id, user.uid)}
                  disabled={loading}
                >
                  <Trash size={16} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {chats.length > itemsPerPage && (
        <Pagination className="mt-3 justify-content-center">
          {renderPagination()}
        </Pagination>
      )}
    </Container>
  );
};

export default RecentChats;