import React, { useState } from 'react';
import { Form, Alert, Button, Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoogleAdIcon } from '../assets/svg/SvgIcons';
import { useUserAuth } from '../context/UserAuthContext';
import './loginForm.css';

const InlineLogin = ({ show, onHide, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { logIn, googleSignIn } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const user = await logIn(email, password);
      if (onLoginSuccess) {
        onLoginSuccess(user);
      }
      if (onHide) onHide();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      const user = await googleSignIn();
      if (onLoginSuccess) {
        onLoginSuccess(user);
      }
      if (onHide) onHide();
    } catch (error) {
      setError(error.message);
    }
  };

  const loginContent = (
    <div className="inlineLogin">
      <Card className="imageCard">
        <div className="imagekraft-benifts">
          <strong>Benefits of Account</strong>
          <ul className="mt-3">
            <li><span className="ico">✅</span> Access 250+ AI Tools</li>
            <li><span className="ico">✅</span> Keyword Research</li>
            <li><span className="ico">✅</span> Long Article Writer</li>
            <li><span className="ico">✅</span> Image Creation Access</li>
            <li><span className="ico">✅</span> Social Media Content</li>
            <li><span className="ico">✅</span> Marketing Content</li>
            <li><span className="ico">✅</span> SmartChat</li>
          </ul>
          <br />
          <div>❤️ Trusted By 1,50,000 Users</div>
        </div>
      </Card>
      <Card className="card loginForm">
        <Card.Body>
          <div className="p-0 box">
            <h4>Let's get started!</h4>
            <p className="mb-4">Create your account now and unlock amazing tools!</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <div>
              <Button 
                className="w-100 googleSignin" 
                onClick={handleGoogleSignIn}
              >
                <GoogleAdIcon /> Connect with Gmail
              </Button>
            </div>
            <hr />
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <div className="d-grid gap-2">
                <Button variant="primary" type="Submit">
                  Login
                </Button>
              </div>
            </Form>
          </div>
          <div className="pt-3 box text-center">
            <p className="mb-0">
              <Link to="/reset" className="linkFont">Forgot password</Link>
              <Link to="/signup" className="linkFont ml-4">Sign up</Link>
            </p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );

  // If show prop is provided, wrap in Modal
  if (show !== undefined) {
    return (
      <Modal 
        show={show} 
        onHide={onHide} 
        keyboard={false} 
        className="loginState"
        size="lg"
        centered
      >
        <Modal.Body className="text-center">
          {loginContent}
        </Modal.Body>
      </Modal>
    );
  }

  // Otherwise render content directly
  return loginContent;
};

export default InlineLogin;