import React, { useState, useRef } from 'react';
import { Form, Badge } from 'react-bootstrap';
import { Paperclip, X } from 'lucide-react';

const MAX_FILES = 3;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const ACCEPTED_FILES = {
  'text/plain': '.txt',
  'application/pdf': '.pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'text/csv': '.csv',
};

const AgentFileUpload = ({ files, onFileAdd, onFileRemove }) => {
  const [error, setError] = useState('');
  const fileRef = useRef();

  const formatFileSize = (bytes) => {
    const sizeInKB = bytes / 1024;
    return `${Math.round(sizeInKB)} KB`;
  };

  const validateFiles = (newFiles) => {
    if (files.length + newFiles.length > MAX_FILES) {
      setError(`You can only upload up to ${MAX_FILES} files`);
      return false;
    }

    for (const file of newFiles) {
      if (file.size > MAX_FILE_SIZE) {
        setError(`File "${file.name}" exceeds the 5MB size limit`);
        return false;
      }

      const extension = file.name.toLowerCase().split('.').pop();
      const isAcceptedType = Object.values(ACCEPTED_FILES).some(type => 
        type.toLowerCase().includes(`.${extension}`)
      );

      if (!isAcceptedType) {
        setError(`File "${file.name}" is not a supported format. Accepted formats: PDF, DOCX, TXT, CSV`);
        return false;
      }
    }
    return true;
  };

  const handleFileAdd = (newFiles) => {
    setError('');
    if (validateFiles(newFiles)) {
      onFileAdd(newFiles);
    }
  };

  const handleFileRemove = (index) => {
    setError('');
    onFileRemove(index);
  };

  return (
    <div className="mb-4">
      <Form.Group>
        <Form.Label className="d-flex justify-content-between">
          <span>Knowledge Base <i className="text-muted font-100">(optional)</i></span>
          <small className="text-muted">
            Upload documents ({files.length}/{MAX_FILES})
          </small>
        </Form.Label>

        <input
          type="file"
          ref={fileRef}
          onChange={e => handleFileAdd(Array.from(e.target.files))}
          multiple
          accept={Object.values(ACCEPTED_FILES).join(',')}
          hidden
        />

        <div 
          className="upload-zone p-3 border rounded text-center cursor-pointer"
          onClick={() => fileRef.current?.click()}
          style={{ 
            cursor: files.length >= MAX_FILES ? 'not-allowed' : 'pointer',            
          }}
        >
          <Paperclip className="mb-2" size={24} />
          <p className="mb-1">Click to upload files or drag and drop</p>
          <small className="text-muted">
            PDF, DOCX, TXT, CSV (max 5MB)
          </small>
        </div>

        {error && (
          <div className="text-danger mt-2 small">
            {error}
          </div>
        )}

        {files.length > 0 && (
          <div className="file-previews mt-3">
            {files.map((file, i) => (
              <Badge 
                key={i} 
                className="d-flex align-items-center p-2 gap-2 bg-light text-dark"
              >
                <span>{file.name} ({formatFileSize(file.size)})</span>
                <X 
                  size={14} 
                  className="cursor-pointer" 
                  onClick={() => handleFileRemove(i)}
                />
              </Badge>
            ))}
          </div>
        )}
      </Form.Group>
    </div>
  );
};

export default AgentFileUpload;