const ChatToolsData = {
    chatWithAI: {
        prompt: "9901",
        icon: "🤖",
        title: "Chat with AI",
        category: "chat",
        caption: "Access the world's best AI models (ChatGPT-4, Claudi, Gemini, Meta..) for writing, creating, and chatting – all in one place.",
        inputs: [{ inputTitle: `What is the focus or niche of your blog?`, inputCaption: `Specify your blog's main theme or niche (e.g., Travel, Health, Technology)`, inputExample: 'Personal finance' }]
    },
    chatWithAgent: {
        prompt: "9902",
        icon: "👨‍💼",
        title: "Chat with Agent",
        category: "chat",
        caption: "Browse or Create your own AI agent for specific requirment like Coding assistant, email assistant, technical support, or creative writing.",
        inputs: [{ inputTitle: `What is the focus or niche of your blog?`, inputCaption: `Specify your blog's main theme or niche (e.g., Travel, Health, Technology)`, inputExample: 'Personal finance' }]
    },
    imageGeneration: {
        prompt: "9903",
        icon: "🎨",
        title: "Image Generation",
        category: "chat",
        caption: "Create custom images and artwork using AI by describing what you want to see in natural language.",
        inputs: [{ inputTitle: `What is the focus or niche of your blog?`, inputCaption: `Specify your blog's main theme or niche (e.g., Travel, Health, Technology)`, inputExample: 'Personal finance' }]
    },
    keywordResearch: {
        prompt: "9904",
        icon: "🔍",
        title: "Keyword Research",
        category: "chat",
        caption: "Discover relevant keywords and search terms for your content, complete with search volume and competition metrics.",
        inputs: [{ inputTitle: `What is the focus or niche of your blog?`, inputCaption: `Specify your blog's main theme or niche (e.g., Travel, Health, Technology)`, inputExample: 'Personal finance' }]
    }
}
export default ChatToolsData;