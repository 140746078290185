const SalesData = {
    developSalesProcess: {
        prompt: "1301",
        icon: "📈",
        title: "Develop Sales Process",
        category: "sales",
        caption: `Gain expert guidance to construct a customized sales process that aligns with your business's unique requirements and customer profiles, boosting the effectiveness of your sales team.`,
        inputs: [
            { inputTitle: `Can you describe about the product or service you want to sell?`, inputCaption: `Provide a detailed description of your product or service`, type: 'textarea', inputExample: `A cloud-based project management tool designed for small to medium-sized businesses to help streamline their workflows and improve team collaboration.` },
            { inputTitle: `Could you provide an overview of your business?`, inputCaption: `Give a brief overview of your business, including its mission and values`, type: 'textarea', inputExample: `Our company, ProjectFlow, aims to revolutionize how teams work together by providing innovative solutions that enhance productivity and foster a collaborative work environment.` },
            { inputTitle: `Who is your target customer or audience?`, inputCaption: `Define your target customer or audience demographics and preferences`, type: 'textarea', inputExample: `Small to medium-sized businesses in the tech industry, specifically those with remote or distributed teams looking to improve project management and communication.` }
        ]
    },
    elevatorPitch: {
        prompt: "1302",
        icon: "🗣️",
        title: "Elevator Pitch",
        category: "sales",
        caption: `This tool assists in creating concise and impactful pitches or presentations, highlighting the key elements of your business or product, ideal for capturing the interest of potential clients or customers.`,
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: `Enter your product or brand name`, type: 'text', inputExample: `ProjectFlow` },
            { inputTitle: `Can you describe your business or product and its intended audience?`, inputCaption: `Explain what your business or product does and who it's intended for`, type: 'textarea', inputExample: `ProjectFlow is a cutting-edge project management tool designed to help small to medium-sized tech companies streamline their operations and enhance team collaboration.` },
            { inputTitle: `What problem does your business/product address, and what makes it stand out?`, inputCaption: `Detail the problem your product solves and its unique selling points`, type: 'textarea', inputExample: `We address the challenge of project management in remote teams by offering a suite of features that enhance communication, task management, and productivity, setting us apart with our intuitive interface and robust integration capabilities.` },
            { inputTitle: `What do you want people to do after learning about your business/product?`, inputCaption: `Specify the desired action or response from your audience`, type: 'textarea', inputExample: `We want potential clients to sign up for a free trial of ProjectFlow to experience firsthand how it can transform their project management processes.` }
        ]
    },
    okrGenerator: {
        prompt: "1303",
        icon: "🎯",
        title: "OKR Generator",
        category: "sales",
        caption: `Efficiently set and monitor clear goals, driving your business towards achieving key objectives with this goal-setting tool.`,
        inputs: [
            { inputTitle: `Can you briefly describe your product, service, or business?`, inputCaption: `Summarize your product, service, or business in a few sentences`, type: 'textarea', inputExample: `We offer an online platform, ProjectFlow, that simplifies project management for businesses by providing tools for task scheduling, communication, and progress tracking.` },
            { inputTitle: `What is the specific goal you want to achieve?`, inputCaption: `State the specific objective or key result you aim to achieve`, type: 'textarea', inputExample: `To increase our user base by 40% and improve customer satisfaction scores by 25% over the next quarter.` }
        ]
    },
    prospectResearch: {
        prompt: "1304",
        icon: "🔍",
        title: "Prospect Research",
        category: "sales",
        caption: `Discover a systematic method to identify and engage with potential customers, optimizing your marketing and sales efforts for your specific product or service.`,
        inputs: [
            { inputTitle: `What is your product/service?`, inputCaption: `Describe the product or service you are offering`, type: 'textarea', inputExample: `ProjectFlow, a project management platform tailored for tech companies and remote teams.` },
            { inputTitle: `Who is your Target Audience?`, inputCaption: `Optional: Define the specific audience or market segment you are targeting`, required: "false", inputExample: `Tech startups and small to medium-sized companies with remote or distributed teams.` },
            { inputTitle: `What are your primary goals for this research?`, inputCaption: `Explain what you aim to achieve with this prospect research`, type: 'textarea', inputExample: `To identify key industries and companies that can benefit from our platform and develop targeted marketing strategies to engage them.` }
        ]
    },
    transactionalEmails: {
        prompt: "1305",
        icon: "📧",
        title: "Transactional Emails",
        category: "sales",
        caption: `Create custom transactional emails for various needs with ease, ensuring professionalism and effectiveness in every communication.`,
        inputs: [
            { inputTitle: `What is the Purpose and Context of Your Transactional Emails?`, inputCaption: `Detail the specific purpose and context of the transactional emails you need`, type: 'textarea', inputExample: `To notify users of successful account creation, project updates, and task assignments within ProjectFlow.` }
        ]
    },
    marketAnalysis: {
        prompt: "1306",
        icon: "📊",
        title: "Market Analysis",
        category: "sales",
        caption: `Obtain in-depth insights into market trends, customer behaviors, and industry dynamics to strategize effectively and stay ahead of the competition.`,
        inputs: [
            { inputTitle: `What industry does your business operate in?`, inputCaption: `Specify your business industry for a targeted analysis`, type: 'text', inputExample: `Tech and Software Development` },
            { inputTitle: `What specific market segments are you interested in?`, inputCaption: `Define the market segments you want to analyze`, type: 'text', inputExample: `Small to medium-sized tech companies` },
            { inputTitle: `What type of market data are you seeking?`, inputCaption: `Specify the kind of data you need - trends, customer behaviors, competition, etc.`, type: 'textarea', inputExample: `Current trends in project management tools, customer preferences in tech industries, and competitive analysis of similar platforms.` }
        ]
    },
    leadScoringAI: {
        prompt: "1307",
        icon: "🤖",
        title: "Lead Scoring AI",
        category: "sales",
        caption: `Leverage artificial intelligence to score and prioritize leads based on their likelihood to convert, optimizing your sales team's focus and efforts.`,
        inputs: [
            { inputTitle: `What criteria do you use to qualify leads?`, inputCaption: `List the factors or criteria used in your current lead qualification process`, type: 'textarea', inputExample: `Industry, company size, current use of project management tools, and readiness to implement new software solutions.` },
            { inputTitle: `Can you provide historical data on past leads and their outcomes?`, inputCaption: `Share past lead data to train the AI for accurate scoring`, type: 'file', inputExample: `CSV file with data on past leads including industry, size, engagement level, and conversion status.` },
            { inputTitle: `What is your ideal customer profile?`, inputCaption: `Describe the characteristics of your ideal customer`, type: 'textarea', inputExample: `Tech companies with 50-200 employees, actively looking for scalable project management solutions.` }
        ]
    },
    competitorMonitoring: {
        prompt: "1309",
        icon: "📉",
        title: "Competitor Monitoring",
        category: "sales",
        caption: `Stay informed about your competitors' strategies and market movements, enabling you to adapt and maintain a competitive edge.`,
        inputs: [
            { inputTitle: `Who are your main competitors?`, inputCaption: `List your key competitors in the market`, type: 'textarea', inputExample: `Asana, Trello, Monday.com` },
            { inputTitle: `What aspects of your competitors’ activities are you most interested in?`, inputCaption: `Specify areas like pricing, marketing strategies, product launches, etc.`, type: 'textarea', inputExample: `Marketing strategies, new feature releases, and pricing models.` },
            { inputTitle: `How frequently do you want to receive updates?`, inputCaption: `Choose the frequency of competitor analysis reports`, type: 'select', options: ['Daily', 'Weekly', 'Monthly'], inputExample: `Monthly` }
        ]
    },
    customerFeedbackAnalysis: {
        prompt: "1310",
        icon: "🧐",
        title: "Customer Feedback Analysis",
        category: "sales",
        caption: `Use AI to analyze customer feedback, gaining valuable insights into client satisfaction and areas for improvement.`,
        inputs: [
            { inputTitle: `What sources of customer feedback do you have?`, inputCaption: `Mention sources like surveys, reviews, social media, etc.`, type: 'textarea', inputExample: `Online surveys, product reviews on our website, and social media comments.` },
            { inputTitle: `What key metrics do you want to analyze from the feedback?`, inputCaption: `Specify metrics such as satisfaction scores, sentiment analysis, etc.`, type: 'textarea', inputExample: `Customer satisfaction scores, common themes in feedback, and sentiment analysis.` },
            { inputTitle: `How would you like the analysis to be presented?`, inputCaption: `Choose the format of the feedback report`, type: 'select', options: ['Charts', 'Text Summary', 'Both'], inputExample: `Both` }
        ]
    },
    socialMediaManagement: {
        prompt: "1311",
        icon: "🌐",
        title: "Social Media Management",
        category: "sales",
        caption: `Efficiently manage and optimize your presence on social media platforms to engage with your audience and promote your brand.`,
        inputs: [
            { inputTitle: `Which social media platforms are you focusing on?`, inputCaption: `List the social media platforms you are active on`, type: 'textarea', inputExample: `LinkedIn, Twitter, and Facebook` },
            { inputTitle: `What are your primary goals for social media marketing?`, inputCaption: `Specify goals like brand awareness, lead generation, customer engagement, etc.`, type: 'textarea', inputExample: `Increase brand awareness and generate leads through targeted content and engagement.` },
            { inputTitle: `How often do you plan to post or interact on social media?`, inputCaption: `Set a frequency for your social media activities`, type: 'select', options: ['Daily', 'Weekly', 'Monthly'], inputExample: `Weekly` }
        ]
    },
    proposalGenerator: {
        prompt: "1312",
        icon: "📋",
        title: "Proposal Generator",
        category: "sales",
        caption: `Automate the creation of customized sales proposals, saving time while maintaining a high standard of professionalism and relevance.`,
        inputs: [
            { inputTitle: `What type of proposals are you looking to generate?`, inputCaption: `Describe the nature of the proposals (e.g., service offering, product sales, etc.)`, type: 'textarea', inputExample: `Custom proposals for our project management software, tailored to the needs of tech companies.` },
            { inputTitle: `What are the key elements to include in every proposal?`, inputCaption: `List essential components like pricing, timelines, terms and conditions, etc.`, type: 'textarea', inputExample: `Software features, pricing plans, implementation timeline, customer support options, and terms and conditions.` },
            { inputTitle: `Do you have any specific branding or formatting requirements?`, inputCaption: `Detail any branding guidelines or format preferences`, type: 'textarea', inputExample: `The proposal should align with our brand's color scheme and logo placement guidelines.` }
        ]
    },
    partnershipOpportunitiesFinder: {
        prompt: "1313",
        icon: "🤝",
        title: "Partnership Opportunities Finder",
        category: "sales",
        caption: `Explore and identify potential partnership opportunities to expand your market reach and leverage synergies.`,
        inputs: [
            { inputTitle: `What type of partnerships are you interested in?`, inputCaption: `Specify the nature of desired partnerships (e.g., affiliate, joint venture, etc.)`, type: 'textarea', inputExample: `We are interested in affiliate partnerships with industry blogs and joint ventures with complementary software providers.` },
            { inputTitle: `What industries or sectors are you targeting for partnerships?`, inputCaption: `Indicate the industries or business sectors you are interested in partnering with`, type: 'textarea', inputExample: `Tech industry, especially companies in software development and remote team collaboration.` },
            { inputTitle: `What are your key criteria for selecting a partner?`, inputCaption: `Define the qualities or capabilities you seek in a partner`, type: 'textarea', inputExample: `Alignment with our product offering, a strong market presence, and a shared target audience.` }
        ]
    },
    contractGenerator: {
        prompt: "1314",
        icon: "📄",
        title: "Contract Generator",
        category: "sales",
        caption: "Create professional and legally-sound contracts tailored to your business needs.",
        inputs: [
            { inputTitle: "Contract Type", inputCaption: "What type of contract do you need?", type: "text", inputExample: "Service Agreement" },
            { inputTitle: "Specific Terms", inputCaption: "List the key terms to be included", type: "textarea", inputExample: "Payment terms, delivery schedule, confidentiality clauses" },
            { inputTitle: "Legal Requirements", inputCaption: "Any specific legal requirements or jurisdictions", type: "textarea", inputExample: "California state law compliance" }
        ]
    },
    
    eventPlanningAssistant: {
        prompt: "1315",
        icon: "📅",
        title: "Event Planning Assistant",
        category: "sales",
        caption: "Plan and organize successful business events with comprehensive planning assistance.",
        inputs: [
            { inputTitle: "Event Type", inputCaption: "What kind of event are you planning?", type: "text", inputExample: "Product Launch Conference" },
            { inputTitle: "Event Details", inputCaption: "Provide key details about the event", type: "textarea", inputExample: "200 attendees, keynote speakers, product demos" },
            { inputTitle: "Budget Range", inputCaption: "Specify the budget range", type: "text", inputExample: "$50,000-$75,000" },
            { inputTitle: "Timeline", inputCaption: "When is the event planned for?", type: "text", inputExample: "3 months from now" }
        ]
    },
    
    elevatorPitchBuilder: {
        prompt: "1316",
        icon: "🎤",
        title: "Elevator Pitch Builder",
        category: "sales",
        caption: "Create compelling elevator pitches that effectively communicate your value proposition.",
        inputs: [
            { inputTitle: "Product/Service", inputCaption: "What are you pitching?", type: "text", inputExample: "AI-powered CRM software" },
            { inputTitle: "Key Selling Points", inputCaption: "List main benefits and features", type: "textarea", inputExample: "Automated lead scoring, predictive analytics" },
            { inputTitle: "Target Audience", inputCaption: "Who is your pitch aimed at?", type: "text", inputExample: "Small business owners" },
            { inputTitle: "Duration", inputCaption: "How long should the pitch be?", type: "select", options: ['30 seconds', '60 seconds', '90 seconds'], inputExample: "60 seconds" }
        ]
    },
    
    brandDescriptionGenerator: {
        prompt: "1317",
        icon: "🎨",
        title: "Brand Description Generator",
        category: "sales",
        caption: "Create compelling brand descriptions that resonate with your target audience.",
        inputs: [
            { inputTitle: "Brand Name", inputCaption: "What's your brand name?", type: "text", inputExample: "TechFlow Solutions" },
            { inputTitle: "Brand Values", inputCaption: "List your core brand values", type: "textarea", inputExample: "Innovation, reliability, customer-first" },
            { inputTitle: "Target Market", inputCaption: "Who is your target audience?", type: "textarea", inputExample: "Small to medium tech companies" },
            { inputTitle: "Unique Selling Proposition", inputCaption: "What makes your brand unique?", type: "textarea", inputExample: "AI-powered solutions with human-centric design" }
        ]
    },
    
    productPriceComparison: {
        prompt: "1318",
        icon: "💰",
        title: "Product Price Comparison",
        category: "sales",
        caption: "Create detailed product comparisons to highlight your competitive advantages.",
        inputs: [
            { inputTitle: "Product A", inputCaption: "First product to compare", type: "text", inputExample: "Our Premium CRM Package" },
            { inputTitle: "Product B", inputCaption: "Second product to compare", type: "text", inputExample: "Competitor's Enterprise Solution" },
            { inputTitle: "Features to Compare", inputCaption: "List key features to compare", type: "textarea", inputExample: "User capacity, automation features, support options" },
            { inputTitle: "Price Points", inputCaption: "List price points for comparison", type: "textarea", inputExample: "Monthly subscription rates, setup fees" }
        ]
    },
    
    staffConfirmationLetter: {
        prompt: "1319",
        icon: "✉️",
        title: "Staff Confirmation Letter",
        category: "sales",
        caption: "Generate professional staff confirmation letters with all necessary details.",
        inputs: [
            { inputTitle: "Employee Name", inputCaption: "Who is the letter for?", type: "text", inputExample: "John Smith" },
            { inputTitle: "Position", inputCaption: "What position are they being confirmed for?", type: "text", inputExample: "Senior Sales Manager" },
            { inputTitle: "Terms", inputCaption: "Employment terms and conditions", type: "textarea", inputExample: "Full-time, annual salary, benefits package" },
            { inputTitle: "Start Date", inputCaption: "When does employment begin?", type: "text", inputExample: "January 15, 2024" }
        ]
    },
    
    businessPlanGenerator: {
        prompt: "1320",
        icon: "📊",
        title: "Business Plan Generator",
        category: "sales",
        caption: "Create comprehensive business plans tailored to your industry and goals.",
        inputs: [
            { inputTitle: "Business Name", inputCaption: "What's your business name?", type: "text", inputExample: "TechFlow Solutions" },
            { inputTitle: "Industry", inputCaption: "What industry are you in?", type: "text", inputExample: "Software Development" },
            { inputTitle: "Key Objectives", inputCaption: "List your main business objectives", type: "textarea", inputExample: "Market expansion, revenue growth, product development" },
            { inputTitle: "Target Market", inputCaption: "Define your target market", type: "textarea", inputExample: "Small to medium-sized tech companies" }
        ]
    },
    
    meetingMinutesGenerator: {
        prompt: "1321",
        icon: "📝",
        title: "Meeting Minutes Generator",
        category: "sales",
        caption: "Create professional meeting minutes with all essential information.",
        inputs: [
            { inputTitle: "Meeting Topic", inputCaption: "What was the meeting about?", type: "text", inputExample: "Q4 Sales Strategy Review" },
            { inputTitle: "Attendees", inputCaption: "List all attendees", type: "textarea", inputExample: "John Smith (Sales Director), Jane Doe (Marketing Lead)" },
            { inputTitle: "Key Points", inputCaption: "Main points discussed", type: "textarea", inputExample: "Revenue targets, marketing initiatives, team expansion" },
            { inputTitle: "Action Items", inputCaption: "List action items and owners", type: "textarea", inputExample: "Create sales deck (John), develop marketing plan (Jane)" }
        ]
    },
    
    interviewPreparationGuide: {
        prompt: "1322",
        icon: "👥",
        title: "Interview Preparation Guide",
        category: "sales",
        caption: "Create comprehensive interview guides for different roles and industries.",
        inputs: [
            { inputTitle: "Position", inputCaption: "What position is the interview for?", type: "text", inputExample: "Senior Sales Representative" },
            { inputTitle: "Industry Focus", inputCaption: "Which industry?", type: "text", inputExample: "Software/Technology" },
            { inputTitle: "Experience Level", inputCaption: "Required experience level", type: "select", options: ['Entry Level', 'Mid Level', 'Senior Level', 'Executive'], inputExample: "Senior Level" },
            { inputTitle: "Key Skills", inputCaption: "Important skills to highlight", type: "textarea", inputExample: "Sales strategy, client relationship management, negotiation" }
        ]
    },
    
    productLaunchPlanner: {
        prompt: "1323",
        icon: "🚀",
        title: "Product Launch Planner",
        category: "sales",
        caption: "Create detailed product launch plans with comprehensive strategy and timeline.",
        inputs: [
            { inputTitle: "Product Name", inputCaption: "What product are you launching?", type: "text", inputExample: "TechFlow CRM 2.0" },
            { inputTitle: "Target Market", inputCaption: "Who is your target audience?", type: "textarea", inputExample: "Small to medium-sized tech companies" },
            { inputTitle: "Launch Timeline", inputCaption: "When do you plan to launch?", type: "text", inputExample: "Q1 2024" },
            { inputTitle: "Marketing Channels", inputCaption: "Which channels will you use?", type: "textarea", inputExample: "Social media, email marketing, industry events" }
        ]
    },
}
export default SalesData;
