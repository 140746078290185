import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Spinner, Button } from 'react-bootstrap';
import { useUserAuth } from "../../context/UserAuthContext";
import { CircleCheckBig, ShieldOff, ShieldEllipsis, ShieldX } from 'lucide-react';

const SubscriptionStatusHandler = () => {
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subscriptionStatus = params.get('subscription');
    
    const checkSubscriptionStatus = async () => {
      try {
        // Wait a brief moment to allow webhook processing
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Fetch the latest subscription status
        
      const local = `http://localhost:3001/api/subscription/${user.uid}`;
      const dev = `https://wk-server.vercel.app/api/subscription/${user.uid}`;

        const response = await fetch(dev);
        if (!response.ok) {
          throw new Error('Failed to fetch subscription status');
        }
        
        const subscriptionData = await response.json();
        
        if (subscriptionStatus === 'success' && subscriptionData.status === 'active') {
          setStatus('success');
        } else if (subscriptionStatus === 'cancelled') {
          setStatus('cancelled');
        } else {
          setStatus('pending');
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
        setError(error.message);
        setStatus('error');
      }
    };

    if (user?.uid) {
      checkSubscriptionStatus();
    }
  }, [user?.uid, location.search]);

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return (
          <div className="text-center">
            <Spinner animation="border" role="status" />
            <p className="mt-3">Processing your subscription...</p>
          </div>
        );

      case 'success':
        return (
          <div className="subscription-status status-success text-center">
            <CircleCheckBig className="btn-outline-success mb-3" size={44} />
            <h6>Subscription Successfully Activated!</h6>
            <p>Thank you for subscribing. Your account has been successfully updated with your new subscription.</p>
            <div className="text-center mt-4">
              <Button variant="outline-success" onClick={() => navigate('/subscription')}>
                View Subscription Details
              </Button>
            </div>
          </div>
        );

      case 'cancelled':
        return (
          <div className="subscription-status status-warning text-center">
            <ShieldOff className="btn-outline-warning mb-3" size={44} />
            <h6>Subscription Process Cancelled</h6>
            <p>You've cancelled the subscription process. You can try again whenever you're ready.</p>
            <div className="text-center mt-4">
              <Button variant="outline-warning" onClick={() => navigate('/')}>
                Return to Plans
              </Button>
            </div>
          </div>
        );

      case 'pending':
        return (
          <div className="subscription-status status-info text-center">
            <ShieldEllipsis className="btn-outline-info mb-3" size={44} />
            <h6>Subscription Update in Progress</h6>
            <p>Your subscription is being processed. This may take a few moments.</p>
            <div className="text-center mt-4">
              <Button variant="outline-info" onClick={() => window.location.reload()}>
                Check Status
              </Button>
            </div>
          </div>
        );

      case 'error':
        return (
          <div className="subscription-status status-danger text-center">
            <ShieldX className="mb-3 btn-outline-danger" size={44} />
            <h6>Error Processing Subscription</h6>
            <p>{error || 'An unexpected error occurred. Please try again or contact support.'}</p>
            <div className="text-center mt-4">
              <Button variant="outline-danger" onClick={() => window.location.reload()}>
                Try Again
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="p-5">
      {renderContent()}
    </div>
  );
};

export default SubscriptionStatusHandler;