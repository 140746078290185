const EducationData = { 
    quizGenerator: {
        prompt: "2001",
        icon: "📝",
        title: "Quiz Generator",
        category: "Education",
        caption: "Create customized educational quizzes with various question types and difficulty levels.",
        inputs: [
            { 
                inputTitle: "Content/Topic", 
                inputCaption: "Enter the content or topic for the quiz", 
                type: "textarea", 
                inputExample: "World War II: Key Events and Figures\n\nThe war began in 1939 with Germany's invasion of Poland. Key figures included Winston Churchill, Franklin D. Roosevelt, and Joseph Stalin. Major events: Battle of Britain, Pearl Harbor, D-Day, and the atomic bombings of Japan." 
            },
            { 
                inputTitle: "Quiz Type", 
                inputCaption: "Select the type of quiz", 
                type: "select", 
                options: ['Multiple Choice', 'True/False', 'Short Answer', 'Essay', 'Mixed Format'],
                inputExample: "Multiple Choice" 
            },
            { 
                inputTitle: "Difficulty Level", 
                inputCaption: "Choose the difficulty level", 
                type: "select", 
                options: ['Basic', 'Intermediate', 'Advanced'],
                inputExample: "Intermediate" 
            },
            { 
                inputTitle: "Number of Questions", 
                inputCaption: "How many questions would you like?", 
                type: "select", 
                options: ['5', '10', '15', '20', '25'],
                inputExample: "10" 
            },
            { 
                inputTitle: "Additional Instructions", 
                inputCaption: "Any specific requirements or focus areas?", 
                type: "textarea", 
                required: false,
                inputExample: "Include questions about major battles and political decisions. Focus on cause-and-effect relationships." 
            }
        ]
    },
    essayTopicFinder: {
        prompt: "2007",
        icon: "🔍",
        title: "Essay Topic Finder",
        category: "Education",
        caption: "Find compelling and relevant topics for your essays.",
        inputs: [
            { inputTitle: "Enter Subject or Area of Interest", inputCaption: "Type the subject or area you're interested in writing about.", inputExample: `Environmental Science`, type: 'text' },
            { inputTitle: "Desired Essay Length", inputCaption: "Specify the desired essay length (e.g., short, medium, long).", inputExample: `Medium`, type: 'select', options: ['Short', 'Medium', 'Long'] },
            { inputTitle: "Academic Level", inputCaption: "Select your academic level.", inputExample: `Undergraduate`, type: 'select', options: ['High School', 'Undergraduate', 'Graduate'] }
        ]
    },        
    essayTitleGenerator: {
        prompt: "425",
        icon: "✍️",
        title: "Essay Title Generator",
        category: "Education",
        caption: "Come up with engaging and relevant titles for your essays to draw in readers.",
        inputs: [
            { inputTitle: `Subject or Theme of the Essay`, inputCaption: `Describe the subject or theme of the essay.`, inputExample: `The Impact of Climate Change on Biodiversity` },
            { inputTitle: `Desired Tone or Style`, inputCaption: `Specify the tone or style of the essay (e.g., academic, persuasive).`, inputExample: `Persuasive`, type: 'text' }
        ]
    },
    essayOutlineCreator: {
        prompt: "2002",
        icon: "📚",
        title: "Essay Outline Creator",
        category: "Education",
        caption: "Creates structured outlines for essays or research papers.",
        inputs: [
            { inputTitle: "Enter Essay Topic", inputCaption: "Type your main topic here.", inputExample: `The Role of Technology in Education`, type: 'text' },
            { inputTitle: "Essay Length", inputCaption: "Specify the desired essay length.", inputExample: `1500 words`, type: 'text' }
        ]
    },
    essayCreator: {
        prompt: "2011",
        icon: "✍️",
        title: "Essay Creator",
        category: "Education",
        caption: "Generate well-structured academic essays tailored to your specific requirements and academic level.",
        inputs: [
            { 
                inputTitle: "Essay Topic", 
                inputCaption: "Enter your essay topic or question", 
                type: "textarea", 
                inputExample: "The Impact of Artificial Intelligence on Future Employment Opportunities" 
            },
            { 
                inputTitle: "Essay Type", 
                inputCaption: "Select the type of essay", 
                type: "select", 
                options: ['Argumentative', 'Expository', 'Analytical', 'Persuasive', 'Compare and Contrast', 'Narrative', 'Descriptive', 'Critical Analysis'],
                inputExample: "Argumentative" 
            },
            { 
                inputTitle: "Academic Level", 
                inputCaption: "Select your academic level", 
                type: "select", 
                options: ['High School', 'Undergraduate', 'Graduate', 'Doctoral'],
                inputExample: "Undergraduate" 
            },
            { 
                inputTitle: "Word Count", 
                inputCaption: "Specify desired word count", 
                type: "select", 
                options: ['500', '750', '1000', '1500', '2000'],
                inputExample: "1500" 
            },
            { 
                inputTitle: "Key Points", 
                inputCaption: "List main arguments or points to include", 
                type: "textarea", 
                inputExample: "1. Current AI automation trends\n2. Impact on traditional job markets\n3. Emergence of new job categories\n4. Required skills for future employment" 
            } 
        ]
    },        
    ebooksIdeasGenerator: {
        prompt: "423",
        icon: "📚",
        title: "Ebooks Ideas Generator",
        category: "Education",
        caption: "Generate creative and marketable ebook ideas to captivate your target audience.",
        inputs: [
            { inputTitle: `Target Audience`, inputCaption: `Describe your target audience.`, inputExample: `Young adults interested in self-improvement` },
            { inputTitle: `Preferred Genre or Topic`, inputCaption: `Mention the genre or topic for your ebook.`, inputExample: `Personal Development`, type: 'text' }
        ]
    },
    essayChecker: {
        prompt: "2013",
        icon: "✅",
        title: "Essay Checker",
        category: "Education",
        caption: "Analyze your essay for clarity, coherence, grammar, and overall quality.",
        inputs: [
            { inputTitle: 'Essay Text', inputCaption: 'Paste your essay text here.', inputExample: `The significant impact of renewable energy sources on the global economy...`, type: 'textarea' },
            { inputTitle: 'Key Focus Areas', inputCaption: 'Select the key areas you want feedback on (e.g., grammar, structure, clarity, coherence).', inputExample: `Grammar, structure`, type: 'text' },
            { inputTitle: 'Essay Type', inputCaption: 'Specify the type of essay (e.g., argumentative, descriptive, narrative).', inputExample: `Argumentative`, type: 'text', required: 'false' }
        ]
    },    
    homeworkHelper: {
        prompt: "2003",
        icon: "💡",
        title: "Homework Helper",
        category: "Education",
        caption: "Assists in understanding and solving homework problems.",
        inputs: [
            { inputTitle: "Enter Homework Question", inputCaption: "Type or paste your question here.", inputExample: `What are the main causes of the French Revolution?`, type: 'textarea' }
        ]
    },
    languageLearningAssistant: {
        prompt: "2004",
        icon: "🌐",
        title: "Language Learning Assistant",
        category: "Education",
        caption: "Tool for language practice and learning.",
        inputs: [
            { inputTitle: "Enter Text for Translation", inputCaption: "Type the words or sentences here.", inputExample: `Hello, how are you today?`, type: 'text' }
        ]
    },
    historicalEventsExplorer: {
        prompt: "2005",
        icon: "🕰️",
        title: "Historical Events Explorer",
        category: "Education",
        caption: "Provides detailed information on historical events and figures.",
        inputs: [
            { inputTitle: "Enter Historical Event or Figure", inputCaption: "Type the name of the event or person here.", inputExample: `Alexander the Great`, type: 'text' }
        ]
    },
    scienceConceptVisualizer: {
        prompt: "2006",
        icon: "🔬",
        title: "Science Concept Visualizer",
        category: "Education",
        caption: "Visual representations of scientific concepts.",
        inputs: [
            { inputTitle: "Enter Scientific Concept", inputCaption: "Type the theory, principle, or equation here.", inputExample: `Newton's Laws of Motion`, type: 'text' }
        ]
    }, 
    literatureAnalysisAssistant: {
        prompt: "2008",
        icon: "📜",
        title: "Literature Analysis Assistant",
        category: "Education",
        caption: "Insights into literary works and analysis.",
        inputs: [
            { inputTitle: "Enter Literary Work", inputCaption: "Type the title or specific passages here.", inputExample: `Shakespeare's Macbeth`, type: 'text' }
        ]
    },
    mathEquationSolver: {
        prompt: "2009",
        icon: "➗",
        title: "Math Equation Solver",
        category: "Education",
        caption: "Solves equations and explains the steps.",
        inputs: [
            { inputTitle: "Enter Math Problem", inputCaption: "Type your equation or problem here.", inputExample: `2x + 3 = 15`, type: 'textarea' }
        ]
    },
    studyPlanner: {
        prompt: "2010",
        icon: "📅",
        title: "Interactive Study Planner",
        category: "Education",
        caption: "Creates personalized study schedules and reminders.",
        inputs: [
            { inputTitle: "Enter Subjects", inputCaption: "List the subjects to study.", inputExample: `Math, Science, History`, type: 'textarea' },
            { inputTitle: "Available Time", inputCaption: "How many hours can you dedicate?", inputExample: `10 hours per week` }
        ]
    },
    thesisStatementGenerator: {
        prompt: "2012",
        icon: "🎓",
        title: "Thesis Statement",
        category: "Education",
        caption: "Craft a clear and compelling thesis statement for your academic writing.",
        inputs: [
            { inputTitle: 'Topic', inputCaption: 'Enter the main topic of your paper or research.', inputExample: `The effectiveness of online education`, type: 'text' },
            { inputTitle: 'Your Stance or Argument', inputCaption: 'Briefly describe your stance or argument on this topic.', inputExample: `Online education offers flexibility and accessibility, making it an effective method for learning.`, type: 'textarea' },
            { inputTitle: 'Key Points', inputCaption: 'List the main points or arguments that support your stance (optional).', inputExample: `Access to a wide range of resources, personalized learning paths, cost-effectiveness.`, type: 'textarea', required: 'false' }
        ]
    },
    studyNotesGenerator: {
        prompt: "2014",
        icon: "📝",
        title: "Study Notes Generator",
        category: "Education",
        caption: "Create comprehensive study notes for any academic topic.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "Enter the topic for study notes", type: "text", inputExample: "Cell Biology: Mitosis and Meiosis" },
            { inputTitle: "Academic Level", inputCaption: "Select your academic level", type: "select", options: ['High School', 'Undergraduate', 'Graduate'], inputExample: "Undergraduate" },
            { inputTitle: "Key Concepts", inputCaption: "List the main concepts to cover", type: "textarea", inputExample: "Cell division stages, chromosome behavior, genetic significance" },
            { inputTitle: "Format Preference", inputCaption: "Choose preferred note format", type: "select", options: ['Outline', 'Bullet Points', 'Q&A Format', 'Mind Map Style'], inputExample: "Outline" }
        ]
    },    
    discussionBoardGenerator: {
        prompt: "2015",
        icon: "💭",
        title: "Discussion Board Generator",
        category: "Education",
        caption: "Create engaging academic discussion posts and responses.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "Enter the discussion topic", type: "text", inputExample: "Impact of Social Media on Modern Communication" },
            { inputTitle: "Course Context", inputCaption: "Provide course context", type: "text", inputExample: "Digital Media Studies 301" },
            { inputTitle: "Key Points", inputCaption: "List key points to address", type: "textarea", inputExample: "Privacy concerns, social behavior changes, digital literacy" },
            { inputTitle: "Academic Level", inputCaption: "Select academic level", type: "select", options: ['Undergraduate', 'Graduate'], inputExample: "Undergraduate" }
        ]
    },    
    personalStatementGenerator: {
        prompt: "2016",
        icon: "📄",
        title: "Personal Statement Generator",
        category: "Education",
        caption: "Create compelling personal statements for academic applications.",
        inputs: [
            { inputTitle: "Application Type", inputCaption: "What are you applying for?", type: "text", inputExample: "Graduate School - Computer Science" },
            { inputTitle: "Purpose", inputCaption: "Main purpose of statement", type: "text", inputExample: "PhD Program Application" },
            { inputTitle: "Key Experiences", inputCaption: "List relevant experiences/achievements", type: "textarea", inputExample: "Research experience, academic achievements, relevant projects" },
            { inputTitle: "Word Limit", inputCaption: "Specify word limit", type: "text", inputExample: "500 words" }
        ]
    },    
    academicSummaryTool: {
        prompt: "2017",
        icon: "📚",
        title: "Academic Summary Tool",
        category: "Education",
        caption: "Create concise summaries of academic texts and research papers.",
        inputs: [
            { inputTitle: "Text to Summarize", inputCaption: "Enter text for summarization", type: "textarea", inputExample: "Research paper on climate change impacts" },
            { inputTitle: "Key Points", inputCaption: "Specific points to include", type: "textarea", inputExample: "Methodology, findings, conclusions" },
            { inputTitle: "Length", inputCaption: "Desired summary length", type: "text", inputExample: "300 words" },
            { inputTitle: "Audience", inputCaption: "Target audience", type: "select", options: ['Students', 'Researchers', 'General Academic'], inputExample: "Researchers" }
        ]
    },    
    researchPaperOutline: {
        prompt: "2018",
        icon: "📋",
        title: "Research Paper Outline",
        category: "Education",
        caption: "Generate structured outlines for academic research papers.",
        inputs: [
            { inputTitle: "Research Topic", inputCaption: "Enter your research topic", type: "text", inputExample: "Effects of Climate Change on Marine Ecosystems" },
            { inputTitle: "Research Question", inputCaption: "State your research question", type: "textarea", inputExample: "How do rising ocean temperatures affect coral reef biodiversity?" },
            { inputTitle: "Key Arguments", inputCaption: "List main arguments/points", type: "textarea", inputExample: "Temperature effects, species adaptation, ecosystem changes" },
            { inputTitle: "Required Sections", inputCaption: "List required paper sections", type: "textarea", inputExample: "Introduction, Methods, Results, Discussion" }
        ]
    },    
    citationGenerator: {
        prompt: "2019",
        icon: "📑",
        title: "Citation Generator",
        category: "Education",
        caption: "Create accurate citations in various academic styles.",
        inputs: [
            { inputTitle: "Source Details", inputCaption: "Enter source information", type: "textarea", inputExample: "Author: John Smith, Title: Understanding Climate Change, Year: 2023" },
            { inputTitle: "Citation Style", inputCaption: "Select citation style", type: "select", options: ['APA', 'MLA', 'Chicago', 'Harvard'], inputExample: "APA" },
            { inputTitle: "Source Type", inputCaption: "Type of source", type: "select", options: ['Book', 'Journal Article', 'Website', 'Conference Paper'], inputExample: "Journal Article" }
        ]
    },    
    literatureReviewHelper: {
        prompt: "2020",
        icon: "📖",
        title: "Literature Review Helper",
        category: "Education",
        caption: "Organize and structure academic literature reviews.",
        inputs: [
            { inputTitle: "Review Topic", inputCaption: "Enter review topic", type: "text", inputExample: "Artificial Intelligence in Healthcare" },
            { inputTitle: "Key Themes", inputCaption: "List main themes to cover", type: "textarea", inputExample: "Diagnostic applications, treatment planning, ethical considerations" },
            { inputTitle: "Time Period", inputCaption: "Specify review period", type: "text", inputExample: "2018-2023" },
            { inputTitle: "Research Focus", inputCaption: "Specific focus area", type: "text", inputExample: "Clinical Applications" }
        ]
    },    
    academicParaphrasingTool: {
        prompt: "2021",
        icon: "🔄",
        title: "Academic Paraphrasing Tool",
        category: "Education",
        caption: "Paraphrase academic text while maintaining scholarly tone.",
        inputs: [
            { inputTitle: "Original Text", inputCaption: "Enter text to paraphrase", type: "textarea", inputExample: "The implementation of artificial intelligence in healthcare..." },
            { inputTitle: "Complexity Level", inputCaption: "Select desired complexity", type: "select", options: ['Undergraduate', 'Graduate', 'Technical'], inputExample: "Graduate" },
            { inputTitle: "Field", inputCaption: "Academic field", type: "text", inputExample: "Medical Sciences" }
        ]
    },
    studyGuideGenerator: {
        prompt: "2022",
        icon: "📚",
        title: "Study Guide Generator",
        category: "Education",
        caption: "Create comprehensive study guides for any subject or topic.",
        inputs: [
            { inputTitle: "Subject", inputCaption: "Enter the subject", type: "text", inputExample: "Biology" },
            { inputTitle: "Topics to Cover", inputCaption: "List the specific topics", type: "textarea", inputExample: "Cell structure, Mitosis, Cell transport" },
            { inputTitle: "Academic Level", inputCaption: "Select academic level", type: "select", options: ['High School', 'Undergraduate', 'Graduate'], inputExample: "Undergraduate" },
            { inputTitle: "Format", inputCaption: "Choose study guide format", type: "select", options: ['Outline', 'Q&A', 'Flashcards', 'Summary Notes'], inputExample: "Outline" }
        ]
    },
    
    satQuestionGenerator: {
        prompt: "2023",
        icon: "📝",
        title: "SAT Question Generator",
        category: "Education",
        caption: "Generate SAT-style practice questions for test preparation.",
        inputs: [
            { inputTitle: "Subject Area", inputCaption: "Select subject area", type: "select", options: ['Math', 'Reading', 'Writing and Language'], inputExample: "Math" },
            { inputTitle: "Topic", inputCaption: "Specify topic within subject", type: "text", inputExample: "Algebra Word Problems" },
            { inputTitle: "Number of Questions", inputCaption: "How many questions?", type: "select", options: ['5', '10', '15', '20'], inputExample: "10" },
            { inputTitle: "Difficulty Level", inputCaption: "Select difficulty", type: "select", options: ['Easy', 'Medium', 'Hard'], inputExample: "Medium" }
        ]
    },
    
    iepGoalGenerator: {
        prompt: "2024",
        icon: "🎯",
        title: "IEP Goal Generator",
        category: "Education",
        caption: "Create individualized education program goals tailored to student needs.",
        inputs: [
            { inputTitle: "Student Level", inputCaption: "Enter student's grade/level", type: "text", inputExample: "3rd Grade" },
            { inputTitle: "Area of Focus", inputCaption: "Select area of focus", type: "select", options: ['Reading', 'Writing', 'Math', 'Behavior', 'Social Skills', 'Communication'], inputExample: "Reading" },
            { inputTitle: "Current Performance", inputCaption: "Describe current level", type: "textarea", inputExample: "Reading at 1st grade level, struggles with phonics" },
            { inputTitle: "Timeline", inputCaption: "Goal timeline", type: "select", options: ['Quarter', 'Semester', 'Academic Year'], inputExample: "Academic Year" }
        ]
    },
    
    hypothesisGenerator: {
        prompt: "2025",
        icon: "🔬",
        title: "Hypothesis Generator",
        category: "Education",
        caption: "Create clear and testable research hypotheses.",
        inputs: [
            { inputTitle: "Research Topic", inputCaption: "Enter your research topic", type: "textarea", inputExample: "Effect of caffeine on student attention spans" },
            { inputTitle: "Variables", inputCaption: "List independent and dependent variables", type: "textarea", inputExample: "Independent: caffeine intake; Dependent: attention span duration" },
            { inputTitle: "Research Type", inputCaption: "Select research type", type: "select", options: ['Experimental', 'Correlational', 'Descriptive'], inputExample: "Experimental" }
        ]
    },
    
    koreanGrammarChecker: {
        prompt: "2026",
        icon: "🇰🇷",
        title: "Korean Grammar Checker",
        category: "Education",
        caption: "Check and correct Korean grammar with detailed explanations.",
        inputs: [
            { inputTitle: "Text", inputCaption: "Enter Korean text to check", type: "textarea", inputExample: "안녕하세요. 저는 한국어를 공부해요." },
            { inputTitle: "Focus Areas", inputCaption: "Specific areas to check", type: "select", options: ['All Grammar', 'Particles', 'Verb Conjugation', 'Honorifics'], inputExample: "All Grammar" }
        ]
    },
    
    essayLengthener: {
        prompt: "2027",
        icon: "📏",
        title: "Essay Lengthener",
        category: "Education",
        caption: "Expand essays naturally while maintaining quality and coherence.",
        inputs: [
            { inputTitle: "Original Text", inputCaption: "Paste your essay", type: "textarea", inputExample: "Current essay content..." },
            { inputTitle: "Target Length", inputCaption: "Desired length", type: "select", options: ['25% Longer', '50% Longer', 'Double Length'], inputExample: "50% Longer" },
            { inputTitle: "Areas to Expand", inputCaption: "Suggest areas to elaborate", type: "textarea", required: false, inputExample: "Examples, analysis, supporting evidence" }
        ]
    },
    
    perspectiveConverter: {
        prompt: "2028",
        icon: "🔄",
        title: "First/Second/Third Person Calculator",
        category: "Education",
        caption: "Convert text between first, second, and third person perspectives.",
        inputs: [
            { inputTitle: "Original Text", inputCaption: "Enter text to convert", type: "textarea", inputExample: "I believe this theory is correct" },
            { inputTitle: "Current Perspective", inputCaption: "Select current perspective", type: "select", options: ['First Person', 'Second Person', 'Third Person'], inputExample: "First Person" },
            { inputTitle: "Desired Perspective", inputCaption: "Select target perspective", type: "select", options: ['First Person', 'Second Person', 'Third Person'], inputExample: "Third Person" }
        ]
    }       
};

export default EducationData;
