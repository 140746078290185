import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Paperclip, Copy, Check, Bot, RotateCcw } from 'lucide-react';
import TableParser from './TableParser';
import CodeHighlighter from './CodeHighlighter';
import { getProviderIcon } from './ModelSelector';
import './ChatMessage.css';

const ChatMessage = ({ message, isAgentChat, onRetry, messages, isLastMessage, agent }) => {
  const [copied, setCopied] = useState(false);  
  const modelName = message.metadata?.model || 'AI';
  const isFirstAgentMessage = isAgentChat && messages?.[0]?.id === message.id && message.sender === 'ai';

  // Get provider name from model name
  const getProviderFromModel = (modelName) => {
    if (modelName.includes('gemini')) return 'gemini';
    if (modelName.includes('claude')) return 'claude';
    if (modelName.includes('gpt')) return 'openai';
    if (modelName.includes('Llama')) return 'llama';
    if (modelName.includes('mistral')) return 'mistral';
    if (modelName.includes('sonar')) return 'perplexity';
    if (modelName.includes('Qwen')) return 'qwen';
    if (modelName.includes('MythoMax')) return 'mytho';
    return 'gemini'; // default
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message.text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy message:', err);
    }
  };

  const handleRetry = () => {
    if (onRetry && message.sender === 'ai') {
      const userMessage = messages.findLast(msg => msg.sender === 'user');
      if (userMessage) {
        onRetry(userMessage.text);
      }
    }
  };

  const processMessageContent = (content) => {
    const lines = content.split('\n');
    let currentTable = [];
    let currentCode = [];
    let currentList = [];
    let result = [];
    let isInTable = false;
    let isInCode = false;
    let isInList = false;
    let codeLanguage = '';
  
    const processList = () => {
      if (currentList.length > 0) {
        result.push(
          <ReactMarkdown key={`list-${result.length}`}>
            {currentList.join('\n')}
          </ReactMarkdown>
        );
        currentList = [];
        isInList = false;
      }
    };
  
    lines.forEach((line) => {
      const isListItem = /^[\s]*[-*+]/.test(line);
  
      if (isListItem) {
        if (!isInList) {
          isInList = true;
        }
        currentList.push(line);
        return;
      } else if (isInList) {
        processList();
      }

      // Handle code blocks
      const codeBlockMatch = line.match(/^```(\w+)?/);
      if (codeBlockMatch) {
        if (!isInCode) {
          isInCode = true;
          codeLanguage = codeBlockMatch[1] || 'plaintext';
        } else {
          result.push(
            <CodeHighlighter
              key={`code-${result.length}`}
              code={currentCode.join('\n')}
              language={codeLanguage}
            />
          );
          currentCode = [];
          isInCode = false;
        }
        return;
      }
  
      if (isInCode) {
        currentCode.push(line);
        return;
      }
  
      // Handle tables
      const isTableLine = line.trim().startsWith('|') && line.trim().endsWith('|');
      if (isTableLine) {
        if (!isInTable) {
          isInTable = true;
        }
        currentTable.push(line);
      } else {
        if (isInTable) {
          result.push(
            <TableParser key={`table-${result.length}`} markdown={currentTable.join('\n')} />
          );
          currentTable = [];
          isInTable = false;
        }
        if (line.trim()) {
          result.push(
            <ReactMarkdown key={`text-${result.length}`}>{line}</ReactMarkdown>
          );
        }
      }
    });
  
    // Process any remaining content
    processList();
    if (currentTable.length > 0) {
      result.push(
        <TableParser key="table-final" markdown={currentTable.join('\n')} />
      );
    }
    if (currentCode.length > 0) {
      result.push(
        <CodeHighlighter
          key="code-final"
          code={currentCode.join('\n')}
          language={codeLanguage}
        />
      );
    }
  
    return result;
  };

  if (isFirstAgentMessage) {
    return (
      <div className="first-agent-message">
        <div className="agent-intro">
          <Bot size={28} className="agent-intro-icon" />
          <h2 className="agent-intro-name">{agent?.name || "AI Agent"}</h2>
        </div>
        <div className="agent-intro-message">
          {processMessageContent(message.text)}
        </div>
      </div>
    );
  }

  return (
    <div className={`message ${message.sender} ${isAgentChat ? 'agent' : ''}`}>      
      {message.sender === 'ai' ? (
        <div className="message-avatar">
          {isAgentChat ? (
            <Bot size={20} />
          ) : (
            <img
              src={getProviderIcon(getProviderFromModel(modelName))}
              alt={`${modelName} icon`}
              className="model-avatar-img"
            />
          )}
        </div>
      ) : (<></>)}    
      <div className="message-wrapper">
        {/* {message.sender === 'ai' && (
          <span className="model-name font-bold">
            {isAgentChat 
              ? (agent?.name || "AI Agent") 
              : (agent?.model || modelName)}
          </span>
        )} */}

        {message.attachments?.map((file, index) => (
          <div key={index} className="file-attachment">
            {file.type.startsWith('image/') ? (
              <img src={file.url} alt="Attachment" className="attachment-preview" />
            ) : (
              <a href={file.url} target="_blank" rel="noopener noreferrer" className="attachment-link">
                <Paperclip size={16} /> {file.name}
              </a>
            )}
          </div>
        ))}
        <div className="message-content">
          {processMessageContent(message.text)}
        </div>
        {!isFirstAgentMessage && message.sender === 'ai' && (
          <div className="message-footer">
            {message.sender === 'ai' && (
              <span className="footer-model-name">
                {isAgentChat 
                  ? (agent?.name || "AI Agent") 
                  : (agent?.model || modelName)}
              </span>
             )}
            {isLastMessage && (
              <button 
                onClick={handleRetry}
                className="retry-button"
                title="Regenerate response"
              >
                <RotateCcw size={16} />
              </button>
            )}
            <button 
              onClick={handleCopy}
              className="copy-button"
              title="Copy message"
            >
              {copied ? (
                <>
                  <Check size={16} className="text-green-500 mr-2" /> 
                </>
              ) : (
                <>
                  <Copy size={16} className="mr-2"/> 
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;