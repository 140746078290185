import { db, storage } from "../../../firebase";
import { 
  collection,
  query,
  orderBy,
  getDocs,
  getDoc,
  setDoc, 
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  where,
  arrayUnion, arrayRemove,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"; 
import * as chatService from '../AIChatServices';

class AgentFirebaseService {
  constructor() {
    this.agentCollection = 'agents';
  }

  // Clean undefined and null values
  cleanData(obj) {
    const clean = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined && obj[key] !== null) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          clean[key] = this.cleanData(obj[key]);
        } else {
          clean[key] = obj[key];
        }
      }
    });
    return clean;
  }

  // Upload icon to Firebase Storage
  async uploadIcon(file, agentId) {
    if (!file) return null;

    const fileExtension = file.name.split('.').pop();
    const iconRef = ref(storage, `agent-icons/${agentId}.${fileExtension}`);
    
    try {
      await uploadBytes(iconRef, file);
      const iconUrl = await getDownloadURL(iconRef);
      return iconUrl;
    } catch (error) {
      console.error('Error uploading icon:', error);
      throw error;
    }
  }

  // Create new agent
  async createAgent(agentData, files = []) {
    try {
      // Clean and prepare basic agent data
      const cleanedData = this.cleanData({
        name: agentData.name,
        description: agentData.description,
        baseModel: agentData.baseModel,
        systemPrompt: agentData.systemPrompt,
        category: agentData.category,
        creator: agentData.creator,
        type: 'agent',
        userId: agentData.userId,
        isAdmin: agentData.isAdmin,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
  
      // Create agent document first to get the ID
      const docRef = await addDoc(collection(db, this.agentCollection), cleanedData);
      const agentId = docRef.id;
  
      // Upload files if any exist
      let uploadedFiles = [];
      if (files && files.length > 0) {
        uploadedFiles = await Promise.all(files.map(async (file) => {
          const fileRef = ref(storage, `agents/${agentId}/files/${file.name}`);
          await uploadBytes(fileRef, file);
          const downloadUrl = await getDownloadURL(fileRef);
          
          return {
            name: file.name,
            url: downloadUrl,
            type: file.type,
            size: file.size,
            path: `agents/${agentId}/files/${file.name}`
          };
        }));
  
        // Update agent document with file references
        await updateDoc(docRef, {
          files: uploadedFiles,
          updatedAt: serverTimestamp()
        });
      }
  
      return {
        id: agentId,
        ...cleanedData,
        files: uploadedFiles
      };
    } catch (error) {
      console.error('Error creating agent:', error);
      throw error;
    }
  }
  
  // async updateAgent(agentId, updates, files = []) {
  //   try {
  //     const agentRef = doc(db, this.agentCollection, agentId);
  //     const agentDoc = await getDoc(agentRef);
  
  //     if (!agentDoc.exists()) {
  //       throw new Error('Agent not found');
  //     }
  
  //     // Check permission
  //     const agentData = agentDoc.data();
  //     if (!agentData.isAdmin && agentData.userId !== updates.userId) {
  //       throw new Error('Unauthorized to update agent');
  //     }
  
  //     // Handle files
  //     let updatedFiles = agentData.files || [];
  //     if (files && files.length > 0) {
  //       // Upload new files
  //       const newFiles = await Promise.all(files.map(async (file) => {
  //         const fileRef = ref(storage, `agents/${agentId}/files/${file.name}`);
  //         await uploadBytes(fileRef, file);
  //         const downloadUrl = await getDownloadURL(fileRef);
          
  //         return {
  //           name: file.name,
  //           url: downloadUrl,
  //           type: file.type,
  //           size: file.size,
  //           path: `agents/${agentId}/files/${file.name}`
  //         };
  //       }));
  
  //       updatedFiles = [...updatedFiles, ...newFiles];
  //     }
  
  //     const cleanedUpdates = this.cleanData({
  //       ...updates,
  //       files: updatedFiles,
  //       updatedAt: serverTimestamp()
  //     });
  
  //     await updateDoc(agentRef, cleanedUpdates);
  
  //     return {
  //       id: agentId,
  //       ...agentData,
  //       ...cleanedUpdates
  //     };
  //   } catch (error) {
  //     console.error('Error updating agent:', error);
  //     throw error;
  //   }
  // }


  async updateAgent(agentId, updates, newFiles = []) {
    try {
      const agentRef = doc(db, this.agentCollection, agentId);
      const agentDoc = await getDoc(agentRef);
  
      if (!agentDoc.exists()) {
        throw new Error('Agent not found');
      }
  
      const agentData = agentDoc.data();
      if (!agentData.isAdmin && agentData.userId !== updates.userId) {
        throw new Error('Unauthorized to update agent');
      }
  
      // Delete removed files from storage
      const existingFiles = agentData.files || [];
      const filesToKeep = updates.files || [];
      const removedFiles = existingFiles.filter(
        existingFile => !filesToKeep.some(
          keepFile => keepFile.name === existingFile.name
        )
      );
  
      // Delete files from storage
      await Promise.all(removedFiles.map(async file => {
        const fileRef = ref(storage, file.path || `agents/${agentId}/files/${file.name}`);
        try {
          await deleteObject(fileRef);
        } catch (error) {
          console.warn('Error deleting file:', error);
        }
      }));
  
      // Upload any new files
      const updatedFiles = [...filesToKeep];
      if (newFiles.length > 0) {
        const uploadedFiles = await Promise.all(newFiles.map(async file => {
          const fileRef = ref(storage, `agents/${agentId}/files/${file.name}`);
          await uploadBytes(fileRef, file);
          const url = await getDownloadURL(fileRef);
          
          return {
            name: file.name,
            url,
            type: file.type,
            size: file.size,
            path: `agents/${agentId}/files/${file.name}`
          };
        }));
        updatedFiles.push(...uploadedFiles);
      }
  
      const cleanedUpdates = this.cleanData({
        ...updates,
        files: updatedFiles,
        updatedAt: serverTimestamp()
      });
  
      await updateDoc(agentRef, cleanedUpdates);
  
      return {
        id: agentId,
        ...agentData,
        ...cleanedUpdates
      };
    } catch (error) {
      console.error('Error updating agent:', error);
      throw error;
    }
  }


  // Get all agents (admin agents and user's own agents) 
async getAgents(userId) {
  try {
    const agentsRef = collection(db, this.agentCollection);
    
    // Create a single compound query for both admin and user agents
    const combinedQuery = query(
      agentsRef,
      where('isAdmin', '==', true),
      // Note: This needs a composite index in Firebase
    );

    const agentsSnapshot = await getDocs(combinedQuery);
    const agents = agentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Add user's own non-admin agents
    const userAgentsQuery = query(
      agentsRef,
      where('userId', '==', userId),
      where('isAdmin', '==', false)
    );
    
    const userAgentsSnapshot = await getDocs(userAgentsQuery);
    const userAgents = userAgentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Combine both sets of agents
    const allAgents = [...agents, ...userAgents];
    
    // Attach favorite status
    return await this.attachFavoriteStatus(allAgents, userId);
  } catch (error) {
    console.error('Error getting agents:', error);
    if (error.code === 'failed-precondition') {
      throw new Error('Please wait a few minutes while the database indexes are being created.');
    }
    throw new Error('Failed to fetch agents. Please try again.');
  }
}
  // Get single agent
  async getAgent(agentId, userId) {
    try {
      // Fetch agent data and user data in parallel
      const [agentDoc, userDataDoc] = await Promise.all([
        getDoc(doc(db, this.agentCollection, agentId)),
        getDoc(doc(db, 'userData', userId))
      ]);
      
      if (!agentDoc.exists()) {
        return null;
      }
    
      const favorites = userDataDoc.data()?.favoriteAgents || [];
      
      return {
        id: agentDoc.id,
        ...agentDoc.data(),
        isFavorite: favorites.includes(agentId)
      };
    } catch (error) {
      console.error('Error getting agent:', error);
      throw error;
    }
  }

  // Update agent
  

  // Delete agent
  async deleteAgent(agentId, userId) {
    try {
      const agentRef = doc(db, this.agentCollection, agentId);
      const agentDoc = await getDoc(agentRef);
  
      if (!agentDoc.exists()) throw new Error('Agent not found');
  
      const agentData = agentDoc.data();
      if (!agentData.isAdmin && agentData.userId !== userId) {
        throw new Error('Unauthorized to delete agent');
      }
  
      // Delete knowledge base files
      if (agentData.files?.length) {
        await Promise.all(agentData.files.map(async file => {
          const fileRef = ref(storage, file.path);
          try {
            await deleteObject(fileRef);
          } catch (error) {
            console.warn('Error deleting file:', error);
          }
        }));
      }
  
      // Update chats using this agent
      const chatsRef = collection(db, 'chats');
      const agentChats = await getDocs(
        query(chatsRef, where('metadata.agent.id', '==', agentId))
      );
  
      await Promise.all(agentChats.docs.map(async chatDoc => {
        await updateDoc(chatDoc.ref, {
          'metadata.agentDeleted': true,
          'metadata.agentDeletedAt': serverTimestamp()
        });
      }));
  
      await deleteDoc(agentRef);
      return true;
    } catch (error) {
      console.error('Error deleting agent:', error);
      throw error;
    }
  }

   // Toggle favorite status
//    async toggleFavorite(agentId, userId) {
//     try {
//       const userDataRef = doc(db, 'userData', userId);
//       const userDataDoc = await getDoc(userDataRef);
      
//       // If userData document doesn't exist, create it with basic structure
//       if (!userDataDoc.exists()) {
//         const initialData = {
//           favoriteAgents: [],
//           createdAt: serverTimestamp()
//         };
//         await setDoc(userDataRef, initialData);
//       }
      
//       // Get current favorites
//       const updatedDoc = await getDoc(userDataRef);
//       const favorites = updatedDoc.data()?.favoriteAgents || [];
//       const isFavorite = favorites.includes(agentId);
      
//       // Update favorites list
//       await updateDoc(userDataRef, {
//         favoriteAgents: isFavorite ? 
//           arrayRemove(agentId) : 
//           arrayUnion(agentId),
//         updatedAt: serverTimestamp()
//       });
  
//       return !isFavorite;
//     } catch (error) {
//       console.error('Error toggling favorite:', error);
//       throw error;
//     }
//   }

  async addToFavorites(agentId, userId) {
    try {
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      
      // If userData document doesn't exist, create it
      if (!userDataDoc.exists()) {
        await setDoc(userDataRef, {
          favoriteAgents: [],
          createdAt: serverTimestamp()
        });
      }
      
      await updateDoc(userDataRef, {
        favoriteAgents: arrayUnion(agentId),
        updatedAt: serverTimestamp()
      });
  
      return true;
    } catch (error) {
      console.error('Error adding to favorites:', error);
      throw error;
    }
  }

  async removeFromFavorites(agentId, userId) {
    try {
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      
      if (!userDataDoc.exists()) {
        return false;
      }
      
      await updateDoc(userDataRef, {
        favoriteAgents: arrayRemove(agentId),
        updatedAt: serverTimestamp()
      });
  
      return false;
    } catch (error) {
      console.error('Error removing from favorites:', error);
      throw error;
    }
  } 

  // Get favorite status
  async getFavoriteStatus(agentId, userId) {
    try {
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      
      if (!userDataDoc.exists()) {
        return false;
      }
      
      const favorites = userDataDoc.data()?.favoriteAgents || [];
      return Array.isArray(favorites) && favorites.includes(agentId);
    } catch (error) {
      console.error('Error getting favorite status:', error);
      return false;
    }
  }
  // Get user's favorite agents
  async getFavoriteAgents(userId) {
    try {
      // 1. Get user data with favorites
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      const favorites = userDataDoc.data()?.favoriteAgents || [];
      
      if (favorites.length === 0) return [];
  
      // 2. Fetch all favorite agents in parallel
      const agentPromises = favorites.map(agentId => {
        const agentRef = doc(db, this.agentCollection, agentId);
        return getDoc(agentRef);
      });
  
      const agentDocs = await Promise.all(agentPromises);
      
      // 3. Process results
      return agentDocs
        .filter(doc => doc.exists())
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          isFavorite: true
        }));
        
    } catch (error) {
      console.error('Error getting favorite agents:', error);
      return [];
    }
  }

// Add a new method to check if agent is in favorites when loading agents
async attachFavoriteStatus(agents, userId) {
    try {
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      const favorites = userDataDoc.data()?.favoriteAgents || [];
  
      return agents.map(agent => ({
        ...agent,
        isFavorite: favorites.includes(agent.id)
      }));
    } catch (error) {
      console.error('Error attaching favorite status:', error);
      return agents; // Return original agents if there's an error
    }
  }

   // New method to create an agent chat
   async createAgentChat(agent, userId) {
    try {
      // Create welcome message with knowledge base information
      const welcomeMessage = `Welcome to ${agent.name}! I am your AI agent ${
        agent.description ? `specialized in ${agent.description}` : ''
      }. How can I help you today?`;
  
      // Create new chat
      const chatId = await chatService.createChat(
        welcomeMessage,
        userId,
        agent.baseModel
      );
  
      // Update chat metadata with agent info and files
      const chatRef = doc(db, 'chats', chatId);
      await updateDoc(chatRef, {
        'metadata.agent': {
          id: agent.id,
          name: agent.name,
          model: agent.baseModel,
          files: agent.files || [], // Include knowledge base files
        },
        'metadata.systemPrompt': agent.systemPrompt,
        'metadata.isAgentChat': true
      });
  
      // Add welcome message as first AI message
      const messagesRef = collection(chatRef, 'messages');
      await addDoc(messagesRef, {
        text: welcomeMessage,
        sender: 'ai',
        timestamp: serverTimestamp(),
        metadata: {
          model: agent.baseModel,
          provider: 'agent',
        }
      });
  
      return chatId;
    } catch (error) {
      console.error('Error creating agent chat:', error);
      throw new Error('Failed to create agent chat: ' + error.message);
    }
  }

  /****************************************************** */
  async uploadAgentFiles(agentId, files) {
    const uploadPromises = files.map(async file => {
      const filePath = `agents/${agentId}/files/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      
      return {
        name: file.name,
        url,
        type: file.type,
        size: file.size
      };
    });
  
    return Promise.all(uploadPromises);
  }
  

}

export default new AgentFirebaseService();