// ImageGenerationUtils.js

export const IMAGE_MODELS = {
    flux: [
      { 
        name: 'flux-dev', 
        level: 'creative',
        description: 'Experimental model with creative outputs' 
      }
    ],
    stability: [
      { 
        name: 'stable-diffusion-3', 
        level: 'creative',
        description: 'Latest stable diffusion model with enhanced quality' 
      }
    ]
  };
  
  export const FORMAT_OPTIONS = {
    flux: ['webp', 'jpg', 'png'],
    stability: ['png']
  };
  
  export const ASPECT_RATIOS = [
    { value: '1:1', label: 'Square (1:1)' },
    { value: '4:3', label: 'Standard (4:3)' },
    { value: '16:9', label: 'Widescreen (16:9)' },
    { value: '3:4', label: 'Portrait (3:4)' },
    { value: '9:16', label: 'Mobile (9:16)' }
  ];
  
  export const STYLE_PRESETS = [
    { value: 'photographic', label: 'Photographic' },
    { value: 'digital-art', label: 'Digital Art' },
    { value: 'cinematic', label: 'Cinematic' },
    { value: 'anime', label: 'Anime' },
    { value: 'manga', label: 'Manga' },
    { value: 'pixel-art', label: 'Pixel Art' },
    { value: 'oil-painting', label: 'Oil Painting' }
  ];
  
  export const DEFAULT_SETTINGS = {
    flux: {
      output_format: 'jpg',
      aspect_ratio: '1:1',
      steps: 28,
      width: 1024,
      height: 1024
    },
    stability: {
      output_format: 'png',
      samples: 1,
      steps: 30,
      cfg_scale: 7,
      style_preset: 'photographic',
      aspect_ratio: '1:1',
      width: 1024,
      height: 1024
    }
  };
  
  export const calculateDimensions = (aspectRatio) => {
    const [width, height] = aspectRatio.split(':').map(Number);
    const ratio = width / height;
    
    let finalWidth, finalHeight;
    
    if (ratio >= 1) {
      finalWidth = 1024;
      finalHeight = Math.round(1024 / ratio / 64) * 64;
    } else {
      finalHeight = 1024;
      finalWidth = Math.round(1024 * ratio / 64) * 64;
    }
    
    return {
      width: finalWidth,
      height: finalHeight
    };
  };