import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AlertTriangle } from 'lucide-react';

const ConfirmationModal = ({
  show,
  onHide,
  onConfirm,
  title = 'Confirm Delete',
  message = 'Are you sure you want to delete this item? This action cannot be undone.',
  confirmButtonText = 'Delete',
  cancelButtonText = 'Cancel',
  variant = 'danger'
}) => {
  const handleConfirm = () => {
    onConfirm();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="confirmation-modal comman-confirm-modal"
    >
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title className="d-flex align-items-center gap-2">
          <AlertTriangle className="text-warning" size={24} />
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <p className="mb-0">{message}</p>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button 
          variant="outline-secondary" 
          onClick={onHide}
        >
          {cancelButtonText}
        </Button>
        <Button 
          variant={variant}
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;