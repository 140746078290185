import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useUserAuth } from "../../context/UserAuthContext";
import { useFavorites } from '../../context/FavoritesContext';
import ToolsData from "../../services/toolsData/ToolsData";
import AiToolsSubmenu from './AiToolsSubmenu';
import CreditDisplay from './CreditDisplay';
import ThemeSwitch from './ThemeSwitch';
import {
  MessageSquarePlus,
  Wallet,
  Trash,
  ChevronLeft,
  ChevronRight,
  Crown,
  Image,
  Bot,
  User,
  PencilRuler,
  ChartColumn,
  MessageCircleMore,
  LogIn,
  Files,
  LogOut
} from 'lucide-react';
 

import './Sidebar.css';

const Sidebar = ({
  showSidebar,
  recentChats,
  selectedChat,
  onNewChat,
  onChatSelect,
  onDeleteChat,
  totalChats,
  isNewChat,
  onViewAllChats
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { favorites } = useFavorites();
  const { subscriptionData, logOut, user } = useUserAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showAiToolsOnly, setShowAiToolsOnly] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});

  const menuRef = React.useRef(null);

  const isActive = (path) => location.pathname.startsWith(path);
  const isAiToolsActive = (location.pathname.startsWith('/aitools') ||
    location.pathname.startsWith('/ai-tools') ||
    location.pathname.startsWith('/my-content')) && showTools;
  const showRecentChats = location.pathname.startsWith('/chat') ||
    location.pathname.startsWith('/recent-chats') ||
    location.pathname.startsWith('/agents');

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const hasCategory = params.get('category');
    const hasPrompt = params.get('prompt');
  
    // Reset to main menu on root URL
    if (location.pathname === '/') {
      setShowAiToolsOnly(false);
      setShowTools(false);
    }
    // Show AI Tools submenu if we're on ai-tools route with parameters
    else if (location.pathname.startsWith('/ai-tools') && (hasCategory || hasPrompt)) {
      setShowAiToolsOnly(true);
      setShowTools(true);
    } 
    // Hide for chat routes
    else if (location.pathname.startsWith('/chat')) {
      setShowAiToolsOnly(false);
      setShowTools(false);
    }
  }, [location.pathname, location.search]);

  const handleViewAllClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onViewAllChats();
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleAiToolsClick = () => {
    setShowAiToolsOnly(true);
    setShowTools(true);
  };

  const handleBackToMenu = () => {
    setShowAiToolsOnly(false);
    setShowTools(false); // Hide tools when clicking back
  };

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const groupByCategory = (toolsData) => {
    const allTools = Object.values(toolsData).flat();
    return allTools.reduce((acc, tool) => {
      const category = tool.category.toLowerCase();
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(tool);
      return acc;
    }, {});
  };

  const categoryEmojis = {
    "advertising": "📢",
    "blog": "📰",
    "copywriting": "✍️",
    "ecommerce": "🛒",
    "email": "📧",
    "film": "🎥",
    "hr": "👥",
    "idea": "💡",
    "repurpose": "♻️",
    "image": "🖼️",
    "music": "🎶",
    "seo": "🔍",
    "sales": "💰",
    "social media": "🌐",
    "support": "🛠️",
    "website": "🌍",
    "writing assistant": "📝",
    "youtube": "▶️",
    "education": "🎓",
    "podcast": "🎙️",
    "story writing": "📜",
    "extra": "🌟"
  };

  const renderMainNavigation = () => (
    <nav className="navigation-links">
      {!showAiToolsOnly ? (
        <>
          <Link
            to="/chat/new"
            className={`nav-link ${isActive('/chat') ? 'active' : ''}`}
          >
            <MessageSquarePlus size={16} />
            <span>Start new chat</span>
          </Link>

          <Link
            to="/agents"
            className={`nav-link ${isActive('/agents') ? 'active' : ''}`}
          >
            <Bot size={16} />
            <span>Agents</span>
          </Link>

          <Link
            to="/image-generation"
            className={`nav-link ${isActive('/image-generation') ? 'active' : ''}`}
          >
            <Image size={16} />
            <span>Image Generation</span>
          </Link>

          <Link
            to="/keyword-research"
            className={`nav-link ${isActive('/keyword-research') ? 'active' : ''}`}
          >
            <ChartColumn size={16} />
            <span>Keyword Research</span>
          </Link>

          <Link
            to="/aitools"
            className={`nav-link ${isActive('/aitools') ? 'active' : ''}`}
            onClick={handleAiToolsClick}
          >
            <PencilRuler size={16} />
            <span>AI Tools</span>
            <ChevronRight size={16} className="ml-auto" />
          </Link>
        </>
      ) : (
        <div className="ai-tools-header">
          <Link
            to="#"
            className="nav-link back-button w-100"
            onClick={handleBackToMenu}
          >
            <ChevronLeft size={16} /> 
          
          <span className="ai-tools-title cursor-pointer">Back to Main</span></Link>
        </div>
      )}

      {(isAiToolsActive || showAiToolsOnly) && <AiToolsSubmenu toolsData={ToolsData} />}
    </nav>
  );

  return (
    <div className={`sidebar-wrapper`}>
      <div className='nav-content'>
        {renderMainNavigation()}
      </div>

      {showRecentChats && !showAiToolsOnly && (
        <div className="chat-list-section">
          <h3 className="sub-heading">
            <MessageCircleMore size={17} className='mr-2' /> Recent Chats
          </h3>
          <div className="chat-list">
            {recentChats.map(chat => (
              <div
                key={chat.id}
                className={`chat-item ${selectedChat?.id === chat.id ? 'active' : ''}`}
                onClick={() => onChatSelect(chat)}
              >
                <span className="chat-preview">
                  {chat.metadata?.lastMessage?.text || 'New Chat'}
                </span>
                <Button
                  variant="link"
                  className="delete-chat"
                  onClick={(e) => onDeleteChat(chat.id, e)}
                >
                  <Trash size={16} />
                </Button>
              </div>
            ))}
          </div>
          {totalChats > 10 && (
            <Button
              variant="link"
              className="view-all-btn"
              onClick={handleViewAllClick}
            >
              View All Chats ({totalChats})
            </Button>
          )}
        </div>
      )}

      <div className="sidebar-footer">
        {user ? (
          <>
            {subscriptionData && (
              <CreditDisplay
                subscriptionData={subscriptionData}
                onUpgrade={() => setShowUpgradeModal(true)}
              />
            )}

            <div className="user-menu" ref={menuRef}>
              <button
                className="user-menu-button"
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <User size={16} />
                <span>My Account</span>
              </button>

              {showUserMenu && (
                <ul className="user-menu-dropdown">
                  <li>
                    <NavLink to="/profile" className="sidebar-link" onClick={() => setShowUserMenu(false)}>
                      <User size={16} /> My Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/subscription" className="sidebar-link" onClick={() => setShowUserMenu(false)}>
                      <Wallet size={16} />Subscription
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/my-content" className="sidebar-link" onClick={() => setShowUserMenu(false)}>
                      <Files size={16} />My content
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/pricing" className="sidebar-link" onClick={() => setShowUserMenu(false)}>
                      <Crown size={16} /> Pricing Plans
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://wordkraft.ai/support/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="sidebar-link"
                      onClick={() => setShowUserMenu(false)}
                    >
                      <MessageSquarePlus size={16} /> Support
                    </a>
                  </li>
                  <li>
                    <a onClick={handleLogout} className="sidebar-link logout-button">
                      <LogOut size={16} /> Logout
                    </a>
                  </li>

                  <ThemeSwitch />
                </ul>
              )}
            </div>
          </>
        ) : (
          <button
            className="user-menu-button"
            onClick={() => navigate('/login')}
          >
            <LogIn size={16} />
            <span>Login</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;