import { createContext, useContext, useEffect, useState, useCallback } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import SubscriptionService from "./SubscriptionService";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);

  // Fetch subscription data with useCallback
  const fetchSubscriptionData = useCallback(async (userId) => {
    if (!userId) return;
    
    try {
      const subscription = await SubscriptionService.getSubscription(userId);
      setSubscriptionData(subscription);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  }, []);

  // Modified signup function to initialize subscription
  async function signUp(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Initialize subscription for new user
      await SubscriptionService.initializeSubscription(user.uid);
      
      // Send verification email
      await sendEmailVerification(user);
      navigate('/verify-email');
    } catch (error) {
      console.error("Error during signup:", error);
      alert(error.message);
    }
  }

  // Modified Google sign in to initialize subscription
  async function googleSignIn() {
    try {
      const googleAuthProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleAuthProvider);
      
      // Initialize subscription for new Google users
      await SubscriptionService.initializeSubscription(result.user.uid);
      
      return result;
    } catch (error) {
      console.error("Error during Google sign in:", error);
      throw error;
    }
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function passwordReset(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function logOut() {
    localStorage.removeItem("user");
    setSubscriptionData(null);
    return signOut(auth);
  }

  // Update credits function with useCallback
  const updateCredits = useCallback(async (creditType, amount) => {
    if (!user?.uid) return;
    
    try {
      await SubscriptionService.updateCredits(user.uid, creditType, amount);
      await fetchSubscriptionData(user.uid);
    } catch (error) {
      console.error("Error updating credits:", error);
      throw error;
    }
  }, [user?.uid, fetchSubscriptionData]);

  // Auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      
      if (currentUser) {
        // Fetch subscription data when user logs in
        await fetchSubscriptionData(currentUser.uid);
      } else {
        setSubscriptionData(null);
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, [fetchSubscriptionData]);

  // Check and refresh daily credits
  useEffect(() => {
    const checkDailyCredits = async () => {
      if (user?.uid) {
        try {
          const wasRefreshed = await SubscriptionService.refreshDailyCredits(user.uid);
          if (wasRefreshed) {
            await fetchSubscriptionData(user.uid);
          }
        } catch (error) {
          console.error("Error checking daily credits:", error);
        }
      }
    };

    checkDailyCredits();
  }, [user?.uid, fetchSubscriptionData]);

  // Refresh subscription with useCallback
  const refreshSubscription = useCallback(async () => {
    if (!user?.uid) return;
    await fetchSubscriptionData(user.uid);
  }, [user?.uid, fetchSubscriptionData]);

  const value = {
    user,
    subscriptionData,
    logIn,
    signUp,
    logOut,
    googleSignIn,
    passwordReset,
    updateCredits,
    refreshSubscription
  };

  return (
    <userAuthContext.Provider value={value}>
      {!loading && children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}