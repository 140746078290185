import React, { useState, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import CommonDataServices from "../../services/common.services";
import ToolsData from "../../services/toolsData/ToolsData";
import { MyContentIcon } from "../../assets/svg/SvgIcons";
import "./Dashboard.css";

const AITools = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstName, setFirstName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState("all");
  const groupedTools = groupByCategory(ToolsData);

  // Parse URL parameters on component mount and URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    if (category && groupedTools[category.toLowerCase()]) {
      setSelectedCategory(category.toLowerCase());
    }
  }, [location.search]);

  useEffect(() => { 
    const fetchData = async () => {
      if(user){
        const userD = await CommonDataServices.getUsageData(user.uid);
        const userData = userD.data(); 
        if (userData) {
          if ('firstName' in userData) { 
            setFirstName(userData.firstName); 
          } else {
            setFirstName(user.displayName); 
          } 
        }
      } 
    };  
    fetchData();
  }, [user]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    
    // Update URL with new category while preserving prompt if it exists
    const params = new URLSearchParams(location.search);
    const currentPrompt = params.get('prompt');
    
    const newParams = new URLSearchParams();
    if (category !== 'all') {
      newParams.set('category', category);
    }
    if (currentPrompt) {
      newParams.set('prompt', currentPrompt);
    }
    
    navigate(`/aitools${newParams.toString() ? `?${newParams.toString()}` : ''}`);
  };

  const handleDropdownChange = (event) => {
    handleCategoryClick(event.target.value);
  };

  const filteredTools =
    selectedCategory === "all"
      ? Object.values(groupedTools).flat()
      : groupedTools[selectedCategory];

  const handleToolClick = (tool) => {
    // Update URL with both prompt and category
    const newParams = new URLSearchParams();
    newParams.set('prompt', tool.prompt);
    
    // Always include the tool's category, even when in "all" view
    const toolCategory = tool.category.toLowerCase();
    newParams.set('category', toolCategory);
    
    return `/ai-tools?${newParams.toString()}`;
  };

  return (
    <>
      <Container className="main-layout templates">
        <div className="header-txt d-flex justify-content-between">
          <h2 className="mt-2 mb-3 text-left">
            👋 Hey {user && firstName}
            <span className="text-left dashCaption"> - Let's elevate your content creation.</span>
          </h2>
          <Link to="/my-content" className="sidebar-link my-content">
            <MyContentIcon size={16}/>
            My content
          </Link>
        </div>

        <div className="category-dropdown d-block d-md-none">
          <select onChange={handleDropdownChange} value={selectedCategory} className="form-select form-select-sm mb-4">
            <option value="all">All Tools</option>
            {Object.keys(groupedTools).map((category) => (
              <option key={category} value={category}>
                {categoryEmojis[category]} {capitalizeFirstLetter(category)} Tools
              </option>
            ))}
          </select>
        </div>

        <div className="card-sort-btns mb-4">
          <Button
            variant="outline-primary"
            size="sm"
            className={selectedCategory === "all" ? "active" : ""}
            title="all"
            onClick={() => handleCategoryClick("all")}
          >
            All<span className="badge badge-light">{Object.values(groupedTools).flat().length}</span>
          </Button>
          {Object.keys(groupedTools).map((category) => (
            <Button
              key={category}
              variant="outline-primary"
              size="sm"
              className={selectedCategory === category ? "active" : ""}
              title={category}
              onClick={() => handleCategoryClick(category)}
            >
              {categoryEmojis[category]} {capitalizeFirstLetter(category)} Tools
              <span className="badge badge-light">{groupedTools[category].length}</span>
            </Button>
          ))}
        </div>

        <div className="card-grid-row tools-cards mt-1">
          {filteredTools.map((tool, index) => (
            <Card title="template" key={index} className={selectedCategory}>
              <Link to={handleToolClick(tool)}>
                <div className="card-wrap">
                  <div className="card-icon">
                    <div className="emoji">
                      {tool.icon}
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>{tool.title}</Card.Title>
                    <Card.Text>{tool.caption}</Card.Text>
                  </Card.Body>
                </div>
              </Link>
            </Card>
          ))}
        </div>
      </Container>
    </>
  );
};

const groupByCategory = (toolsData) => {
  const allTools = Object.values(toolsData).flat();
  return allTools.reduce((acc, tool) => {
    const category = tool.category.toLowerCase();
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(tool);
    return acc;
  }, {});
};

const categoryEmojis = {
  "advertising": "📢",
  "blog": "📰",
  "copywriting": "✍️",
  "ecommerce": "🛒",
  "email": "📧", 
  "film": "🎥",
  "hr": "👥",
  "idea": "💡",
  "repurpose": "♻️",
  "image": "🖼️",
  "music": "🎶",
  "seo": "🔍",
  "sales": "💰",
  "social media": "🌐",
  "support": "🛠️",
  "website": "🌍",
  "writing assistant": "📝",
  "youtube": "▶️",  
  "education": "🎓",
  "podcast": "🎙️",
  "story writing": "📜",  
  "extra": "🌟"
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default AITools;