import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Save } from 'lucide-react';
import ModelSelector from '../ModelSelector';
import AgentFileUpload from './AgentFileUpload';
import { useUserAuth } from "../../../context/UserAuthContext";

const CreateAgentForm = ({ 
  onSubmit, 
  onCancel, 
  isAdmin, 
  mode = 'create', 
  initialData = null,
  readOnly = false 
}) => {
  const { user } = useUserAuth();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    baseModel: 'gemini-1.5-flash',
    systemPrompt: '',
    category: '',
    creator: isAdmin ? 'WordKraft' : '',
  });
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialData) {
      setFormData({
        id: initialData.id,
        name: initialData.name || '',
        description: initialData.description || '',
        baseModel: initialData.baseModel || 'gemini-1.5-flash',
        systemPrompt: initialData.systemPrompt || '',
        category: initialData.category || '',
        creator: initialData.creator || (isAdmin ? 'WordKraft' : ''),
      });
      setFiles(initialData.files || []);
    }
  }, [initialData, isAdmin]);

  const handleModelSelect = (model) => {
    if (!readOnly) {
      setFormData(prev => ({
        ...prev,
        baseModel: model
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (readOnly) {
      onCancel();
      return;
    }
  
    if (!formData.name || !formData.description || !formData.baseModel || !formData.systemPrompt || !formData.category) {
      setError('Please fill in all required fields');
      return;
    }
  
    try {
      const fileData = files.map(file => ({
        name: file.name,
        size: file.size,
        type: file.type
      }));
  
      const submissionData = {
        ...formData,
        files: fileData,
        userId: user?.uid,
        isAdmin: isAdmin,
        ...(mode === 'edit' && initialData?.id && { id: initialData.id })
      };
  
      await onSubmit(submissionData, files, mode);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="create-agent-form">
      <Form onSubmit={handleSubmit}>
        {error && <Alert variant="danger" className="mb-3">{error}</Alert>}

        <Form.Group className="mb-3">
          <Form.Label>Agent Name</Form.Label>
          <Form.Control
            type="text"
            value={formData.name}
            onChange={(e) => !readOnly && setFormData({ ...formData, name: e.target.value })}
            placeholder="Enter agent name"
            required
            disabled={readOnly}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            value={formData.description}
            onChange={(e) => !readOnly && setFormData({ ...formData, description: e.target.value })}
            placeholder="Enter agent description"
            required
            disabled={readOnly}
          />
        </Form.Group>

        <Form.Group className="mb-3 d-flex pt-3">
          <Form.Label>Base AI Model</Form.Label>
          <ModelSelector 
            onModelSelect={handleModelSelect}
            initialModel={formData.baseModel}
            className="agent-model-select"
            disabled={readOnly}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Instructions (System Prompt)</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={formData.systemPrompt}
            onChange={(e) => !readOnly && setFormData({ ...formData, systemPrompt: e.target.value })}
            placeholder="Define the agent's behavior and capabilities"
            required
            disabled={readOnly}
          />
          <Form.Text className="text-muted">
            Define the agent's behavior and capabilities
          </Form.Text>
        </Form.Group>

        {!readOnly && (
          <AgentFileUpload
            files={files}
            onFileAdd={(newFiles) => setFiles([...files, ...newFiles])}
            onFileRemove={(index) => setFiles(files.filter((_, i) => i !== index))}
          />
        )}

        {readOnly && files.length > 0 && (
          <Form.Group className="mb-3">
            <Form.Label>Uploaded Files</Form.Label>
            <div className="file-list">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  {file.name} ({Math.round(file.size / 1024)} KB)
                </div>
              ))}
            </div>
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            value={formData.category}
            onChange={(e) => !readOnly && setFormData({ ...formData, category: e.target.value })}
            placeholder="e.g., Sales, Writing, Analysis"
            required
            disabled={readOnly}
          />
        </Form.Group>

        {isAdmin && (
          <Form.Group className="mb-3">
            <Form.Label>Creator</Form.Label>
            <Form.Control
              type="text"
              value={formData.creator}
              onChange={(e) => !readOnly && setFormData({ ...formData, creator: e.target.value })}
              placeholder="Enter creator name"
              disabled={readOnly}
            />
          </Form.Group>
        )}

        <div className="d-flex gap-2 mt-4">
          <Button variant="secondary" onClick={onCancel}>
            Close
          </Button>
          {!readOnly && (
            <Button type="submit" variant="primary" className="d-flex align-items-center gap-2">
              <Save size={16} />
              {mode === 'edit' ? 'Update Agent' : 'Create Agent'}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CreateAgentForm;