const CareerToolsData = {
    resumeSummary: {
        prompt: "23101",
        icon: "📝",
        title: "Resume Summary Generator",
        category: "Resume-Jobs",
        caption: "Create compelling resume summaries that highlight your professional value.",
        inputs: [
            { inputTitle: "Professional Background", inputCaption: "Your work experience and role", type: 'textarea', inputExample: "5 years of software development experience" },
            { inputTitle: "Key Achievements", inputCaption: "Notable accomplishments", type: 'textarea', inputExample: "Led development of award-winning mobile app" },
            { inputTitle: "Target Role", inputCaption: "Position you're applying for", type: 'text', inputExample: "Senior Software Engineer" }
        ]
    },

    resumeObjectives: {
        prompt: "23102",
        icon: "🎯",
        title: "Resume Objectives Generator",
        category: "Resume-Jobs",
        caption: "Generate clear and focused career objectives for your resume.",
        inputs: [
            { inputTitle: "Career Goal", inputCaption: "What position are you targeting?", type: 'text', inputExample: "Entry-level marketing position" },
            { inputTitle: "Key Skills", inputCaption: "Relevant skills for the position", type: 'textarea', inputExample: "Digital marketing, social media management" },
            { inputTitle: "Industry", inputCaption: "Target industry", type: 'text', inputExample: "Technology" }
        ]
    },

    resumeSkills: {
        prompt: "23103",
        icon: "💪",
        title: "Resume Skills Generator",
        category: "Resume-Jobs",
        caption: "Create comprehensive lists of technical and soft skills for your resume.",
        inputs: [
            { inputTitle: "Job Title", inputCaption: "Position you're applying for", type: 'text', inputExample: "Project Manager" },
            { inputTitle: "Industry", inputCaption: "Industry sector", type: 'text', inputExample: "Construction" },
            { inputTitle: "Experience Level", inputCaption: "Years of experience", type: 'text', inputExample: "5+ years" },
            { inputTitle: "Key Technologies", inputCaption: "Relevant technical skills", type: 'textarea', inputExample: "MS Project, Jira" }
        ]
    },

    coverLetter: {
        prompt: "23104",
        icon: "✉️",
        title: "Cover Letter Generator",
        category: "Resume-Jobs",
        caption: "Create personalized and compelling cover letters.",
        inputs: [
            { inputTitle: "Job Position", inputCaption: "Position you're applying for", type: 'text', inputExample: "Marketing Manager" },
            { inputTitle: "Company Name", inputCaption: "Target company", type: 'text', inputExample: "Google" },
            { inputTitle: "Key Qualifications", inputCaption: "Relevant experience and skills", type: 'textarea', inputExample: "10 years marketing experience, MBA" },
            { inputTitle: "Specific Achievements", inputCaption: "Notable accomplishments", type: 'textarea', inputExample: "Increased sales by 50%" }
        ]
    },

    thankYouLetter: {
        prompt: "23105",
        icon: "🙏",
        title: "Thank You Letter Generator",
        category: "Resume-Jobs",
        caption: "Create professional post-interview thank you letters.",
        inputs: [
            { inputTitle: "Interviewer Name", inputCaption: "Who conducted the interview?", type: 'text', inputExample: "John Smith" },
            { inputTitle: "Position", inputCaption: "Position interviewed for", type: 'text', inputExample: "Senior Developer" },
            { inputTitle: "Interview Date", inputCaption: "When was the interview?", type: 'text', inputExample: "January 15, 2024" },
            { inputTitle: "Key Discussion Points", inputCaption: "Important topics discussed", type: 'textarea', inputExample: "Project management experience, team leadership" }
        ]
    },

    professionalBio: {
        prompt: "23106",
        icon: "👤",
        title: "Professional Biography Generator",
        category: "Resume-Jobs",
        caption: "Create engaging professional biographies for various purposes.",
        inputs: [
            { inputTitle: "Professional Background", inputCaption: "Your career history", type: 'textarea', inputExample: "15 years in financial services" },
            { inputTitle: "Key Achievements", inputCaption: "Notable accomplishments", type: 'textarea', inputExample: "Led $10M merger acquisition" },
            { inputTitle: "Education", inputCaption: "Academic background", type: 'textarea', inputExample: "MBA from Harvard" },
            { inputTitle: "Bio Length", inputCaption: "Desired length", type: 'select', options: ['Short', 'Medium', 'Long'] }
        ]
    },

    portfolioDescription: {
        prompt: "23107",
        icon: "📁",
        title: "Portfolio Description Generator",
        category: "Resume-Jobs",
        caption: "Create compelling descriptions for your work samples and projects.",
        inputs: [
            { inputTitle: "Project Type", inputCaption: "Type of work/project", type: 'text', inputExample: "Mobile App Development" },
            { inputTitle: "Role", inputCaption: "Your role in the project", type: 'text', inputExample: "Lead Developer" },
            { inputTitle: "Key Features", inputCaption: "Notable aspects", type: 'textarea', inputExample: "AI integration, Real-time analytics" },
            { inputTitle: "Results", inputCaption: "Project outcomes", type: 'textarea', inputExample: "500,000+ downloads" }
        ]
    },

    referenceList: {
        prompt: "23108",
        icon: "📋",
        title: "Reference List Generator",
        category: "Resume-Jobs",
        caption: "Create professional reference lists with proper formatting.",
        inputs: [
            { inputTitle: "Reference Type", inputCaption: "Professional or Academic", type: 'select', options: ['Professional', 'Academic', 'Both'] },
            { inputTitle: "Reference Details", inputCaption: "Contact information and roles", type: 'textarea', inputExample: "John Smith, CEO, Company X" },
            { inputTitle: "Relationship", inputCaption: "Your connection to references", type: 'textarea', inputExample: "Former supervisor for 3 years" }
        ]
    },

    letterOfRecommendation: {
        prompt: "23109",
        icon: "📜",
        title: "Recommendation Letter Generator",
        category: "Resume-Jobs",
        caption: "Generate professional letters of recommendation.",
        inputs: [
            { inputTitle: "Candidate Name", inputCaption: "Who is the recommendation for?", type: 'text', inputExample: "Jane Doe" },
            { inputTitle: "Relationship", inputCaption: "Your connection to candidate", type: 'text', inputExample: "Direct Supervisor" },
            { inputTitle: "Duration", inputCaption: "Length of association", type: 'text', inputExample: "3 years" },
            { inputTitle: "Key Strengths", inputCaption: "Notable qualities and achievements", type: 'textarea', inputExample: "Leadership, Innovation" }
        ]
    },

    interviewQuestions: {
        prompt: "23110",
        icon: "❓",
        title: "Interview Questions Generator",
        category: "Resume-Jobs",
        caption: "Generate relevant interview questions for both parties.",
        inputs: [
            { inputTitle: "Position", inputCaption: "Job title", type: 'text', inputExample: "Software Engineer" },
            { inputTitle: "Experience Level", inputCaption: "Required experience", type: 'text', inputExample: "Senior Level" },
            { inputTitle: "Question Type", inputCaption: "Technical/Behavioral/Both", type: 'select', options: ['Technical', 'Behavioral', 'Both'] },
            { inputTitle: "Key Skills", inputCaption: "Required skills", type: 'textarea', inputExample: "JavaScript, React, Node.js" }
        ]
    },

    behavioralInterviewResponses: {
        prompt: "23111",
        icon: "🎯",
        title: "STAR Method Response Generator",
        category: "Resume-Jobs",
        caption: "Create structured behavioral interview responses using the STAR method.",
        inputs: [
            { inputTitle: "Situation Type", inputCaption: "Type of scenario", type: 'text', inputExample: "Conflict Resolution" },
            { inputTitle: "Context", inputCaption: "Background information", type: 'textarea', inputExample: "Team disagreement on project approach" },
            { inputTitle: "Role", inputCaption: "Your position", type: 'text', inputExample: "Team Lead" },
            { inputTitle: "Outcome", inputCaption: "Results achieved", type: 'textarea', inputExample: "Successfully aligned team and completed project" }
        ]
    },

    salaryNegotiation: {
        prompt: "23112",
        icon: "💰",
        title: "Salary Negotiation Script Generator",
        category: "Resume-Jobs",
        caption: "Create effective salary negotiation scripts and responses.",
        inputs: [
            { inputTitle: "Position Level", inputCaption: "Job level", type: 'text', inputExample: "Senior Manager" },
            { inputTitle: "Industry", inputCaption: "Field of work", type: 'text', inputExample: "Technology" },
            { inputTitle: "Experience", inputCaption: "Years of experience", type: 'text', inputExample: "8 years" },
            { inputTitle: "Current Offer", inputCaption: "Details of offer", type: 'textarea', inputExample: "$120,000 base + benefits" }
        ]
    },

    jobDescription: {
        prompt: "23113",
        icon: "📋",
        title: "Job Description Generator",
        category: "Resume-Jobs",
        caption: "Create comprehensive job descriptions.",
        inputs: [
            { inputTitle: "Job Title", inputCaption: "Position name", type: 'text', inputExample: "Senior Product Manager" },
            { inputTitle: "Department", inputCaption: "Team or department", type: 'text', inputExample: "Product Development" },
            { inputTitle: "Level", inputCaption: "Career level", type: 'text', inputExample: "Senior" },
            { inputTitle: "Key Responsibilities", inputCaption: "Main duties", type: 'textarea', inputExample: "Product strategy, team leadership" }
        ]
    },

    jobResponsibilities: {
        prompt: "23114",
        icon: "📝",
        title: "Job Responsibilities Generator",
        category: "Resume-Jobs",
        caption: "Generate detailed job responsibility lists.",
        inputs: [
            { inputTitle: "Position", inputCaption: "Job title", type: 'text', inputExample: "Marketing Director" },
            { inputTitle: "Level", inputCaption: "Career level", type: 'text', inputExample: "Director" },
            { inputTitle: "Department", inputCaption: "Team or department", type: 'text', inputExample: "Marketing" },
            { inputTitle: "Key Areas", inputCaption: "Main areas of responsibility", type: 'textarea', inputExample: "Strategy, team management, budget" }
        ]
    },

    jobRequirements: {
        prompt: "23115",
        icon: "📋",
        title: "Job Requirements Generator",
        category: "Resume-Jobs",
        caption: "Create comprehensive job requirement lists.",
        inputs: [
            { inputTitle: "Position", inputCaption: "Job title", type: 'text', inputExample: "Data Scientist" },
            { inputTitle: "Experience Level", inputCaption: "Required experience", type: 'text', inputExample: "5+ years" },
            { inputTitle: "Education", inputCaption: "Required education", type: 'text', inputExample: "Master's in Computer Science" },
            { inputTitle: "Technical Skills", inputCaption: "Required technical skills", type: 'textarea', inputExample: "Python, Machine Learning, SQL" }
        ]
    }
};

export default CareerToolsData;