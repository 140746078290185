const AdvertisingToolsData = {
    adCopy: {
        prompt: "101",
        icon: "📝",
        title: "Ad Copy",
        category: "advertising",
        caption: "Enhance your Google Ads with attention-grabbing headlines and persuasive descriptions.",
        inputs: [
            { inputTitle: 'Product Name?', inputCaption: 'Enter the name of your product.', inputExample: 'Eco-Friendly Water Bottles' },
            { inputTitle: 'Enter your product description', inputCaption: 'Provide a detailed description of your product.', inputExample: 'Our water bottles are made from 100% recycled materials, keeping you hydrated and the planet healthy.', type: 'textarea' }
        ]
    },
    googleAdCopy: {
        prompt: "102",
        icon: "🔍",
        title: "Google Ads",
        category: "advertising",
        caption: "Elevate your Google Ads with compelling headlines and captivating descriptions.",
        inputs: [
            { inputTitle: 'Enter your product features or focus Keyword', inputCaption: 'Type the main features or keyword for your ad.', inputExample: 'Sustainable, leak-proof, durable', type: "textarea" },
            { inputTitle: 'Who is the target audience', inputCaption: 'Describe your target audience.', inputExample: 'Environmentally conscious consumers', required: 'false' },
            { inputTitle: 'What is the advertising goal of the ad?', inputCaption: 'State your goal, e.g., increase website visits.', inputExample: 'Boost online sales by 30%', required: 'false' }
        ]
    },
    facebookAdCopy: {
        prompt: "104",
        icon: "📘",
        title: "Facebook Ads",
        category: "advertising",
        caption: "An AI-powered tool for formulating effective Facebook Ads, including primary text, headlines, and descriptions.",
        inputs: [
            { inputTitle: 'Describe your Product / Service', inputCaption: 'Detail what you are promoting, the target audience, and main benefits.', inputExample: 'Introducing our new line of eco-friendly water bottles designed to make a difference. Perfect for the eco-conscious consumer looking to reduce their carbon footprint.', type: 'textarea' },
            { inputTitle: 'No. of Ad Copies you need?', inputCaption: 'Choose the number of ad copies required.', inputExample: '3', type: "select", options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] }
        ]
    },
    linkedInAd: {
        prompt: "108",
        icon: "💼",
        title: "LinkedIn Ads",
        category: "advertising",
        caption: "Maximize your LinkedIn ad campaigns' effectiveness with our AI-driven ad creation tool.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter the name of your product or brand.', inputExample: 'Eco-Friendly Water Bottles' },
            { inputTitle: 'Describe your Product / Service', inputCaption: 'Provide a detailed description of what you are offering.', inputExample: 'Our bottles are designed for those who are not only passionate about their hydration but also about making a positive impact on the environment.', type: 'textarea' },
            { inputTitle: 'Who is your target audience?', inputCaption: 'Describe the audience you are targeting.', inputExample: 'Professionals interested in sustainability and eco-friendly products.' }
        ]
    },
    tikTokAds: {
        prompt: "109",
        icon: "🎵",
        title: "TikTok Ads",
        category: "advertising",
        caption: "Quickly create targeted TikTok ads that are designed to grab and hold the audience's attention.",
        inputs: [
            { inputTitle: `What's the focus of your TikTok ad?`, inputCaption: 'Detail the main focus or theme of your ad.', inputExample: 'Highlighting the unique features of our eco-friendly water bottles through creative and engaging content.' },
            { inputTitle: 'Who is your Target Audience?', inputCaption: 'Describe the audience you intend to target.', inputExample: 'Young, eco-conscious individuals looking for sustainable lifestyle products.', required: 'false' }
        ]
    },
    instagramAdCopy: {
        prompt: "119",
        icon: "📸",
        title: "Instagram Ads",
        category: "advertising",
        caption: "Create visually appealing and engaging Instagram ads to capture your audience's attention.",
        inputs: [
            { inputTitle: 'Describe your Product / Service', inputCaption: 'Provide details about what you are promoting.', inputExample: 'Our latest range of eco-friendly water bottles comes in various colors and designs, perfect for your daily hydration needs.', type: 'textarea' },
            { inputTitle: 'Define your Visual Theme', inputCaption: 'What visual style or theme do you want for your ad?', inputExample: 'Bright and earthy tones to highlight eco-friendliness', required: 'false' }
        ]
    },
    twitterAdCopy: {
        prompt: "111",
        icon: "🐦",
        title: "Twitter Ads",
        category: "advertising",
        caption: "Generate concise and impactful Twitter ads to engage with your followers and beyond.",
        inputs: [
            { inputTitle: 'What is your message?', inputCaption: 'Summarize the key message of your ad.', inputExample: 'Stay hydrated and eco-conscious with our new range of water bottles. #EcoFriendly #Hydration' },
            { inputTitle: 'Target Audience Interests', inputCaption: 'Describe the interests of your target audience.', inputExample: 'Sustainability, environmental activism, health and wellness', required: 'false' }
        ]
    },
    pinterestAdCopy: {
        prompt: "112",
        icon: "📌",
        title: "Pinterest Ads",
        category: "advertising",
        caption: "Design Pinterest ads that resonate with your audience's interests and inspire action.",
        inputs: [
            { inputTitle: 'Product/Service Description', inputCaption: 'Detail the features of what you’re promoting.', inputExample: 'Our eco-friendly water bottles are not just a statement of style but also a commitment to reducing plastic waste.', type: 'textarea' },
            { inputTitle: 'Visual Elements', inputCaption: 'Describe the visual elements or themes you want to include.', inputExample: 'Images featuring bottles in natural settings, emphasizing sustainability', required: 'false' }
        ]
    },
    snapchatAdCopy: {
        prompt: "113",
        icon: "👻",
        title: "Snapchat Ads",
        category: "advertising",
        caption: "Craft catchy Snapchat ads that connect with a younger audience in a fun and engaging way.",
        inputs: [
            { inputTitle: 'Ad Content Description', inputCaption: 'Explain the content and purpose of your ad.', inputExample: 'Showcasing the cool, fun side of staying hydrated with our eco-friendly water bottles through dynamic and creative snaps.' },
            { inputTitle: 'Creative Style', inputCaption: 'What creative style do you prefer for your ad?', inputExample: 'Lively and colorful with quick, engaging visuals', required: 'false' }
        ]
    },
    bingAdCopy: {
        prompt: "114",
        icon: "🔍",
        title: "Bing Ads",
        category: "advertising",
        caption: "Optimize your ads for Bing's search audience with targeted headlines and descriptions.",
        inputs: [
            { inputTitle: 'Main Keywords', inputCaption: 'List the main keywords for your ad.', inputExample: 'Eco-friendly water bottles, sustainable hydration solutions' },
            { inputTitle: 'Ad Objective', inputCaption: 'What is the primary goal of your ad?', inputExample: 'Drive more traffic to our website and increase sales', required: 'false' }
        ]
    },
    amazonAdCopy: {
        prompt: "115",
        icon: "🛒",
        title: "Amazon Ads",
        category: "advertising",
        caption: "Enhance your product visibility on Amazon with compelling ad copy that drives sales.",
        inputs: [
            { inputTitle: 'Product Features', inputCaption: 'Describe the key features of your product.', inputExample: 'Made from recycled materials, BPA-free, comes in multiple colors', type: 'textarea' },
            { inputTitle: 'Target Customer Profile', inputCaption: 'Who is your ideal customer?', inputExample: 'Eco-conscious shoppers looking for sustainable everyday products', required: 'false' }
        ]
    },
    youtubeAdCopy: {
        prompt: "116",
        icon: "🎥",
        title: "YouTube Ads",
        category: "advertising",
        caption: "Create YouTube ads that captivate viewers and encourage them to take action.",
        inputs: [
            { inputTitle: 'Video Content Theme', inputCaption: 'What is the theme or main idea of your video content?', inputExample: 'A day in the life of an eco-friendly water bottle user' },
            { inputTitle: 'Call to Action', inputCaption: 'What do you want viewers to do after watching your ad?', inputExample: 'Visit our website to learn more and make a purchase', required: 'false' }
        ]
    },
    taboolaAdCopy: {
        prompt: "117",
        icon: "📰",
        title: "Taboola Ads",
        category: "advertising",
        caption: "Generate engaging content for Taboola ads that attract clicks and drive traffic.",
        inputs: [
            { inputTitle: 'Headline Ideas', inputCaption: 'Provide potential headlines for your ad.', inputExample: 'Discover the Water Bottle That’s Saving the Planet One Sip at a Time' },
            { inputTitle: 'Content Overview', inputCaption: 'Briefly describe the content you are promoting.', inputExample: 'An in-depth look at how our eco-friendly water bottles are made and their impact on the environment.', type: 'textarea' }
        ]
    },
    admobAdCopy: {
        prompt: "118",
        icon: "📱",
        title: "AdMob Ads",
        category: "advertising",
        caption: "Create effective mobile ads for apps using AdMob, tailored to engage and convert.",
        inputs: [
            { inputTitle: 'App Features', inputCaption: 'Describe the main features of your app.', inputExample: 'Our app tracks your daily water intake and suggests hydration tips, all while promoting the use of sustainable products.', type: 'textarea' },
            { inputTitle: 'Target User Demographics', inputCaption: 'Who is your app designed for?', inputExample: 'Health-conscious users of all ages interested in sustainable living', required: 'false' }
        ]
    },
    adHookCreator: {
        prompt: "103",
        icon: "🪝",
        title: "Ad Hook Creator",
        category: "advertising",
        caption: "Craft irresistible hooks for Facebook ads, designed to make your ads truly memorable.",
        inputs: [
            { inputTitle: 'Describe your Product / Service', inputCaption: 'Detail the features and benefits of your product/service.', inputExample: 'Our eco-friendly water bottles are not just a drinkware option; they’re a lifestyle choice for those who care about their health and the planet.', type: 'textarea' }
        ]
    },    
    adTargetingHelper: {
        prompt: "105",
        icon: "🎯",
        title: "Ad Targeting Helper",
        category: "advertising",
        caption: "This AI agent aids in audience targeting, budget setting, ad optimization, and tracking engagement.",
        inputs: [
            { inputTitle: 'Describe your Product / Service', inputCaption: 'Explain what you are promoting and your target audience.', inputExample: 'Our innovative eco-friendly water bottles designed for individuals committed to a sustainable lifestyle.', type: 'textarea' },
            { inputTitle: 'Your Target Location', inputCaption: 'Specify the location you want to target, e.g., United States.', inputExample: 'United States', required: 'false' }
        ]
    },
    findUserPainPoint: {
        prompt: "106",
        icon: "🤕",
        title: "Find User Pain Point",
        category: "advertising",
        caption: "Identify key customer pain points for your product in specific markets with this insightful marketing tool.",
        inputs: [
            { inputTitle: `What's Your Business About?`, inputCaption: 'Describe your business briefly.', inputExample: 'We specialize in eco-friendly water bottles designed to reduce plastic use and promote sustainability.', type: 'textarea' },
            { inputTitle: 'Your Target Location', inputCaption: 'Specify the target location for your business.', inputExample: 'Global market', required: 'false' },
            { inputTitle: 'What are you selling or promoting?', inputCaption: 'Detail the key features of your product or service.', inputExample: 'Our bottles are made from recycled materials, are BPA-free, and come in a range of stylish designs.', type: 'textarea' }
        ]
    }, 
    
    digitalMarketingStrategy: {
        prompt: "110",
        icon: "📈",
        title: "Digital Marketing Strategy",
        category: "advertising",
        caption: "Develop a robust digital marketing strategy to enhance your online presence and reach your target audience effectively.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: 'Eco-Friendly Water Bottles' },
            { inputTitle: `Target Market`, inputCaption: 'Describe your target market and audience.', inputExample: 'Eco-conscious consumers looking for sustainable hydration solutions.', type: 'textarea' }
        ]
    },
}

export default AdvertisingToolsData;
