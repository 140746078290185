// ImageGenerationModals.js
import React from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { Download } from 'lucide-react';
import { FORMAT_OPTIONS, ASPECT_RATIOS, STYLE_PRESETS, calculateDimensions } from './ImageGenerationUtils';

export const SettingsModal = ({ 
  show, 
  onHide, 
  settings, 
  onSettingsChange, 
  selectedProvider,
  dimensionMode,
  onDimensionModeChange 
}) => {
  const handleSettingsChange = (key, value) => {
    if (key === 'aspect_ratio') {
      const dimensions = calculateDimensions(value);
      onSettingsChange({
        aspect_ratio: value,
        width: dimensions.width,
        height: dimensions.height
      });
    } else {
      onSettingsChange({ [key]: value });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-capitalize">{selectedProvider} Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Image Output Format</Form.Label>
            <Form.Select
              value={settings.output_format}
              onChange={e => handleSettingsChange('output_format', e.target.value)}
            >
              {FORMAT_OPTIONS[selectedProvider].map(format => (
                <option key={format} value={format}>
                  {format.toUpperCase()}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Dimension Mode</Form.Label>
            <div className="d-flex gap-4">
              <Form.Check
                type="radio"
                name="dimensionMode"
                id="aspectMode"
                label="Aspect Ratio"
                checked={dimensionMode === 'aspect'}
                onChange={() => onDimensionModeChange('aspect')}
              />
              <Form.Check
                type="radio"
                name="dimensionMode"
                id="customMode"
                label="Custom Dimensions"
                checked={dimensionMode === 'custom'}
                onChange={() => onDimensionModeChange('custom')}
              />
            </div>
          </Form.Group>

          {dimensionMode === 'aspect' ? (
            <Form.Group className="mb-4">
            <Form.Label>Aspect Ratio</Form.Label>
            <Form.Select
              value={settings.aspect_ratio}
              onChange={e => handleSettingsChange('aspect_ratio', e.target.value)}
            >
              {ASPECT_RATIOS.map(ratio => (
                <option key={ratio.value} value={ratio.value}>
                  {ratio.label}
                </option>
              ))}
            </Form.Select>
            <Form.Text className="text-muted">
              Output dimensions: {settings.width}px × {settings.height}px
            </Form.Text>
          </Form.Group>
        ) : (
          <Row className="mb-4">
            <Col>
              <Form.Group>
                <Form.Label>Width (px)</Form.Label>
                <Form.Control
                  type="number"
                  min="64"
                  max="1024"
                  step="64"
                  value={settings.width}
                  onChange={e => {
                    const value = Math.min(1024, Math.max(64, Math.round(parseInt(e.target.value) / 64) * 64));
                    handleSettingsChange('width', value);
                  }}
                />
                <Form.Text className="text-muted">
                  Must be between 64 and 1024, in steps of 64
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Height (px)</Form.Label>
                <Form.Control
                  type="number"
                  min="64"
                  max="1024"
                  step="64"
                  value={settings.height}
                  onChange={e => {
                    const value = Math.min(1024, Math.max(64, Math.round(parseInt(e.target.value) / 64) * 64));
                    handleSettingsChange('height', value);
                  }}
                />
                <Form.Text className="text-muted">
                  Must be between 64 and 1024, in steps of 64
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        )}

        {selectedProvider === 'stability' && (
          <Form.Group className="mb-4">
            <Form.Label>Style Preset</Form.Label>
            <Form.Select
              value={settings.style_preset}
              onChange={e => handleSettingsChange('style_preset', e.target.value)}
            >
              {STYLE_PRESETS.map(style => (
                <option key={style.value} value={style.value}>
                  {style.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" onClick={onHide}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
);
};

export const ImageViewerModal = ({ show, onHide, image, onDownload }) => {
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    // Handle Firebase Timestamp
    if (timestamp?.toDate) {
      return timestamp.toDate().toLocaleString();
    }
    
    // Handle regular Date object
    if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    }
    
    // Handle timestamp as number (milliseconds)
    if (typeof timestamp === 'number') {
      return new Date(timestamp).toLocaleString();
    }

    // Handle Firebase server timestamp
    if (timestamp?._methodName === "serverTimestamp") {
      return new Date().toLocaleString();
    }

    // Try to parse the timestamp if it's something else
    try {
      return new Date(timestamp).toLocaleString();
    } catch (e) {
      console.error('Error formatting timestamp:', e);
      return 'N/A';
    }
  };

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size="lg" 
      centered
      className="image-viewer-modal"
    >
      <Modal.Header closeButton className='py-3'>
        {/* <Modal.Title className="h6">{image?.prompt}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="p-0">
        <img 
          src={image?.downloadURL || `data:image/${image?.format};base64,${image?.data}`}
          alt={image?.prompt}
          className="w-100 h-auto"
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <span className="text-white small">
          Generated on: {formatTimestamp(image?.timestamp || image?.createdAt)}
        </span>
        <Button 
          variant="primary"
          onClick={() => onDownload(image)}
        >
          <Download size={16} className="me-2" />
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};