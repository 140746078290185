import { useState, useEffect } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import commonDataServices from '../services/common.services';
import { getFunctions, httpsCallable } from 'firebase/functions';
import SubscriptionService from '../context/SubscriptionService';

async function handleSubscriptionUpdate(userData, currentDate) {
  if (userData.planType === "Life Time Plan") {
    try {
      // First attempt to migrate Life Time Plan user
      const migrationResult = await SubscriptionService.migrateLifeTimePlan(userData.uid);
      
      // Check if credits need to be refreshed
      await SubscriptionService.refreshLifeTimePlanCredits(userData.uid);
      
      if (migrationResult) {
        return null; // Return null since we've handled it in the new subscription system
      }
      
      // Fall back to old system if migration didn't happen
      const planTier = userData.redeemedCodes?.length || 0;
      let tierWords = planTier === 1 ? 20000 : planTier === 2 ? 50000 : 100000;
      
      const endDate = Math.floor(userData.planEndDate.toDate() / 1000);
      if (currentDate > endDate) {
        let expDate = new Date();
        expDate.setMonth(expDate.getMonth() + 1);
        
        return {
          planEndDate: expDate,
          totalWords: 0,
          wordsLimit: tierWords
        };
      }
    } catch (error) {
      console.error('Error handling Life Time Plan user:', error);
    }
  }

  if (userData.planType === "free") {
    if (currentDate > userData.planEndDate) {
      let oneMonth = new Date();
      let expDate = Math.round(oneMonth.setMonth(oneMonth.getMonth() + 1) / 1000);
      
      return {
        planStartDate: currentDate,
        planEndDate: expDate,
        totalWords: 0,
        wordsLimit: 1500
      };
    }
  }

  // if (userData.planType !== "free") {
  //   if (currentDate > userData.planEndDate) {
  //     const subId = userData.current;
  //     if (subId) {
  //       const functions = getFunctions();
  //       const getSub = httpsCallable(functions, 'getStripeSubscription');
  //       const response = await getSub({ subId });
  //       const subRes = response.data;

  //       if (subRes.current_period_end > currentDate && subRes.status === "active") {
  //         return {
  //           planStartDate: subRes.current_period_start,
  //           planEndDate: subRes.current_period_end,
  //           renewalDate: subRes.current_period_end,
  //           totalWords: 0
  //         };
  //       }
  //     }
  //   }
  // }

  if (userData.planType !== "free") {
    if (currentDate > userData.planEndDate) {
      const subId = userData.current;
      if (subId) {
        const functions = getFunctions();
        const getSub = httpsCallable(functions, 'getStripeSubscription');
        const response = await getSub({ subId });
        const subRes = response.data;

        if (subRes.current_period_end > currentDate && subRes.status === "active") {
          // Base subscription update
          const subscriptionUpdate = {
            planStartDate: subRes.current_period_start,
            planEndDate: subRes.current_period_end,
            renewalDate: subRes.current_period_end,
            totalWords: 0
          };

          // Update the new subscription collection for old subscribers
          try {
            // Check if user has old subscription plan
            if (userData.planType === "Professional Plan" || userData.planType === "Unlimited Plan") {
              const newSubscriptionData = {
                planId: userData.planType === "Professional Plan" ? 'pro' : 'starter',
                status: 'active',
                credits: userData.planType === "Professional Plan" 
                  ? {
                      essential: 1500,
                      advanced: 300,
                      images: 100
                    }
                  : {
                      essential: -1,
                      advanced: 1500,
                      images: 300
                    },
                createdAt: new Date(subscriptionUpdate.planStartDate * 1000),
                lastUpdated: new Date(),
                lastCreditRefresh: new Date(),
                renewalDate: new Date(subscriptionUpdate.renewalDate * 1000)
              };

              // Update the new subscription collection
              await SubscriptionService.updateSubscriptionOnRenewal(userData.uid, newSubscriptionData);
              console.log('Updated subscription for old subscriber:', userData.uid);
            }
          } catch (error) {
            console.error('Error updating new subscription collection:', error);
          }

          return subscriptionUpdate;
        }
      }
    }
  }

  return null;
}

export default function useSubscription() {
  const { user } = useUserAuth();
  const [subscriber, setSubscriber] = useState(false);

  useEffect(() => {
    async function fetchSubscription() {
      if (!user) return;
  
      try {
        // First try to migrate Life Time Plan
        await SubscriptionService.migrateLifeTimePlan(user.uid);
        
        // Then check if credits need to be refreshed
        await SubscriptionService.refreshLifeTimePlanCredits(user.uid);
        
        // Then try to migrate any other old subscription
        await SubscriptionService.migrateOldSubscription(user.uid);
      } catch (error) {
        console.error('Migration error:', error);
      }
      
      const subData = await commonDataServices.getUsageLimit(user.uid);
      
      if (!subData.data()) {
        await commonDataServices.setUserSubscription(user.uid);
        const newSubData = await commonDataServices.getUsageLimit(user.uid);
        setSubscriber(newSubData.data());
        return;
      }

      const userData = subData.data();
      const currentDate = Math.floor(Date.now() / 1000);
      
      const update = await handleSubscriptionUpdate(userData, currentDate);
      
      if (update) {
        await commonDataServices.updateUserSubscription(user.uid, update);
        const updatedData = await commonDataServices.getUsageLimit(user.uid);
        setSubscriber(updatedData.data());
      } else {
        setSubscriber(userData);
      }
    }

    fetchSubscription();
  }, [user]);

  return { user, subscriber, setSubscriber };
}