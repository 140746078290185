import React from 'react';

const TableParser = ({ markdown }) => {
  // Parse markdown table into rows and cells
  const parseTable = (markdown) => {
    const lines = markdown.trim().split('\n');
    
    // Return empty if not enough lines for a table
    if (lines.length < 2) return null;
    
    // Remove any leading/trailing pipe and split cells
    const parseRow = (row) => {
      return row.trim()
        .replace(/^\||\|$/g, '')
        .split('|')
        .map(cell => cell.trim());
    };
    
    // Parse header, separator, and body rows
    const headerRow = parseRow(lines[0]);
    const separator = parseRow(lines[1]);
    const bodyRows = lines.slice(2).map(parseRow);
    
    // Validate separator row (should contain only -, :, or spaces)
    const isValidSeparator = separator.every(cell => /^[-:\s]+$/.test(cell));
    if (!isValidSeparator) return null;
    
    // Get column alignments from separator row
    const alignments = separator.map(cell => {
      if (cell.startsWith(':') && cell.endsWith(':')) return 'center';
      if (cell.endsWith(':')) return 'right';
      return 'left';
    });
    
    return {
      headers: headerRow,
      alignments,
      rows: bodyRows
    };
  };

  // Check if the content is a markdown table
  const isMarkdownTable = (text) => {
    const lines = text.trim().split('\n');
    if (lines.length < 2) return false;
    
    // Check if first two lines match table pattern
    const hasHeaderRow = /^\|.+\|$/.test(lines[0].trim());
    const hasSeparator = /^\|[-:\s|]+\|$/.test(lines[1].trim());
    
    return hasHeaderRow && hasSeparator;
  };

  if (!markdown || !isMarkdownTable(markdown)) {
    return null;
  }

  const tableData = parseTable(markdown);
  if (!tableData) return null;

  return (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {tableData.headers.map((header, index) => (
              <th
                key={index}
                className="border border-gray-300 px-4 py-2 text-sm font-semibold text-gray-900"
                style={{ textAlign: tableData.alignments[index] }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row, rowIndex) => (
            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className="border border-gray-300 px-4 py-2 text-sm text-gray-900"
                  style={{ textAlign: tableData.alignments[cellIndex] }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableParser;