// Complete list of language codes and their names
export const LANGUAGE_CODES = {
    'ar': 'Arabic',
    'bn': 'Bengali',
    'bg': 'Bulgarian',
    'ca': 'Catalan',
    'zh': 'Chinese',
    'hr': 'Croatian',
    'cs': 'Czech',
    'da': 'Danish',
    'nl': 'Dutch',
    'en': 'English',
    'et': 'Estonian',
    'tl': 'Filipino',
    'fi': 'Finnish',
    'fr': 'French',
    'de': 'German',
    'el': 'Greek',
    'gu': 'Gujarati',
    'iw': 'Hebrew',
    'hi': 'Hindi',
    'hu': 'Hungarian',
    'is': 'Icelandic',
    'id': 'Indonesian',
    'it': 'Italian',
    'ja': 'Japanese',
    'kn': 'Kannada',
    'ko': 'Korean',
    'lv': 'Latvian',
    'lt': 'Lithuanian',
    'ms': 'Malay',
    'ml': 'Malayalam',
    'mr': 'Marathi',
    'no': 'Norwegian',
    'fa': 'Persian',
    'pl': 'Polish',
    'pt': 'Portuguese',
    'pa': 'Punjabi',
    'ro': 'Romanian',
    'ru': 'Russian',
    'sr': 'Serbian',
    'sk': 'Slovak',
    'sl': 'Slovenian',
    'es': 'Spanish',
    'sv': 'Swedish',
    'ta': 'Tamil',
    'te': 'Telugu',
    'th': 'Thai',
    'tr': 'Turkish',
    'uk': 'Ukrainian',
    'ur': 'Urdu',
    'vi': 'Vietnamese'
  };
  
  // Helper function to get language name from code
  export const getLanguageName = (code) => LANGUAGE_CODES[code] || code;
  
  // Helper function to get language code from name
  export const getLanguageCode = (name) => {
    const entry = Object.entries(LANGUAGE_CODES).find(([_, langName]) => 
      langName.toLowerCase() === name.toLowerCase()
    );
    return entry ? entry[0] : null;
  };
  
  // Helper function to search languages by name or code
  export const searchLanguages = (query) => {
    if (!query) return Object.entries(LANGUAGE_CODES);
    
    const searchTerm = query.toLowerCase();
    return Object.entries(LANGUAGE_CODES).filter(([code, name]) => 
      name.toLowerCase().includes(searchTerm) || 
      code.toLowerCase().includes(searchTerm)
    );
  };
  
  // Most commonly used languages for quick access
  export const POPULAR_LANGUAGES = {
    'en': 'English',
    'es': 'Spanish',
    'fr': 'French',
    'de': 'German',
    'zh': 'Chinese',
    'ja': 'Japanese',
    'ar': 'Arabic',
    'hi': 'Hindi',
    'pt': 'Portuguese',
    'ru': 'Russian'
  };
  
  // Language families/groups (useful for regional grouping)
  export const LANGUAGE_GROUPS = {
    'indo-european': ['en', 'fr', 'de', 'es', 'pt', 'hi', 'ru', 'bn', 'pa'],
    'sino-tibetan': ['zh'],
    'japonic': ['ja'],
    'turkic': ['tr'],
    'afroasiatic': ['ar', 'iw'],
    'dravidian': ['ta', 'te', 'kn', 'ml'],
    'austronesian': ['id', 'ms', 'tl'],
    'korean': ['ko'],
    'thai': ['th'],
    'vietnamese': ['vi']
  };