const MusicToolsData = {
    songLyricsDesigner: {
        prompt: "1101",
        icon: "🎶",
        title: "Song Lyrics Designer",
        category: "music",
        caption: `Unlock your songwriting potential with this AI agent, crafting lyrics with a compelling narrative and emotional depth to connect with listeners.`,
        inputs: [
            { inputTitle: `What is the concept, theme or idea for the song lyrics?`, inputCaption: `Describe the main concept, theme, or story idea for your song`, type: 'textarea', inputExample: "A song about overcoming obstacles and achieving dreams" },
            { inputTitle: `What genre do you want for your song?`, inputCaption: `Specify the genre of your song (e.g., Pop, Rock, Hip-Hop)`, type: 'text', inputExample: "Pop" },
            { inputTitle: `How many verses would you like in the song?`, inputCaption: `Indicate the desired number of verses for the song`, type: 'text', inputExample: "3" },
            { inputTitle: `How would you like the overall mood or emotion of the song to feel?`, inputCaption: `Describe the mood or emotion you want to convey (e.g., joyful, melancholic, energetic)`, type: 'text', inputExample: "Hopeful and uplifting" }
        ]
    }, 
    songLyricsGenerator: {
        prompt: "1102",
        icon: "🎶",
        title: "Song Lyrics Generator",
        category: "music",
        caption: "Create unique and tailored song lyrics across a variety of genres, inspired by famous artists.",
        inputs: [
            { inputTitle: `Select Genre`, inputCaption: `Choose a genre for your song (e.g., Rap, Metal, Love Song, etc.)`, type: 'select', options: ['Freestyle Song', 'Line By Line', 'Rap', 'Metal', 'Love Song', 'Rhyming Song', 'Summer Song', 'Country', 'X Factor Song', 'Ballad', 'Christmas Song'], inputExample: "Love Song" },
            { inputTitle: `Song Name`, inputCaption: `Enter a name for your song.`, inputExample: "Eternal Love" },
            { inputTitle: `Artist Style `, inputCaption: `Select an artist to influence the style of your song .`, type: 'select', options: ['None','Ariana Grande', 'Drake', 'Adele', 'Eminem', 'Taylor Swift', 'Katy Perry', 'One Direction', 'Justin Bieber', 'Beyonce Knowles', 'The Beatles', 'Britney Spears', 'REM', 'Grease the Musical', 'Abba', 'The Village People', 'Frank Sinatra', 'The Cranberries', 'Simon and Garfunkel', 'The Beautiful South'], required: 'false', inputExample: "Taylor Swift" },
            { inputTitle: `Mood or Theme `, inputCaption: `Describe the mood or theme of the song (e.g., joyful, melancholic, romantic).`, type: 'text', required: 'false', inputExample: "Nostalgic" },
            { inputTitle: `Key Lyrics or Phrases `, inputCaption: `Include any key lyrics, phrases, or words you want in the song.`, type: 'textarea', required: 'false', inputExample: "Forever and always" }
        ]
    }, 
    songBreakdown: {
        prompt: "31001",
        icon: "🎵",
        title: "Song Breakdown by Bars",
        category: "music",
        caption: "Analyze and break down song lyrics into bars with detailed structural analysis.",
        inputs: [
            { inputTitle: "Song Lyrics", inputCaption: "Enter the song lyrics to analyze", type: "textarea", inputExample: "Verse 1:\nIn the heat of the night\nUnder stars burning bright\nI can feel the rhythm flow\nAs the beat drops down low" },
            { inputTitle: "Bar Count", inputCaption: "Number of bars to analyze", type: "select", options: ['4', '8', '16', '32'], inputExample: "16" },
            { inputTitle: "Structure Type", inputCaption: "Choose structure analysis type", type: "select", options: ['Basic', 'Advanced', 'Technical'], inputExample: "Advanced" },
            { inputTitle: "Additional Notes", inputCaption: "Any specific elements to focus on", type: "textarea", required: false, inputExample: "Focus on internal rhyme schemes and flow patterns" }
        ]
    },

    dissTrackGenerator: {
        prompt: "31002",
        icon: "🎤",
        title: "Diss Track Generator",
        category: "music",
        caption: "Create hard-hitting diss tracks with clever wordplay and sharp lyrics.",
        inputs: [
            { inputTitle: "Target/Theme", inputCaption: "What/who is the diss track about?", type: "text", inputExample: "Rival Rapper/Fake Friends" },
            { inputTitle: "Style/Flow", inputCaption: "Choose the style and flow", type: "select", options: ['Aggressive', 'Laid Back', 'Technical', 'Old School'], inputExample: "Aggressive" },
            { inputTitle: "Intensity Level", inputCaption: "Select intensity level", type: "select", options: ['Mild', 'Medium', 'Heavy', 'Nuclear'], inputExample: "Medium" },
            { inputTitle: "Length", inputCaption: "Choose track length", type: "select", options: ['16 Bars', '32 Bars', 'Full Song'], inputExample: "32 Bars" }
        ]
    },

    metalLyricsGenerator: {
        prompt: "31003",
        icon: "🤘",
        title: "Metal Lyrics Generator",
        category: "music",
        caption: "Generate powerful metal lyrics with intense themes and dramatic elements.",
        inputs: [
            { inputTitle: "Theme", inputCaption: "Choose main theme", type: "select", options: ['Dark Fantasy', 'Apocalyptic', 'Personal Struggle', 'Mythological', 'Social Commentary'], inputExample: "Dark Fantasy" },
            { inputTitle: "Style", inputCaption: "Select metal style", type: "select", options: ['Heavy Metal', 'Death Metal', 'Black Metal', 'Power Metal', 'Symphonic Metal'], inputExample: "Heavy Metal" },
            { inputTitle: "Intensity", inputCaption: "Choose intensity level", type: "select", options: ['Moderate', 'Intense', 'Extreme'], inputExample: "Intense" },
            { inputTitle: "Structure", inputCaption: "Select song structure", type: "select", options: ['Verse-Chorus', 'Progressive', 'Through-Composed'], inputExample: "Verse-Chorus" }
        ]
    },

    countrySongGenerator: {
        prompt: "31004",
        icon: "🤠",
        title: "Country Song Generator",
        category: "music",
        caption: "Create authentic country songs with heart and storytelling.",
        inputs: [
            { inputTitle: "Theme", inputCaption: "Choose main theme", type: "select", options: ['Love', 'Heartbreak', 'Small Town Life', 'Family', 'Patriotic', 'Nature'], inputExample: "Small Town Life" },
            { inputTitle: "Style", inputCaption: "Select country style", type: "select", options: ['Traditional', 'Modern', 'Country Pop', 'Bluegrass Influenced'], inputExample: "Traditional" },
            { inputTitle: "Story Elements", inputCaption: "Key story elements to include", type: "textarea", inputExample: "Small town, pickup truck, summer nights" },
            { inputTitle: "Song Structure", inputCaption: "Choose structure", type: "select", options: ['Verse-Chorus', 'Story Ballad', 'Modern Verse-Chorus-Bridge'], inputExample: "Verse-Chorus" }
        ]
    },

    melodyGenerator: {
        prompt: "31005",
        icon: "🎼",
        title: "Melody Generator",
        category: "music",
        caption: "Create memorable melodies with specific musical characteristics.",
        inputs: [
            { inputTitle: "Style", inputCaption: "Choose melodic style", type: "select", options: ['Pop', 'Rock', 'R&B', 'Folk', 'Classical'], inputExample: "Pop" },
            { inputTitle: "Key", inputCaption: "Select musical key", type: "select", options: ['C', 'G', 'D', 'A', 'F', 'Bb', 'Em', 'Am'], inputExample: "C" },
            { inputTitle: "Tempo", inputCaption: "Choose tempo range", type: "select", options: ['Slow', 'Medium', 'Fast', 'Very Fast'], inputExample: "Medium" },
            { inputTitle: "Structure", inputCaption: "Select melodic structure", type: "select", options: ['Simple', 'Complex', 'Repetitive', 'Progressive'], inputExample: "Simple" }
        ]
    },

    musicParodyCreator: {
        prompt: "31006",
        icon: "😄",
        title: "Music Parody Creator",
        category: "music",
        caption: "Create clever musical parodies while maintaining original song structures.",
        inputs: [
            { inputTitle: "Original Song", inputCaption: "Enter original song title and artist", type: "text", inputExample: "Sweet Home Alabama - Lynyrd Skynyrd" },
            { inputTitle: "New Theme", inputCaption: "What's your parody about?", type: "text", inputExample: "Working From Home" },
            { inputTitle: "Style", inputCaption: "Choose parody style", type: "select", options: ['Funny', 'Satirical', 'Educational', 'Political'], inputExample: "Funny" },
            { inputTitle: "Humor Level", inputCaption: "Select humor intensity", type: "select", options: ['Subtle', 'Moderate', 'Over-the-top'], inputExample: "Moderate" }
        ]
    },

    elementarySchoolSongGenerator: {
        prompt: "31007",
        icon: "🏫",
        title: "Elementary School Song Generator",
        category: "music",
        caption: "Create educational and engaging songs for young learners.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "What are you teaching?", type: "text", inputExample: "The Solar System" },
            { inputTitle: "Educational Goal", inputCaption: "What should students learn?", type: "textarea", inputExample: "Names and order of planets" },
            { inputTitle: "Age Group", inputCaption: "Select target age group", type: "select", options: ['K-1st', '2nd-3rd', '4th-5th'], inputExample: "2nd-3rd" },
            { inputTitle: "Style", inputCaption: "Choose song style", type: "select", options: ['Fun & Upbeat', 'Call & Response', 'Story-Based', 'Movement-Based'], inputExample: "Fun & Upbeat" }
        ]
    },

    songTopicGenerator: {
        prompt: "31008",
        icon: "💭",
        title: "Song Topic Generator",
        category: "music",
        caption: "Generate unique and engaging song topic ideas.",
        inputs: [
            { inputTitle: "Genre", inputCaption: "Select music genre", type: "select", options: ['Pop', 'Rock', 'Hip Hop', 'Country', 'R&B', 'Folk'], inputExample: "Pop" },
            { inputTitle: "Mood", inputCaption: "Choose desired mood", type: "select", options: ['Happy', 'Sad', 'Angry', 'Reflective', 'Romantic'], inputExample: "Reflective" },
            { inputTitle: "Theme Category", inputCaption: "Select theme category", type: "select", options: ['Love', 'Life', 'Social Issues', 'Personal Growth', 'Fantasy'], inputExample: "Personal Growth" },
            { inputTitle: "Target Audience", inputCaption: "Choose target audience", type: "select", options: ['Teens', 'Young Adults', 'Adults', 'Universal'], inputExample: "Young Adults" }
        ]
    },

    rapLyricsWriter: {
        prompt: "31009",
        icon: "🎤",
        title: "Rap Lyrics Writer",
        category: "music",
        caption: "Generate complex rap lyrics with advanced rhyme schemes and wordplay.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "What's your rap about?", type: "text", inputExample: "Rising Above Challenges" },
            { inputTitle: "Style", inputCaption: "Choose rap style", type: "select", options: ['Boom Bap', 'Trap', 'Conscious', 'Melodic', 'Old School'], inputExample: "Conscious" },
            { inputTitle: "Flow Type", inputCaption: "Select flow pattern", type: "select", options: ['Simple', 'Complex', 'Double-time', 'Syncopated'], inputExample: "Complex" },
            { inputTitle: "Complexity Level", inputCaption: "Choose lyrical complexity", type: "select", options: ['Basic', 'Advanced', 'Expert'], inputExample: "Advanced" }
        ]
    },

    songIdeaGenerator: {
        prompt: "31010",
        icon: "💡",
        title: "Song Idea Generator",
        category: "music",
        caption: "Generate complete song concepts including hooks and verse themes.",
        inputs: [
            { inputTitle: "Genre", inputCaption: "Select music genre", type: "select", options: ['Pop', 'Rock', 'Hip Hop', 'R&B', 'Country', 'Folk', 'Electronic'], inputExample: "Pop" },
            { inputTitle: "Theme", inputCaption: "Choose main theme", type: "select", options: ['Love', 'Life Journey', 'Social Commentary', 'Party', 'Relationship', 'Self-Discovery'], inputExample: "Life Journey" },
            { inputTitle: "Mood", inputCaption: "Select overall mood", type: "select", options: ['Upbeat', 'Melancholic', 'Energetic', 'Chill', 'Dramatic'], inputExample: "Upbeat" },
            { inputTitle: "Structure", inputCaption: "Choose song structure", type: "select", options: ['Verse-Chorus', 'AABA', 'Verse-Chorus-Bridge', 'Progressive'], inputExample: "Verse-Chorus-Bridge" }
        ]
    }
};

export default MusicToolsData;
