import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col,
  Badge
} from 'react-bootstrap';
import { Youtube, Download, Music, Clock } from 'lucide-react';
import axios from 'axios';

const YouTubeToMP3 = () => {
  const [url, setUrl] = useState('');
  const [audioInfo, setAudioInfo] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState('');
  const [selectedQuality, setSelectedQuality] = useState('best');
  const [qualityPresets, setQualityPresets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch quality presets on component mount
    const fetchQualityPresets = async () => {
      try {
        const response = await axios.get('http://localhost:3001/api/youtube/mp3/quality-presets');
        setQualityPresets(response.data);
      } catch (err) {
        console.error('Failed to fetch quality presets:', err);
      }
    };

    fetchQualityPresets();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setAudioInfo(null);

    try {
      const dev = 'https://yt-api-eight.vercel.app/api/youtube/mp3-info';
      const local = 'http://localhost:3001/api/youtube/mp3-info';  
      const response = await axios.get(`${dev}?url=${encodeURIComponent(url)}`);
      setAudioInfo(response.data);
      if (response.data.formats.length > 0) {
        setSelectedFormat(response.data.formats[0].itag);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to get audio information');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      const params = selectedFormat 
        ? `url=${encodeURIComponent(url)}&itag=${selectedFormat}`
        : `url=${encodeURIComponent(url)}&quality=${selectedQuality}`;
      
      window.location.href = `http://localhost:3001/api/youtube/mp3/download?${params}`;
    } catch (err) {
      setError('Failed to download MP3');
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <Container className="py-5">
      <Card className="shadow">
        <Card.Header className="bg-primary text-white text-center py-3">
          <h2 className="mb-0 d-flex align-items-center justify-content-center">
            <Youtube size={32} className="me-2" />
            YouTube to MP3 Converter
          </h2>
        </Card.Header>

        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter YouTube URL..."
                  disabled={loading}
                />
                <Button 
                  type="submit" 
                  variant="primary" 
                  disabled={loading}
                  style={{ minWidth: '120px' }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Get Info'
                  )}
                </Button>
              </div>
            </Form.Group>

            {error && (
              <Alert variant="danger">
                {error}
              </Alert>
            )}

            {audioInfo && (
              <Row className="mt-4">
                <Col md={4}>
                  <img
                    src={audioInfo.thumbnail}
                    alt={audioInfo.title}
                    className="img-fluid rounded"
                  />
                </Col>
                
                <Col md={8}>
                  <h4 className="mb-3">{audioInfo.title}</h4>
                  
                  <div className="d-flex gap-4 mb-3">
                    <div className="d-flex align-items-center text-muted">
                      <Clock size={18} className="me-1" />
                      <span>{formatDuration(audioInfo.duration)}</span>
                    </div>
                    <div className="d-flex align-items-center text-muted">
                      <Music size={18} className="me-1" />
                      <span>Audio</span>
                    </div>
                  </div>

                  <Card className="mb-3">
                    <Card.Body>
                      <h5 className="mb-3">Quality Options</h5>
                      
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Quick Quality Preset
                          <Badge bg="info" className="ms-2">Recommended</Badge>
                        </Form.Label>
                        <Form.Select
                          value={selectedQuality}
                          onChange={(e) => setSelectedQuality(e.target.value)}
                        >
                          {qualityPresets && Object.entries(qualityPresets).map(([key, preset]) => (
                            <option key={key} value={key}>
                              {preset.description} ({preset.bitrate})
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <hr />

                      <Form.Group>
                        <Form.Label>Advanced Format Selection</Form.Label>
                        <Form.Select
                          value={selectedFormat}
                          onChange={(e) => setSelectedFormat(e.target.value)}
                        >
                          <option value="">Use quality preset instead</option>
                          {audioInfo.formats.map((format) => (
                            <option key={format.itag} value={format.itag}>
                              {format.quality} - {format.codec} ({format.size})
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Button
                    variant="primary"
                    onClick={handleDownload}
                    className="w-100 d-flex align-items-center justify-content-center gap-2"
                  >
                    <Download size={18} />
                    Download MP3
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default YouTubeToMP3;