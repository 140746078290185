// ImageGeneration.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth'; 
import ImageGenerationService from './ImageGenerationService';
import PricingModal from '../pricing/PricingModal';
import { Container, Form, Button, Spinner, Alert, Dropdown, Row, Col } from 'react-bootstrap';
import { useUserAuth } from "../../context/UserAuthContext";
import { Settings, Image as ImageIcon, Download, Maximize2, Trash2, RefreshCcw, ChevronLeft, ChevronRight } from 'lucide-react';
import { SettingsModal, ImageViewerModal } from './ImageGenerationModals';
import { IMAGE_MODELS, DEFAULT_SETTINGS, calculateDimensions } from './ImageGenerationUtils';
import ConfirmationModal from '../shared/ConfirmationModal';  
import './ImageGeneration.css';

import fluxDev from './images/flux-dev.jpeg';
import stableDiffusion from './images/stable-diffusion-3.jpg';

const ImageGeneration = () => { 
  const { user, subscriptionData, refreshSubscription } = useUserAuth(); 
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('flux');
  const [selectedModel, setSelectedModel] = useState(IMAGE_MODELS.stability[0]);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS.stability);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [dimensionMode, setDimensionMode] = useState('aspect');
  const [imagesLoading, setImagesLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [showPricingModal, setShowPricingModal] = useState(false);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 9;
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = generatedImages.slice(indexOfFirstImage, indexOfLastImage);
  const totalPages = Math.ceil(generatedImages.length / imagesPerPage);
 
  const logos = {
    'flux-dev': fluxDev,
    'stable-diffusion-3': stableDiffusion
  };
 
  useEffect(() => {
    setSettings(DEFAULT_SETTINGS[selectedProvider]);
    setSelectedModel(IMAGE_MODELS[selectedProvider][0]);
  }, [selectedProvider]);

  useEffect(() => {
    setCurrentPage(1);
  }, [generatedImages.length]);

  const loadImages = async () => {
    if (!user?.uid) {
      setGeneratedImages([]);
      setImagesLoading(false);
      return;
    }
  
    setImagesLoading(true);
    setLoadError(null);
  
    try {
      const images = await ImageGenerationService.getUserImages(user.uid);
      
      const processedImages = images.map(image => ({
        ...image,
        timestamp: image.createdAt?.toDate?.() || image.createdAt || image.timestamp || new Date()
      }));
      
      const sortedImages = [...processedImages].sort((a, b) => {
        const timeA = new Date(a.timestamp).getTime();
        const timeB = new Date(b.timestamp).getTime();
        return timeB - timeA;
      });
      
      setGeneratedImages(sortedImages);
    } catch (error) {
      console.error('Error loading images:', error);
      setLoadError(error.message || 'Failed to load saved images. Please try again.');
    } finally {
      setImagesLoading(false);
    }
  };

  useEffect(() => {
    loadImages();
  }, [user]);

  const handleDimensionModeChange = (mode) => {
    setDimensionMode(mode);
    if (mode === 'aspect' && settings.aspect_ratio) {
      const dimensions = calculateDimensions(settings.aspect_ratio);
      handleSettingsChange({
        width: dimensions.width,
        height: dimensions.height
      });
    }
  };

  const handleSettingsChange = (changes) => {
    setSettings(prev => ({
      ...prev,
      ...changes
    }));
  };

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }
  
    setLoading(true);
    setError('');
  
    try {
      const dev = 'https://wk-server.vercel.app/api/generate-image';
      const response = await fetch(dev, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          provider: selectedProvider,
          model: selectedModel.name,
          prompt,
          userId: user.uid,
          parameters: settings
        })
      });
  
      if (response.status === 402) {
        setShowPricingModal(true);
        throw new Error('Insufficient credits');
      }    

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Image generation failed');
      }
  
      const data = await response.json();
      if (!data.images || !data.images.length) {
        throw new Error('No images received');
      }
  
      const storedImages = await Promise.all(
        data.images.map(async (image) => {
          const metadata = {
            prompt,
            model: selectedModel.name,
            provider: selectedProvider,
            format: image.format,
            width: settings.width,
            height: settings.height,
            settings: settings
          };
  
          const stored = await ImageGenerationService.storeGeneratedImage(
            user.uid,
            image.data,
            metadata,
            refreshSubscription,
            () => setShowPricingModal(true)
          );
  
          if (refreshSubscription) {
            await refreshSubscription();
          }
  
          return stored;
        })
      );
  
      setGeneratedImages((prev) => [...storedImages, ...prev]);
  
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (image) => {
    try {
      const imageUrl = image.downloadURL || `data:image/${image.format};base64,${image.data}`;
      
      if (imageUrl.startsWith('data:')) {
        const [header, base64Data] = imageUrl.split(',');
        const format = header.match(/image\/([^;]+)/)?.[1] || 'png';
        
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        
        const blob = new Blob(byteArrays, { type: `image/${format}` });
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = `generated-image-${Date.now()}.${format}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
  
        const response = await fetch(imageUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const blob = await response.blob();
        const format = image.format || blob.type.split('/')[1] || 'png';
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = `generated-image-${Date.now()}.${format}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading image:', error);
      throw new Error(`Failed to download image: ${error.message}`);
    }
  };

  const handleDelete = async (imageId) => {
    try {
      await ImageGenerationService.deleteImage(imageId, user.uid);
      setGeneratedImages(prevImages => 
        prevImages.filter(image => image.id !== imageId)
      );
      setShowDeleteModal(false);
      setImageToDelete(null);
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image');
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    if (timestamp?.toDate) {
      return timestamp.toDate().toLocaleString();
    }
    
    if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    }
    
    if (typeof timestamp === 'number') {
      return new Date(timestamp).toLocaleString();
    }

    if (timestamp?._methodName === "serverTimestamp") {
      return new Date().toLocaleString();
    }

    try {
      return new Date(timestamp).toLocaleString();
    } catch (e) {
      console.error('Error formatting timestamp:', e);
      return 'N/A';
    }
  };

  return (
    <Container className="main-layout templates">
      <div className="image-gen main-content">
        <Container fluid className="p-4">
          <div className="py-5 text-center">
            <h1 className='text-xl text-center fw-bolder animated-gradient-text mb-2 landing-title' style={{ fontSize: "38px" }}>AI Image Generator</h1>
            <p className='landing-des lead text-muted'>
              Bringing Your Imagination to Life Through Top-Rated AI Models
            </p>
          </div>

          <div className="rounded p-4 mb-4 mx-auto" style={{ maxWidth: "760px" }}>
            <div className="d-flex align-items-center gap-2 mb-3">
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary" className="d-flex align-items-center gap-2 img-model-btn">
                  {selectedModel.name && (
                    <img 
                      src={logos[selectedModel.name]} 
                      alt={`${selectedModel.name} logo`}
                      className="rounded"
                      width="20" 
                      height="20"
                      style={{ objectFit: 'cover' }}
                    />
                  )}
                  <span>{selectedModel.name}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Object.entries(IMAGE_MODELS).map(([provider, models]) => (
                    <React.Fragment key={provider}>
                      {models.map(model => (
                        <Dropdown.Item 
                          key={model.name}
                          onClick={() => {
                            setSelectedProvider(provider);
                            setSelectedModel(model);
                          }}
                          className="d-flex align-items-center gap-2 py-2 px-3"
                          active={selectedProvider === provider && selectedModel.name === model.name}
                        >
                          <img 
                            src={logos[model.name]} 
                            alt={`${model.name} logo`}
                            className="rounded"
                            width="24" 
                            height="24"
                            style={{ objectFit: 'cover' }}
                          />
                          <div>
                            <div className="fw-medium">{model.name}</div>
                            <div className="text-muted small">{model.description}</div>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </React.Fragment>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              
              <a 
                onClick={() => setShowSettings(true)}
                className="d-flex align-items-center gap-2 image-settings"
              >
                <Settings size={16} />
              </a>
            </div>

            <Form onSubmit={e => { e.preventDefault(); handleGenerate(); }}>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  placeholder="Describe the image you want to generate..."
                  value={prompt}
                  onChange={e => setPrompt(e.target.value)}
                  rows={3}
                  className="resize-none"
                />
              </Form.Group>
              
              <Button 
                variant="primary" 
                onClick={handleGenerate}
                disabled={loading || !prompt.trim()}
                className="w-100 d-flex align-items-center justify-content-center gap-2"
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" />
                    <span>Generating...</span>
                  </>
                ) : (
                  'Generate'
                )}
              </Button>
            </Form>

            {error && (
              <Alert variant="danger" className="mt-3" onClose={() => setError('')} dismissible>
                {error}
              </Alert>
            )}
          </div>

          <div className="generated-images">
            <div className="d-flex justify-content-between align-items-center mb-3">
              {loadError && (
                <div className="error-alert">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{loadError}</span>
                    <Button 
                      variant="outline-danger" 
                      size="sm"
                      onClick={() => {
                        setImagesLoading(true);
                        setLoadError(null);
                        loadImages();
                      }}
                      className="d-flex align-items-center gap-2"
                    >
                      <RefreshCcw size={14} />
                      Retry
                    </Button>
                  </div>
                </div>
)}
            {imagesLoading ? (
              <div className="d-flex flex-column py-5 w-100 align-items-center justify-content-center">
                <Spinner animation="border" variant="primary" className="mb-3" />
                <p className="text-muted mb-0">Loading your images...</p>
              </div>
            ) : loadError ? (
              <Alert variant="danger" className="mb-4">
                {loadError}
              </Alert>
            ) : generatedImages.length === 0 ? (
              <div className='w-100'>
                     <div className="text-center rounded p-5">
                    <ImageIcon size={48} className="text-muted mb-3" />
                    <h3 className="h6 mb-2">No images yet</h3>
                    <p className="text-muted mb-0">
                      Generate your first image to see it appear here
                    </p>
                  </div>
                </div>
            ) : (
              <>
                <div className="image-grid">
                  {currentImages.map((image) => (
                    <div key={image.id} className="image-item">
                      <div 
                        className="image-card"
                        onClick={() => {
                          setViewImage(image);
                          setShowImageModal(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <img 
                          src={image.downloadURL || `data:image/${image.format};base64,${image.data}`}
                          alt={image.prompt}
                          className="gallery-image"
                          onError={(e) => {
                            console.error('Image load error:', image.id);
                            e.target.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>';
                            e.target.className = 'gallery-image error';
                          }}
                        />
                        <div className="image-overlay">
                          <div className="image-actions">
                            <Button 
                              variant="light"
                              size="sm"
                              className="action-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewImage(image);
                                setShowImageModal(true);
                              }}
                            >
                              <Maximize2 size={16} />
                            </Button>
                            <Button 
                              variant="light"
                              size="sm"
                              className="action-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownload(image);
                              }}
                            >
                              <Download size={16} />
                            </Button>
                            <Button 
                              variant="danger"
                              size="sm"
                              className="action-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setImageToDelete(image);
                                setShowDeleteModal(true);
                              }}
                            >
                              <Trash2 size={16} />
                            </Button>
                          </div>
                          <div className="image-info">
                            <p className="prompt">{image.prompt}</p>
                            <p className="timestamp">
                              {formatTimestamp(image.timestamp || image.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          </div>

<div className='pagination-wrap'>
{totalPages > 1 && (
                  <div className="d-flex justify-content-center align-items-center gap-2 mt-4">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                      disabled={currentPage === 1}
                    >
                      <ChevronLeft size={16} />
                    </Button>
                    
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                      <Button
                        key={pageNum}
                        variant={currentPage === pageNum ? "primary" : "outline-secondary"}
                        size="sm"
                        onClick={() => setCurrentPage(pageNum)}
                        className="min-w-[32px]"
                      >
                        {pageNum}
                      </Button>
                    ))}

                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                      disabled={currentPage === totalPages}
                    >
                      <ChevronRight size={16} />
                    </Button>
                  </div>
                )}
</div>

        </Container>

        <SettingsModal 
          show={showSettings}
          onHide={() => setShowSettings(false)}
          settings={settings}
          onSettingsChange={handleSettingsChange}
          selectedProvider={selectedProvider}
          dimensionMode={dimensionMode}
          onDimensionModeChange={handleDimensionModeChange}
        />
        
        <ImageViewerModal 
          show={showImageModal}
          onHide={() => {
            setShowImageModal(false);
            setViewImage(null);
          }}
          image={viewImage}
          onDownload={handleDownload}
        />

        <ConfirmationModal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
            setImageToDelete(null);
          }}
          onConfirm={() => handleDelete(imageToDelete?.id)}
          title="Delete Image"
          message="Are you sure you want to delete this image? This action cannot be undone."
        />
        
        <PricingModal 
          show={showPricingModal} 
          onClose={() => {
            setShowPricingModal(false);
            setError('');
          }}
        />
      </div>
    </Container>
  );
};

export default ImageGeneration;