const NameTitleData = {
    wifiNameGenerator: {
        prompt: "95001",
        icon: "📶",
        title: "WiFi Name Generator",
        category: "Name & Title",
        caption: "Generate creative and memorable WiFi network names.",
        inputs: [{ 
            inputTitle: 'What style or theme would you like for your WiFi name?',
            inputCaption: 'E.g., funny, professional, geeky, etc.',
            inputExample: 'Tech/Geeky Style' 
        }]
    },
    twitterUsernameGenerator: {
        prompt: "95002",
        icon: "🐦",
        title: "Twitter Username Generator",
        category: "Name & Title",
        caption: "Create unique and available Twitter usernames.",
        inputs: [{ 
            inputTitle: 'What interests or themes would you like to incorporate?',
            inputCaption: 'Enter keywords related to your interests or brand',
            inputExample: 'Tech & Gaming' 
        }]
    },
    gamingUsernameGenerator: {
        prompt: "95003",
        icon: "🎮",
        title: "Gaming Username Generator",
        category: "Name & Title",
        caption: "Generate epic gaming usernames for various platforms.",
        inputs: [{ 
            inputTitle: 'What type of gaming username would you like?',
            inputCaption: 'E.g., epic, funny, competitive, etc.',
            inputExample: 'Epic Competitive Style' 
        }]
    },
    artTitleGenerator: {
        prompt: "95004",
        icon: "🎨",
        title: "Art Title Generator",
        category: "Name & Title",
        caption: "Create compelling titles for artwork and exhibitions.",
        inputs: [{ 
            inputTitle: 'Describe your artwork or exhibition theme',
            inputCaption: 'Enter details about the art style, medium, or theme',
            inputExample: 'Abstract Urban Photography' 
        }]
    },
    domainNameGenerator: {
        prompt: "95005",
        icon: "🌐",
        title: "Domain Name Generator",
        category: "Name & Title",
        caption: "Generate available and creative domain name suggestions.",
        inputs: [{ 
            inputTitle: 'What is your website about?',
            inputCaption: 'Enter keywords related to your business or project',
            inputExample: 'Digital Marketing Agency' 
        }]
    },
    fantasyCharacterGenerator: {
        prompt: "95006",
        icon: "⚔️",
        title: "Fantasy Character Name Generator",
        category: "Name & Title",
        caption: "Create unique names for fantasy characters and worlds.",
        inputs: [{ 
            inputTitle: 'What type of fantasy character needs a name?',
            inputCaption: 'Specify race, class, or character type',
            inputExample: 'Elven Warrior' 
        }]
    },
    comicBookTitleGenerator: {
        prompt: "95007",
        icon: "💥",
        title: "Comic Book Title Generator",
        category: "Name & Title",
        caption: "Generate catchy titles for comic books and graphic novels.",
        inputs: [{ 
            inputTitle: 'What is your comic book about?',
            inputCaption: 'Enter theme, genre, or main character type',
            inputExample: 'Superhero Origin Story' 
        }]
    },
    tvShowNameGenerator: {
        prompt: "95008",
        icon: "📺",
        title: "TV Show Name Generator",
        category: "Name & Title",
        caption: "Create engaging titles for television shows and series.",
        inputs: [{ 
            inputTitle: 'What is your show about?',
            inputCaption: 'Enter genre and main theme of your show',
            inputExample: 'Crime Drama Mystery' 
        }]
    },
    babyNameGenerator: {
        prompt: "95009",
        icon: "👶",
        title: "Baby Name Generator",
        category: "Name & Title",
        caption: "Find perfect names for newborns with meaning and origin.",
        inputs: [
            { 
                inputTitle: 'Any specific culture or origin preference?',
                inputCaption: 'Enter preferred cultural background',
                inputExample: 'Celtic Origins' 
            },
            {
                inputTitle: 'Gender preference (if any)',
                inputCaption: 'Enter preferred gender or "neutral"',
                required: false,
                inputExample: 'Gender Neutral'
            }
        ]
    },
    brandNameGenerator: {
        prompt: "95010",
        icon: "™️",
        title: "Brand Name Generator",
        category: "Name & Title",
        caption: "Create memorable and unique brand names for businesses.",
        inputs: [{ 
            inputTitle: 'What does your brand represent?',
            inputCaption: 'Describe your business type and values',
            inputExample: 'Eco-friendly Fashion Brand' 
        }]
    },
    productNameGenerator: {
        prompt: "95011",
        icon: "📦",
        title: "Product Name Generator",
        category: "Name & Title",
        caption: "Generate catchy names for products and services.",
        inputs: [{ 
            inputTitle: 'Describe your product',
            inputCaption: 'Enter product type and key features',
            inputExample: 'Smart Home Security Device' 
        }]
    },
    eventNameGenerator: {
        prompt: "95012",
        icon: "🎪",
        title: "Event Name Generator",
        category: "Name & Title",
        caption: "Create memorable names for events and conferences.",
        inputs: [{ 
            inputTitle: 'What type of event are you planning?',
            inputCaption: 'Describe event type and theme',
            inputExample: 'Tech Innovation Conference' 
        }]
    },
    podcastTitleGenerator: {
        prompt: "95013",
        icon: "🎙️",
        title: "Podcast Title Generator",
        category: "Name & Title",
        caption: "Generate engaging titles for podcast shows and episodes.",
        inputs: [{ 
            inputTitle: 'What is your podcast about?',
            inputCaption: 'Enter main topic or theme',
            inputExample: 'True Crime Stories' 
        }]
    },
    bandNameGenerator: {
        prompt: "95014",
        icon: "🎸",
        title: "Band Name Generator",
        category: "Name & Title",
        caption: "Create unique names for music bands and groups.",
        inputs: [{ 
            inputTitle: 'What music genre do you play?',
            inputCaption: 'Enter music style and influences',
            inputExample: 'Alternative Rock' 
        }]
    },
    restaurantNameGenerator: {
        prompt: "95015",
        icon: "🍽️",
        title: "Restaurant Name Generator",
        category: "Name & Title",
        caption: "Generate appealing names for restaurants and cafes.",
        inputs: [{ 
            inputTitle: 'What type of restaurant is it?',
            inputCaption: 'Enter cuisine type and concept',
            inputExample: 'Modern Italian Bistro' 
        }]
    },
    superHeroNameGenerator: {
        prompt: "95016",
        icon: "🦸‍♂️",
        title: "Superhero Name Generator",
        category: "Name & Title",
        caption: "Create epic superhero names and alter egos.",
        inputs: [{ 
            inputTitle: 'Describe the superhero powers or theme',
            inputCaption: 'Enter superpowers or character concept',
            inputExample: 'Weather Control Powers' 
        }]
    },
    petNameGenerator: {
        prompt: "95017",
        icon: "🐾",
        title: "Pet Name Generator",
        category: "Name & Title",
        caption: "Find perfect names for your furry friends.",
        inputs: [
            { 
                inputTitle: 'What type of pet do you have?',
                inputCaption: 'Enter pet type and breed if applicable',
                inputExample: 'Golden Retriever Dog' 
            },
            {
                inputTitle: 'Preferred name style',
                inputCaption: 'E.g., cute, funny, human names, etc.',
                required: false,
                inputExample: 'Cute Names'
            }
        ]
    },
    streamTitleGenerator: {
        prompt: "95018",
        icon: "🎮",
        title: "Stream Title Generator",
        category: "Name & Title",
        caption: "Create engaging titles for your streaming content.",
        inputs: [{ 
            inputTitle: 'What are you streaming?',
            inputCaption: 'Enter game name or content type',
            inputExample: 'Minecraft Survival Mode' 
        }]
    },
    discordServerGenerator: {
        prompt: "95019",
        icon: "💬",
        title: "Discord Server Name Generator",
        category: "Name & Title",
        caption: "Generate unique names for Discord communities.",
        inputs: [{ 
            inputTitle: 'What is your server about?',
            inputCaption: 'Enter server theme or purpose',
            inputExample: 'Gaming Community' 
        }]
    },
    youtubeChannelGenerator: {
        prompt: "95020",
        icon: "▶️",
        title: "YouTube Channel Name Generator",
        category: "Name & Title",
        caption: "Create memorable YouTube channel names.",
        inputs: [{ 
            inputTitle: 'What content will you create?',
            inputCaption: 'Enter content type and theme',
            inputExample: 'Tech Reviews and Tutorials' 
        }]
    },
    foodTruckNameGenerator: {
        prompt: "95021",
        icon: "🚚",
        title: "Food Truck Name Generator",
        category: "Name & Title",
        caption: "Create catchy names for food truck businesses.",
        inputs: [{ 
            inputTitle: 'What type of cuisine do you serve?',
            inputCaption: 'Enter cuisine type and concept',
            inputExample: 'Mexican Street Food' 
        }]
    },
    esportsTeamGenerator: {
        prompt: "95022",
        icon: "🏆",
        title: "Esports Team Name Generator",
        category: "Name & Title",
        caption: "Generate powerful names for esports teams.",
        inputs: [{ 
            inputTitle: 'What game do you compete in?',
            inputCaption: 'Enter game name and team style',
            inputExample: 'Competitive CSGO Team' 
        }]
    },
    cocktailNameGenerator: {
        prompt: "95023",
        icon: "🍸",
        title: "Cocktail Name Generator",
        category: "Name & Title",
        caption: "Create unique names for signature cocktails.",
        inputs: [{ 
            inputTitle: 'Describe your cocktail',
            inputCaption: 'Enter main ingredients and style',
            inputExample: 'Tropical Rum-based Cocktail' 
        }]
    },
    spaceshipNameGenerator: {
        prompt: "95024",
        icon: "🚀",
        title: "Spaceship Name Generator",
        category: "Name & Title",
        caption: "Generate epic names for spacecraft and vessels.",
        inputs: [{ 
            inputTitle: 'What type of spacecraft is it?',
            inputCaption: 'Enter vessel type and purpose',
            inputExample: 'Deep Space Exploration Vessel' 
        }]
    },
    magicalSpellGenerator: {
        prompt: "95025",
        icon: "✨",
        title: "Magical Spell Name Generator",
        category: "Name & Title",
        caption: "Create mystical names for spells and incantations.",
        inputs: [{ 
            inputTitle: 'What does the spell do?',
            inputCaption: 'Enter spell effect and magic type',
            inputExample: 'Fire-based Attack Spell' 
        }]
    },
    virtualWorldGenerator: {
        prompt: "95026",
        icon: "🌐",
        title: "Virtual World Name Generator",
        category: "Name & Title",
        caption: "Generate names for virtual worlds and metaverse spaces.",
        inputs: [{ 
            inputTitle: 'What is your virtual world about?',
            inputCaption: 'Enter theme and purpose',
            inputExample: 'Social Gaming Metaverse' 
        }]
    },
    indieGameTitleGenerator: {
        prompt: "95027",
        icon: "🎲",
        title: "Indie Game Title Generator",
        category: "Name & Title",
        caption: "Create unique titles for indie games.",
        inputs: [{ 
            inputTitle: 'What type of game is it?',
            inputCaption: 'Enter genre and main mechanics',
            inputExample: 'Pixel Art Platformer' 
        }]
    },
    fashionLineGenerator: {
        prompt: "95029",
        icon: "👗",
        title: "Fashion Line Name Generator",
        category: "Name & Title",
        caption: "Generate stylish names for fashion brands and collections.",
        inputs: [{ 
            inputTitle: 'What type of fashion line is it?',
            inputCaption: 'Enter style and target market',
            inputExample: 'Sustainable Streetwear Collection' 
        }]
    },
    albumTitleGenerator: {
        prompt: "95030",
        icon: "💿",
        title: "Album Title Generator",
        category: "Name & Title",
        caption: "Create meaningful titles for music albums.",
        inputs: [{ 
            inputTitle: 'What is the album\'s genre and theme?',
            inputCaption: 'Enter music style and concept',
            inputExample: 'Alternative Rock Concept Album' 
        }]
    },
    sportsTeamGenerator: {
        prompt: "95031",
        icon: "⚽",
        title: "Sports Team Name Generator",
        category: "Name & Title",
        caption: "Generate powerful names for sports teams.",
        inputs: [{ 
            inputTitle: 'What sport and level?',
            inputCaption: 'Enter sport type and competition level',
            inputExample: 'Professional Soccer Team' 
        }]
    },
    recipeNameGenerator: {
        prompt: "95032",
        icon: "📝",
        title: "Recipe Title Generator",
        category: "Name & Title",
        caption: "Create appetizing names for food recipes.",
        inputs: [{ 
            inputTitle: 'Describe your recipe',
            inputCaption: 'Enter main ingredients and style',
            inputExample: 'Spicy Asian Fusion Dish' 
        }]
    },
    musicFestivalGenerator: {
        prompt: "95033",
        icon: "🎪",
        title: "Music Festival Name Generator",
        category: "Name & Title",
        caption: "Generate exciting names for music festivals and events.",
        inputs: [{ 
            inputTitle: 'What type of festival?',
            inputCaption: 'Enter music genres and vibe',
            inputExample: 'Electronic Music Desert Festival' 
        }]
    },
    appNameGenerator: {
        prompt: "95034",
        icon: "📱",
        title: "App Name Generator",
        category: "Name & Title",
        caption: "Create catchy names for mobile applications.",
        inputs: [{ 
            inputTitle: 'What does your app do?',
            inputCaption: 'Enter app purpose and features',
            inputExample: 'Productivity Task Manager' 
        }]
    },
    nftCollectionGenerator: {
        prompt: "95035",
        icon: "🎨",
        title: "NFT Collection Name Generator",
        category: "Name & Title",
        caption: "Generate unique names for NFT collections.",
        inputs: [{ 
            inputTitle: 'What is your NFT collection about?',
            inputCaption: 'Enter theme and art style',
            inputExample: 'Cyberpunk Animal Collection' 
        }]
    },
    scientificDiscoveryGenerator: {
        prompt: "95036",
        icon: "🔬",
        title: "Scientific Discovery Name Generator",
        category: "Name & Title",
        caption: "Create names for scientific phenomena and discoveries.",
        inputs: [{ 
            inputTitle: 'What type of discovery is it?',
            inputCaption: 'Enter field and type of discovery',
            inputExample: 'New Astronomical Phenomenon' 
        }]
    },
    mythologicalCreatureGenerator: {
        prompt: "95037",
        icon: "🐉",
        title: "Mythological Creature Name Generator",
        category: "Name & Title",
        caption: "Generate names for mythical beings and creatures.",
        inputs: [{ 
            inputTitle: 'What type of creature is it?',
            inputCaption: 'Enter creature type and characteristics',
            inputExample: 'Ancient Forest Guardian' 
        }]
    },
    digitalCurrencyGenerator: {
        prompt: "95038",
        icon: "💰",
        title: "Digital Currency Name Generator",
        category: "Name & Title",
        caption: "Create names for cryptocurrency and digital tokens.",
        inputs: [{ 
            inputTitle: 'What is the currency\'s purpose?',
            inputCaption: 'Enter use case and technology',
            inputExample: 'Gaming Reward Token' 
        }]
    },
    academicPaperGenerator: {
        prompt: "95039",
        icon: "📚",
        title: "Academic Paper Title Generator",
        category: "Name & Title",
        caption: "Generate professional titles for academic papers.",
        inputs: [{ 
            inputTitle: 'What is your research about?',
            inputCaption: 'Enter field and research focus',
            inputExample: 'AI Ethics Study' 
        }]
    },
    ancientArtifactGenerator: {
        prompt: "95040",
        icon: "🏺",
        title: "Ancient Artifact Name Generator",
        category: "Name & Title",
        caption: "Create names for fictional ancient artifacts.",
        inputs: [{ 
            inputTitle: 'What type of artifact is it?',
            inputCaption: 'Enter artifact type and origin',
            inputExample: 'Magical Egyptian Relic' 
        }]
    },
    fictionalLanguageGenerator: {
        prompt: "95041",
        icon: "🗣️",
        title: "Fictional Language Name Generator",
        category: "Name & Title",
        caption: "Generate names for constructed languages.",
        inputs: [{ 
            inputTitle: 'What kind of language is it?',
            inputCaption: 'Enter culture and characteristics',
            inputExample: 'Ancient Elvish Dialect' 
        }]
    }
}

export default NameTitleData;