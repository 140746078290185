const StoryData = {
    storyPlotGenerator: {
        prompt: "4001",
        icon: "📖",
        title: "Story Plot Generator",
        category: "Story Poetry",
        caption: "Generate a core plot or premise for your story with customizable twists.",
        inputs: [
            { inputTitle: 'Genre', inputCaption: 'Select the genre of your story.', type: 'text', inputExample: "Fantasy" },
            { inputTitle: 'Themes or Keywords', inputCaption: 'Enter themes or keywords to incorporate.', type: 'textarea', inputExample: "Magic, Adventure, Destiny" },
            { inputTitle: 'Plot Twists', inputCaption: 'Would you like to include plot twists? Yes/No.', type: 'text', inputExample: "Yes" }
        ]
    },
    storyOutlineCreator: {
        prompt: "4002",
        icon: "🗺️",
        title: "Story Outline Creator",
        category: "Story Poetry",
        caption: "Structure your story with a detailed outline of events and character arcs.",
        inputs: [
            { inputTitle: 'Main Events', inputCaption: 'Describe the main events of the story.', type: 'textarea', inputExample: "The hero's journey begins with a call to adventure." },
            { inputTitle: 'Character Arcs', inputCaption: 'Outline the development arcs for key characters.', type: 'textarea', inputExample: "Character A starts as a reluctant hero and grows into a leader." },
            { inputTitle: 'Settings', inputCaption: 'List the primary settings of your story.', type: 'text', inputExample: "Magical kingdom of Eldoria" }
        ]
    },
    storyGenerator: {
        prompt: "4003",
        icon: "✍️",
        title: "Story Generator",
        category: "Story Poetry",
        caption: "Create short stories or narratives based on your inputs.",
        inputs: [
            { inputTitle: 'Characters', inputCaption: 'Describe the characters involved.', type: 'textarea', inputExample: "Sara: Brave adventurer seeking treasure" },
            { inputTitle: 'Setting', inputCaption: 'Define the setting of your story.', type: 'text', inputExample: "Mysterious forest" },
            { inputTitle: 'Key Events', inputCaption: 'List key events to occur.', type: 'textarea', inputExample: "Discovery of ancient artifact" }
        ]
    },
    storyChaptersOrganizer: {
        prompt: "4004",
        icon: "📚",
        title: "Story Chapters Organizer",
        category: "Story Poetry",
        caption: "Plan and structure the chapters of your story for optimal flow.",
        inputs: [
            { inputTitle: 'Chapter Titles', inputCaption: 'List your chapter titles.', type: 'textarea', inputExample: "Chapter 1: The Call to Adventure" },
            { inputTitle: 'Chapter Summaries', inputCaption: 'Provide a brief summary for each chapter.', type: 'textarea', inputExample: "Introduction of main characters and setting" }
        ]
    },
    storyFlowAssistant: {
        prompt: "4005",
        icon: "⚡",
        title: "Story Flow Assistant",
        category: "Story Poetry",
        caption: "Analyze and improve the pacing and engagement of your narrative.",
        inputs: [
            { inputTitle: 'Story Content', inputCaption: 'Paste your story content here.', type: 'textarea', inputExample: "Once upon a time..." }
        ]
    },
    storyRephraseTool: {
        prompt: "4006",
        icon: "🔄",
        title: "Story Rephrase Tool",
        category: "Story Poetry",
        caption: "Refine your story's language for clarity and impact.",
        inputs: [
            { inputTitle: 'Text to Rephrase', inputCaption: 'Enter the text you want to rephrase.', type: 'textarea', inputExample: "The quick brown fox jumps over the lazy dog." }
        ]
    },
    dialogueGenerator: {
        prompt: "4007",
        icon: "💬",
        title: "Dialogue Generator",
        category: "Story Poetry",
        caption: "Generate realistic dialogues for your characters.",
        inputs: [
            { inputTitle: 'Context', inputCaption: 'Describe the scene or context for the dialogue.', type: 'textarea', inputExample: "In a medieval tavern" },
            { inputTitle: 'Characters Involved', inputCaption: 'List the characters involved and their traits.', type: 'textarea', inputExample: "Character A: Brave knight\nCharacter B: Witty bard" }
        ]
    },
    developmentalEditingAssistant: {
        prompt: "4008",
        icon: "📝",
        title: "Developmental Editing Assistant",
        category: "Story Poetry",
        caption: "Get feedback on structure, character development, and themes.",
        inputs: [
            { inputTitle: 'Story Draft', inputCaption: 'Paste your story draft here.', type: 'textarea', inputExample: "Once upon a time..." }
        ]
    },
    aiStoryteller: {
        prompt: "4009",
        icon: "🗣️",
        title: "AI Storyteller",
        category: "Story Poetry",
        caption: "Experience your stories narrated with character-specific voices.",
        inputs: [
            { inputTitle: 'Story Text', inputCaption: 'Enter the text of the story to be narrated.', type: 'textarea', inputExample: "Once upon a time..." },
            { inputTitle: 'Voice Preferences', inputCaption: 'Specify any voice preferences for characters.', type: 'textarea', required: 'false', inputExample: "Male voice for Character A, Female voice for Character B" }
        ]
    },
    storyNameGenerator: {
        prompt: "4011",
        icon: "✍️",
        title: "Story Name Generator",
        category: "Story Poetry",
        caption: "Generate captivating names for your stories.",
        inputs: [
            { inputTitle: 'Genre', inputCaption: 'Specify the genre of your story.', type: 'text', inputExample: "Mystery", type: 'text' },
            { inputTitle: 'Key Themes', inputCaption: 'List key themes or elements of the story.', type: 'text', inputExample: "Murder, Intrigue, Suspense" },
        ]
    },
    storyRandom: {
        prompt: "4012",
        icon: "🎲",
        title: "Random Story Generator",
        category: "Story Poetry",
        caption: "Create unique and engaging stories with a touch of randomness.",
        inputs: [
            { inputTitle: 'Seed Elements', inputCaption: 'Enter any seed elements or keywords to include in the story (optional).', type: 'text', required: 'false', inputExample: "Magic, Adventure" },
            { inputTitle: 'Genre', inputCaption: 'Choose the genre for the story.', type: 'text', inputExample: "Fantasy" },
            { inputTitle: 'Length', inputCaption: 'Preferred length of the story (e.g., short story, novella, flash fiction).', type: 'text', required: 'false', inputExample: "Short story" }
        ]
    },
    storyBeats: {
        prompt: "4010",
        icon: "🎼",
        title: "Story Beats",
        category: "Story Poetry",
        caption: "Map out the crucial moments in your story.",
        inputs: [
            { inputTitle: 'Story Synopsis', inputCaption: 'Provide a brief synopsis of your story.', type: 'textarea', inputExample: "A young hero sets out on a quest to save the kingdom from darkness." },
            { inputTitle: 'Desired Emotional Arc', inputCaption: 'Describe the emotional journey you want your story to take.', type: 'textarea', required: 'false', inputExample: "From despair to triumph" }
        ]
    },
    poemTitleGenerator: {
        prompt: "4013",
        icon: "🎭",
        title: "Poem Title Generator",
        category: "Story Poetry",
        caption: "Generate creative and meaningful titles for your poems.",
        inputs: [
            { inputTitle: 'Theme', inputCaption: 'Enter the main theme or subject of your poem.', type: 'text', inputExample: "Love, Nature, Loss" },
            { inputTitle: 'Style', inputCaption: 'Specify the style of poetry (e.g., Sonnet, Haiku).', type: 'text', inputExample: "Sonnet" },
            { inputTitle: 'Mood', inputCaption: 'Describe the intended emotional tone.', type: 'text', inputExample: "Melancholic" }
        ]
    },
    
    loveNoteCreator: {
        prompt: "4014",
        icon: "💌",
        title: "Love Note Creator",
        category: "Story Poetry",
        caption: "Create heartfelt and romantic love notes.",
        inputs: [
            { inputTitle: 'Recipient', inputCaption: 'Who is the note for?', type: 'text', inputExample: "Partner's name" },
            { inputTitle: 'Occasion', inputCaption: 'Special occasion or reason for the note.', type: 'text', inputExample: "Anniversary" },
            { inputTitle: 'Special Memories', inputCaption: 'Include specific memories or moments.', type: 'textarea', inputExample: "Our first date at the beach" }
        ]
    },

    limerickGenerator: {
        prompt: "4015",
        icon: "📝",
        title: "Limerick Generator",
        category: "Story Poetry",
        caption: "Create fun and witty limericks based on your input.",
        inputs: [
            { inputTitle: 'Subject', inputCaption: 'Main subject or character of the limerick.', type: 'text', inputExample: "A sailor from Maine" },
            { inputTitle: 'Theme', inputCaption: 'Theme or situation for the limerick.', type: 'text', inputExample: "Adventure at sea" }
        ]
    },

    metaphorGenerator: {
        prompt: "4016",
        icon: "🎯",
        title: "Metaphor & Idiom Generator",
        category: "Story Poetry",
        caption: "Generate creative metaphors and idioms for your writing.",
        inputs: [
            { inputTitle: 'Concept', inputCaption: 'The concept you want to describe figuratively.', type: 'text', inputExample: "Success" },
            { inputTitle: 'Context', inputCaption: 'The context or setting for the metaphor.', type: 'text', inputExample: "Business achievement" },
            { inputTitle: 'Style', inputCaption: 'Preferred style (Modern/Classical/Cultural).', type: 'text', inputExample: "Modern" }
        ]
    },

    poetryStyleGenerator: {
        prompt: "4017",
        icon: "🎨",
        title: "Poetry Style Generator",
        category: "Story Poetry",
        caption: "Generate poetry in various styles and forms.",
        inputs: [
            { inputTitle: 'Theme', inputCaption: 'Main theme or subject of the poem.', type: 'text', inputExample: "Spring" },
            { inputTitle: 'Form', inputCaption: 'Desired poetic form.', type: 'text', inputExample: "Haiku" },
            { inputTitle: 'Tone', inputCaption: 'Emotional tone of the poem.', type: 'text', inputExample: "Contemplative" }
        ]
    },

    figurativeLanguageGenerator: {
        prompt: "4018",
        icon: "💭",
        title: "Figurative Language Generator",
        category: "Story Poetry",
        caption: "Create various forms of figurative language for your writing.",
        inputs: [
            { inputTitle: 'Type', inputCaption: 'Type of figurative language (Simile/Personification/etc).', type: 'text', inputExample: "Simile" },
            { inputTitle: 'Subject', inputCaption: 'Subject to describe.', type: 'text', inputExample: "Ocean waves" },
            { inputTitle: 'Mood', inputCaption: 'Desired emotional impact.', type: 'text', inputExample: "Peaceful" }
        ]
    },

    writingPromptGenerator: {
        prompt: "4019",
        icon: "💡",
        title: "Writing Prompt Generator",
        category: "Story Poetry",
        caption: "Generate creative writing prompts to inspire your work.",
        inputs: [
            { inputTitle: 'Genre', inputCaption: 'Preferred genre for the prompt.', type: 'text', inputExample: "Science Fiction" },
            { inputTitle: 'Elements', inputCaption: 'Specific elements to include.', type: 'textarea', inputExample: "Time travel, Ancient civilization" },
            { inputTitle: 'Length', inputCaption: 'Intended length of the piece.', type: 'text', inputExample: "Short story" }
        ]
    },

    characterDevelopmentTool: {
        prompt: "4020",
        icon: "👤",
        title: "Character Development Tool",
        category: "Story Poetry",
        caption: "Create detailed character profiles and development arcs.",
        inputs: [
            { inputTitle: 'Character Type', inputCaption: 'Main role of the character.', type: 'text', inputExample: "Protagonist" },
            { inputTitle: 'Background', inputCaption: 'Characters background details.', type: 'textarea', inputExample: "Orphaned at young age, raised by grandparents" },
            { inputTitle: 'Goals', inputCaption: 'Characters main goals and motivations.', type: 'textarea', inputExample: "Seeking revenge against corrupt corporation" }
        ]
    },

        fantasyLanguageCreator: {
            prompt: "4021",
            icon: "🗣️",
            title: "Fantasy Language Creator",
            category: "Story Poetry",
            caption: "Create unique and consistent fantasy languages for your fictional worlds.",
            inputs: [
                { inputTitle: 'Culture Type', inputCaption: 'Describe the culture this language belongs to.', type: 'textarea', inputExample: "Ancient forest-dwelling elves" },
                { inputTitle: 'Language Features', inputCaption: 'Desired characteristics of the language.', type: 'textarea', inputExample: "Flowing, musical, nature-based" },
                { inputTitle: 'Complexity Level', inputCaption: 'How complex should the language be?', type: 'text', inputExample: "Medium - basic grammar and 100 words" }
            ]
        },
    
        horrorStoryGenerator: {
            prompt: "4022",
            icon: "👻",
            title: "Horror Story Generator",
            category: "Story Poetry",
            caption: "Generate spine-chilling horror stories with customizable elements.",
            inputs: [
                { inputTitle: 'Horror Type', inputCaption: 'Style of horror (psychological, supernatural, etc).', type: 'text', inputExample: "Psychological" },
                { inputTitle: 'Setting', inputCaption: 'Where does the story take place?', type: 'text', inputExample: "Abandoned asylum" },
                { inputTitle: 'Key Elements', inputCaption: 'Specific elements to include.', type: 'textarea', inputExample: "Unreliable narrator, strange noises, missing time" }
            ]
        },
    
        childrensBookGenerator: {
            prompt: "4023",
            icon: "📚",
            title: "Children's Book Generator",
            category: "Story Poetry",
            caption: "Create engaging and age-appropriate children's stories.",
            inputs: [
                { inputTitle: 'Age Group', inputCaption: 'Target age range for the story.', type: 'text', inputExample: "6-8 years" },
                { inputTitle: 'Theme', inputCaption: 'Main lesson or theme of the story.', type: 'text', inputExample: "Friendship and sharing" },
                { inputTitle: 'Characters', inputCaption: 'Main characters in the story.', type: 'textarea', inputExample: "A shy penguin and an outgoing seal" }
            ]
        },
    
        bookTitleGenerator: {
            prompt: "4024",
            icon: "📖",
            title: "Book Title Generator",
            category: "Story Poetry",
            caption: "Generate compelling and marketable book titles.",
            inputs: [
                { inputTitle: 'Genre', inputCaption: 'Book genre.', type: 'text', inputExample: "Epic Fantasy" },
                { inputTitle: 'Key Themes', inputCaption: 'Main themes of the book.', type: 'textarea', inputExample: "Redemption, power, sacrifice" },
                { inputTitle: 'Target Audience', inputCaption: 'Intended readership.', type: 'text', inputExample: "Young Adult" }
            ]
        },
    
        scriptWriter: {
            prompt: "4025",
            icon: "🎬",
            title: "Script Writer",
            category: "Story Poetry",
            caption: "Generate professional scripts for various media formats.",
            inputs: [
                { inputTitle: 'Script Type', inputCaption: 'Type of script needed.', type: 'text', inputExample: "Feature Film" },
                { inputTitle: 'Genre', inputCaption: 'Genre of the script.', type: 'text', inputExample: "Action" },
                { inputTitle: 'Scene Description', inputCaption: 'Description of the scene needed.', type: 'textarea', inputExample: "Opening chase sequence through city streets" }
            ]
        },
    
        fanficGenerator: {
            prompt: "4026",
            icon: "✨",
            title: "Fanfic Generator",
            category: "Story Poetry",
            caption: "Create engaging fanfiction stories within existing universes.",
            inputs: [
                { inputTitle: 'Original Work', inputCaption: 'Source material/universe.', type: 'text', inputExample: "Harry Potter" },
                { inputTitle: 'Story Type', inputCaption: 'Type of fanfic (AU, continuation, etc).', type: 'text', inputExample: "Alternate Universe" },
                { inputTitle: 'Focus Characters', inputCaption: 'Main characters involved.', type: 'textarea', inputExample: "Luna Lovegood, Neville Longbottom" }
            ]
        },
    
        playwrightAssistant: {
            prompt: "4027",
            icon: "🎭",
            title: "Playwriting Assistant",
            category: "Story Poetry",
            caption: "Generate theatrical plays with stage directions and dialogue.",
            inputs: [
                { inputTitle: 'Play Type', inputCaption: 'Style of play.', type: 'text', inputExample: "Comedy" },
                { inputTitle: 'Act/Scene', inputCaption: 'Which part of the play?', type: 'text', inputExample: "Act 1, Scene 2" },
                { inputTitle: 'Setting Description', inputCaption: 'Description of the setting.', type: 'textarea', inputExample: "A Victorian parlor at dusk" }
            ]
        },
    
        bookSummaryGenerator: {
            prompt: "4028",
            icon: "📑",
            title: "Book Summary Generator",
            category: "Story Poetry",
            caption: "Create comprehensive book summaries and analyses.",
            inputs: [
                { inputTitle: 'Book Title', inputCaption: 'Title of the book to summarize.', type: 'text', inputExample: "1984" },
                { inputTitle: 'Summary Type', inputCaption: 'Type of summary needed.', type: 'text', inputExample: "Chapter-by-chapter" },
                { inputTitle: 'Focus Areas', inputCaption: 'Specific aspects to emphasize.', type: 'textarea', inputExample: "Themes, character development, symbolism" }
            ]
        }
};
export default StoryData;
