import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Spinner, Pagination, Dropdown } from "react-bootstrap"; 
import { useNavigate } from "react-router";
import { useUserAuth } from "../../../context/UserAuthContext";
import toast from 'react-hot-toast';
import { Trash, FilePenLine } from 'lucide-react';
import QuickTemplateServices from "../../tools/quick-template/services/QuickTemplate.services";
import AIToolsDataService from "../../tools/ai-tools/services/AiTools.services";
import articlePostServices from "../../tools/articlePost/services/articlePost.services";
import Loader2 from "../../shared/utilities/loader2/Loader2";

const MyContent = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  
  const [activeTab, setActiveTab] = useState('tools-content');
  const [loading, setLoading] = useState(false);
  const [toolsContent, setToolsContent] = useState([]);
  const [myTemplates, setMyTemplates] = useState([]);
  const [article, setArticle] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    getToolsContent();
    getMyTemplates();
    getArticle();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const truncateContent = (content, lines = 2) => {
    if (!content) return '';
    const contentLines = content.split('\n').slice(0, lines);
    return contentLines.join('\n') + (content.split('\n').length > lines ? '...' : '');
  };

  const getToolsContent = async () => {
    setLoading(true);
    try {
      const data = await AIToolsDataService.getAllProjects(user.uid);
      if (data.docs.length > 0) {
        const formattedData = data.docs.map(doc => {
          const docData = doc.data();
          return {
            ...docData,
            id: doc.id,
            title: docData.toolObj?.title || '',
            content: docData.text || docData.title || '',
            creationDate: docData.creationDate || new Date().getTime() / 1000
          };
        });
        // Sort by creation date in descending order (latest first)
        const sortedData = formattedData.sort((a, b) => b.creationDate - a.creationDate);
        setToolsContent(sortedData);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch tools content');
    }
    setLoading(false);
  };

  const getMyTemplates = async () => {
    setLoading(true);
    try {
      const data = await QuickTemplateServices.getAllProjects(user.uid);
      if (data.docs.length > 0) {
        const formattedData = data.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          content: doc.data().title || '',
          creationDate: doc.data().creationDate || new Date().getTime() / 1000
        }));
        // Sort by creation date in descending order
        const sortedData = formattedData.sort((a, b) => b.creationDate - a.creationDate);
        setMyTemplates(sortedData);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch templates');
    }
    setLoading(false);
  };

  const getArticle = async () => {
    setLoading(true);
    try {
      const data = await articlePostServices.getAllProjects(user.uid);
      if (data.docs.length > 0) {
        const formattedData = data.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          content: doc.data().title || '',
          creationDate: doc.data().creationDate || new Date().getTime() / 1000
        }));
        // Sort by creation date in descending order
        const sortedData = formattedData.sort((a, b) => b.creationDate - a.creationDate);
        setArticle(sortedData);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch articles');
    }
    setLoading(false);
  };

  const handleDelete = async (id, type) => {
    try {
      switch (type) {
        case 'tools-content':
          await AIToolsDataService.deleteProjectContent(user.uid, id);
          setToolsContent(prev => prev.filter(item => item.id !== id));
          break;
        case 'quick-template':
          await QuickTemplateServices.deleteProjectContent(user.uid, id);
          setMyTemplates(prev => prev.filter(item => item.id !== id));
          break;
        case 'article-writer':
          await articlePostServices.deleteProjectContent(user.uid, id);
          setArticle(prev => prev.filter(item => item.id !== id));
          break;
      }
      toast.success('Content deleted!');
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete content');
    }
  };

  const renderPagination = (totalItems) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    
    if (totalPages <= 1) return null;

    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    let items = [];
    if (startPage > 1) {
      items.push(<Pagination.First key="first" onClick={() => setCurrentPage(1)} />);
      items.push(<Pagination.Prev key="prev" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item 
          key={number} 
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(<Pagination.Next key="next" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} />);
      items.push(<Pagination.Last key="last" onClick={() => setCurrentPage(totalPages)} />);
    }

    return <Pagination className="mt-3 justify-content-center">{items}</Pagination>;
  };

  const renderContent = () => {
    const contentMap = {
      'tools-content': toolsContent,
      'quick-template': myTemplates,
      'article-writer': article
    };

    const data = contentMap[activeTab] || [];
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <>
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Title</th>
              {activeTab === 'tools-content' && <th style={{ width: '40%' }}>Content</th>}
              {activeTab === 'article-writer' && <th style={{ width: '10%' }}>Word Count</th>}
              <th style={{ width: '15%' }}>Date</th>
              <th style={{ width: '15%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map(item => (
              <tr key={item.id}>
                <td dangerouslySetInnerHTML={{ __html: item.title }} />
                {activeTab === 'tools-content' && (
                  <td className="text-truncate" style={{ maxWidth: '400px' }}>
                    <div className="content-data" style={{ maxHeight: '3em', overflow: 'hidden' }} 
                         dangerouslySetInnerHTML={{ __html: truncateContent(item.content) }} />
                  </td>
                )}
                {activeTab === 'article-writer' && <td>{item.wordCount}</td>}
                <td>{new Date(item.creationDate * 1000).toDateString()}</td>
                <td>
                  <div className="d-flex gap-3">
                  <button 
                    className="btn btn-outline-secondary btn-sm me-2"
                    id={item.id}
                    onClick={(e) => {
                      switch (activeTab) {
                        case 'tools-content':
                          navigate(`/ai-tools/?prompt=${item.prompt}&id=${item.id}`);
                          break;
                        case 'quick-template':
                          navigate(`/flexy-template/?id=${item.id}`);
                          break;
                        case 'article-writer':
                          navigate(`/article-writer/?id=${item.id}`);
                          break;
                      }
                    }}
                  >
                  <FilePenLine size={16}/> 
                  </button>
                  <button 
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => handleDelete(item.id, activeTab)}
                  >
                 <Trash size={16}/>  
                  </button></div>
                </td>
              </tr>
            ))}
            {!data.length && (
              <tr>
                <td colSpan={
                  activeTab === 'tools-content' ? 4 : 
                  activeTab === 'article-writer' ? 4 : 3
                } className="text-center">
                  No content found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {renderPagination(data.length)}
      </>
    );
  };

  const categories = [
    { value: 'tools-content', label: `Tools Content (${toolsContent.length})` },
    { value: 'quick-template', label: `My Templates (${myTemplates.length})` },
    { value: 'article-writer', label: `Article Writer (${article.length})` }
  ];

  const getCurrentCategoryLabel = () => {
    const currentCategory = categories.find(cat => cat.value === activeTab);
    return currentCategory ? currentCategory.label : 'Select Category';
  };

  return (
    <Container className="main-layout">
      <div className="d-flex justify-content-between header">
      <h4 className="page-title">My Content</h4>  
      <Dropdown className="content-category">
                <Dropdown.Toggle variant="outline-secondary" className="w-100">
                  {getCurrentCategoryLabel()}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  {categories.map(category => (
                    <Dropdown.Item 
                      key={category.value}
                      active={activeTab === category.value}
                      onClick={() => setActiveTab(category.value)}
                    >
                      {category.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              </div> 
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{'height':'80vh'}}>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner> 
                </div>
              ) : (
                renderContent()
              )}  
    </Container>
  );
};

export default MyContent;