import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown } from 'lucide-react';
import openaiIcon from './images/openai.jpeg';
import claudeIcon from './images/claude.jpeg';
import geminiIcon from './images/gemini.jpeg';
import perplexityIcon from './images/perplexity.jpeg';
import mistralIcon from './images/mistral.jpeg';
import llamaIcon from './images/llama.jpeg';
import qwenIcon from './images/qwen.jpeg';
import mythoIcon from './images/mytho.jpeg';

export const AVAILABLE_MODELS = {
  openai: [
    { name: 'gpt-3.5-turbo', level: 'Essential' },
    { name: 'gpt-4o-mini', level: 'Essential' },
    { name: 'gpt-4o', level: 'Advanced' }
  ],
  gemini: [
    { name: 'gemini-1.5-flash', level: 'Essential' },
    { name: 'gemini-1.5-flash-8b', level: 'Essential' },
    { name: 'gemini-1.5-pro', level: 'Advanced' },
    { name: 'gemini-1.0-pro', level: 'Advanced' } 
  ],
  claude: [
    { name: 'claude-3-5-haiku-20241022', level: 'Essential' },
    { name: 'claude-3-sonnet-20240229', level: 'Essential' },
    { name: 'claude-3-haiku-20240307', level: 'Essential' },
    { name: 'claude-3-5-sonnet-20241022', level: 'Advanced' } 
  ],
  perplexity: [
    { name: 'llama-3.1-sonar-small-128k-online', level: 'Essential' }
  ],
  llama: [
    { name: 'Llama-3.2-3B-Instruct-Turbo', level: 'Essential' }, //0.06
    { name: 'Meta-Llama-3.1-8B-Instruct-Turbo', level: 'Advanced' }, //0.18
    { name: 'Llama-3.3-70B-Instruct-Turbo', level: 'Advanced' }, //0.88
    { name: 'Meta-Llama-3.1-70B-Instruct-Turbo', level: 'Advanced' }  //0.88
  ],
  qwen: [
    { name: 'Qwen2.5-7B-Instruct-Turbo', level: 'Advanced' }   
  ],
  mytho: [
    { name: 'MythoMax-L2-13b-Lite', level: 'Essential' }
  ],
  mistral: [
    { name: 'mistral-tiny', level: 'Essential' },
    { name: 'mistral-small-2402', level: 'Essential' },
    { name: 'mistral-medium', level: 'Advanced' },
    { name: 'mistral-large-latest', level: 'Advanced' }
  ]
};

export const getProviderIcon = (provider) => {
  switch (provider) {
    case 'openai': return openaiIcon;
    case 'claude': return claudeIcon;
    case 'gemini': return geminiIcon;
    case 'llama': return llamaIcon;
    case 'perplexity': return perplexityIcon;
    case 'mistral': return mistralIcon;
    case 'qwen': return qwenIcon;
    case 'Qwen2.5': return qwenIcon;
    case 'mytho': return mythoIcon;  
    case 'MythoMax': return mythoIcon; 
    case 'Llama': return llamaIcon;   
    case 'Meta': return llamaIcon;  
    default: return openaiIcon;
  }
};

const ModelSelector = ({ onModelSelect, disabled = false, initialModel = 'gemini-1.5-flash' }) => {
  
  const getInitialProvider = (modelName) => {
    for (const [provider, models] of Object.entries(AVAILABLE_MODELS)) {
      if (models.some(m => m.name === modelName)) {
        return provider;
      }
    }
    return 'gemini';
  };

  const [selectedProvider, setSelectedProvider] = useState(getInitialProvider(initialModel));
  const [selectedModel, setSelectedModel] = useState(initialModel);

    // Initialize with passed model on mount
    useEffect(() => {
      if (initialModel) {
        const provider = getInitialProvider(initialModel);
        setSelectedProvider(provider);
        setSelectedModel(initialModel);
      }
    }, [initialModel]);

  const handleSelect = (eventKey) => {
    const [provider, model] = eventKey.split('|');
    setSelectedProvider(provider);
    setSelectedModel(model);
    onModelSelect(model);
  };

  return (
    <Dropdown onSelect={handleSelect} className="model-selector">
      <Dropdown.Toggle
        variant="light"
        className="w-100 d-flex align-items-center justify-content-between border"
        style={{ backgroundColor: 'white', color:'#000', boxShadow: 'none' }}
        disabled={disabled}
      >
        <div className="d-flex align-items-center">
          <img
            src={getProviderIcon(selectedProvider)}
            alt={`${selectedProvider} icon`}
            className="rounded-circle me-2"
            style={{ width: '24px', height: '24px' }}
          />
          <span>{selectedModel}</span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100" style={{ minWidth:'350px', maxHeight: '400px', overflowX: 'hidden', overflowY: 'auto' }}>
        {Object.entries(AVAILABLE_MODELS).map(([provider, models]) => (
          <div key={provider}>
            <div className="px-3 py-2 text-muted">
              <small className="fw-bold text-uppercase">{provider}</small>
            </div>
            {models.map((model) => (
              <Dropdown.Item
                key={model.name}
                eventKey={`${provider}|${model.name}`}
                active={selectedModel === model.name}
                className="py-2"
              >
                <div className="d-flex align-items-center">
                  <img
                    src={getProviderIcon(provider)}
                    alt={`${provider} icon`}
                    className="rounded-circle me-2"
                    style={{ width: '20px', height: '20px' }}
                  />
                  <div className='ai-model-item'>
                    <div className='ai-model-name'>{model.name}</div>
                    <small className="ai-model-level">
                      {model.level}
                    </small>
                  </div>
                </div>
              </Dropdown.Item>
            ))}
            {provider !== Object.keys(AVAILABLE_MODELS).slice(-1)[0] && (
              <Dropdown.Divider />
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ModelSelector;

// Add this CSS to your stylesheet
const styles = `
.model-selector {
  min-width:280px; 
} 
.model-selector .dropdown-menu {
  max-height: 400px;
  overflow:hidden !important;
  overflow-y: auto; 
}
.model-selector .dropdown-item:active,
.model-selector .dropdown-item.active {
  background-color: #6366f1;
}
  .dropdown-item.active, .dropdown-item:active .text-muted{
    color:#fff;
  }
`;
