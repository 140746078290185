const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class articlePostAIServices {    
    // Base API call helper
    async makeToolRequest(prompt, userId, num = 1, language = 'en', tone = 'professional') {
      try {
        const response = await fetch('/api/tools', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt,
            userId,
            num,
            language,
            tone
          })
        });
  
        if (!response.ok) {
          throw new Error('API request failed');
        }
  
        const data = await response.json();
        return data.responses;
      } catch (error) {
        console.error('Error making API request:', error);
        throw error;
      }
    }
  
    async generateBlogIdeas(formDataObj) {
      const response = await this.makeToolRequest('201', formDataObj.userId, 1, formDataObj.language, 'professional');
      return response[0];
    }
  
    async reGenerateBlogTitles(queryData, blogIdeaValue) {
      const response = await this.makeToolRequest('205', queryData.userId, 1, queryData.language, 'professional');
      return response[0];
    }
  
    async generateBlogIntro(queryData, blogTitle) {
      // Pass blog title as part of the request
      const response = await this.makeToolRequest('212', queryData.userId, 3, queryData.language, 'professional');
      return response;
    }
  
    async reGenerateBlogIntro(queryData, blogTitle) {
      const response = await this.makeToolRequest('212', queryData.userId, 3, queryData.language, 'professional');
      return response;
    }
  
    async generateBlogOutline(queryData, blogTitle) {
      const response = await this.makeToolRequest('202', queryData.userId, 1, queryData.language, 'professional');
      return response[0];
    }
  
    async reGenerateBlogOutline(queryData, blogTitle) {
      const response = await this.makeToolRequest('202', queryData.userId, 1, queryData.language, 'professional');
      return response[0];
    }
  
    async generateAllBlogSections(articlePost) {
      const sectionPromises = articlePost.sections.map(section => 
        this.generateBlogSections({
          userId: articlePost.userId,
          language: articlePost.language
        }, {
          postTitle: articlePost.title,
          postSectionTitle: section.secTitle
        })
      );
      
      return Promise.all(sectionPromises);
    }
  
    async generateBlogSections(queryData, postObj) {
      const response = await this.makeToolRequest('203', queryData.userId, 1, queryData.language, 'professional');
      return response[0];
    }
  
    async reGenerateSectionContent(postTitle, secTitle) {
      const response = await this.makeToolRequest('203', postTitle.userId, 1, 'en', 'professional');
      return response[0];
    }
  
    async writeMoreSectionContent(secTitle, secDesc) {
      // Use the existing content as input for expansion
      const response = await this.makeToolRequest('203', secDesc.userId, 1, 'en', 'professional');
      return response[0];
    }
  
    async reGenerateIntroContent(postTitle) {
      const response = await this.makeToolRequest('212', postTitle.userId, 1, 'en', 'professional');
      return response[0];
    }
  
    async generateNewIntro(postIntro) {
      const response = await this.makeToolRequest('212', postIntro.userId, 1, 'en', 'professional');
      return response[0];
    }
  }
  
  export default new articlePostAIServices();

 