import React, { useState } from 'react';
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col
} from 'react-bootstrap';
import { Youtube, Download, Clock, Video } from 'lucide-react';
import axios from 'axios';

const YouTubeShorts = () => {
  const [url, setUrl] = useState('');
  const [videoInfo, setVideoInfo] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // YouTube URL validation
  const validateYouTubeUrl = (url) => {
    // Specifically check for YouTube Shorts URL format
    const regex = /^(https?:\/\/)?(www\.)?youtube\.com\/shorts\/[a-zA-Z0-9_-]{11}(\S*)?$/;
    return regex.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setVideoInfo(null);

    // Validate URL format first
    if (!validateYouTubeUrl(url)) {
      setError('Please enter a valid YouTube Shorts URL (e.g., https://youtube.com/shorts/VIDEO_ID)');
      setLoading(false);
      return;
    }

    try {
      // Use single encoding for the URL
      const encodedUrl = encodeURIComponent(url);
      const dev = 'https://yt-api-eight.vercel.app/api/youtube/shorts-info';
      const local = 'http://localhost:3001/api/youtube/shorts-info';   
      const response = await axios.get(`${dev}?url=${encodedUrl}`);
      setVideoInfo(response.data);
      if (response.data.formats.length > 0) {
        setSelectedFormat(response.data.formats[0].itag);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to get video information');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      // Validate URL before download
      if (!validateYouTubeUrl(url)) {
        setError('Invalid YouTube URL format');
        return;
      }
      
      const encodedUrl = encodeURIComponent(url);
      
      const dev = 'https://yt-api-eight.vercel.app/api/youtube/shorts/download';
      const local = 'http://localhost:3001/api/youtube/shorts/download'; 
      const downloadUrl = `${dev}?url=${encodedUrl}&itag=${selectedFormat}`;
      window.location.href = downloadUrl;
    } catch (err) {
      setError('Failed to download video');
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <Container className="py-5">
      <Card className="shadow">
        <Card.Header className="bg-primary text-white text-center py-3">
          <h2 className="mb-0 d-flex align-items-center justify-content-center">
            <Youtube size={32} className="me-2" />
            YouTube Shorts Downloader
          </h2>
        </Card.Header>

        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter YouTube Shorts URL..."
                  disabled={loading}
                />
                <Button 
                  type="submit" 
                  variant="primary" 
                  disabled={loading}
                  style={{ minWidth: '120px' }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Get Info'
                  )}
                </Button>
              </div>
            </Form.Group>

            {error && (
              <Alert variant="danger">
                {error}
              </Alert>
            )}

            {videoInfo && (
              <Row className="mt-4">
                <Col md={4}>
                  <img
                    src={videoInfo.thumbnail}
                    alt={videoInfo.title}
                    className="img-fluid rounded"
                  />
                </Col>
                
                <Col md={8}>
                  <h4 className="mb-3">{videoInfo.title}</h4>
                  
                  <div className="d-flex gap-4 mb-3">
                    <div className="d-flex align-items-center text-muted">
                      <Clock size={18} className="me-1" />
                      <span>{formatDuration(videoInfo.duration)}</span>
                    </div>
                    <div className="d-flex align-items-center text-muted">
                      <Video size={18} className="me-1" />
                      <span>Shorts</span>
                    </div>
                  </div>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Format</Form.Label>
                    <Form.Select
                      value={selectedFormat}
                      onChange={(e) => setSelectedFormat(e.target.value)}
                    >
                      {videoInfo.formats.map((format) => (
                        <option key={format.itag} value={format.itag}>
                          {format.quality} - {format.container} ({format.size})
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Button
                    variant="primary"
                    onClick={handleDownload}
                    disabled={!selectedFormat}
                    className="w-100 d-flex align-items-center justify-content-center gap-2"
                  >
                    <Download size={18} />
                    Download Short
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default YouTubeShorts;