import React, { useEffect, useState } from 'react';
import { Container, Spinner, Alert } from 'react-bootstrap';
import SubscriptionManager from './SubscriptionManager';
import PricingModal from './PricingModal';
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from 'react-router-dom';

function SubscriptionPage() {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useUserAuth();

  const fetchSubscription = async () => {
    if (!user?.uid) return;
    try {
      
      const local = `http://localhost:3001/api/subscription/${user.uid}`;
      const dev = `https://wk-server.vercel.app/api/subscription/${user.uid}`;

      const response = await fetch(dev);
      if (!response.ok) {
        throw new Error('Failed to fetch subscription');
      }
      const data = await response.json();
      setSubscription(data);
    } catch (error) {
      console.error('Error fetching subscription:', error);
      setError('Failed to load subscription data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, [user?.uid]);


  const handleManageBilling = async () => {
  if (!user?.uid) {
    setError('User authentication required');
    return;
  }

  try {
    setLoading(true);
    const local = `http://localhost:3001/api/subscription/create-portal-session`;
    const dev = 'https://wk-server.vercel.app/api/subscription/create-portal-session';

    const response = await fetch(dev, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: user.uid })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to access billing portal');
    }
    
    const { url } = await response.json();
    if (url) {
      window.location.href = url;
    } else {
      throw new Error('No portal URL received');
    }
  } catch (error) {
    console.error('Error accessing billing portal:', error);
    setError('Failed to access billing portal. Please try again later. Please reach out to support@wordkraft.ai');
  } finally {
    setLoading(false);
  }
};

  const handleCancelSubscription = async () => {
    try {
      const local = `'http://localhost:3001/api/subscription/cancel-subscription`;
      const dev = `https://wk-server.vercel.app/api/subscription/cancel-subscription`;
      const response = await fetch(dev, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.uid })
      });
      
      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      await fetchSubscription();
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setError('Failed to cancel subscription. Please try again later.');
    }
  };

  const handleUpgrade = () => {
    setShowPricingModal(true);
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ 'height': '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner> 
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <div className="main-layout profile page-layout">
      <div className="header-txt text-center"> 
        <h2>Subscription</h2> 
        <p>Check your Billing details and Subscription Management.</p>
      </div> 
      <div className="container justify-content-center">  
        <Container className="px-5 subscription-page">
          <SubscriptionManager
            subscription={subscription}
            onManageBilling={handleManageBilling}
            onCancelSubscription={handleCancelSubscription}
            onUpgrade={handleUpgrade}
          />
        </Container>
      </div>

      <PricingModal 
        show={showPricingModal} 
        onClose={() => setShowPricingModal(false)} 
      />
    </div>
  );
}

export default SubscriptionPage;