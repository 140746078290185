import Dashboard from './components/dashboard/Dashboard';
import { Navigate } from 'react-router-dom';
import GPTtest from './components/dashboard/GPTtest'; 
import Tools from './components/dashboard/tools/Tools';
import BlogContent from './components/dashboard/tools/BlogContent';
import SocialMedia from './components/dashboard/tools/SocialMedia';
import WebsiteSEO from './components/dashboard/tools/WebsiteSEO';
import Marketing from './components/dashboard/tools/Marketing';
import OtherTools from './components/dashboard/tools/Other';
import Templates from './components/dashboard/templates/Templates';
import BusinessTemplates from './components/dashboard/templates/BusinessTemplates';
import MarketingTemplates from './components/dashboard/templates/MarketingTemplates';
import CareersTemplates from './components/dashboard/templates/CareersTemplates';
import HrTemplates from './components/dashboard/templates/HrTemplates';
import PersonalTemplates from './components/dashboard/templates/PersonalTemplates';
import EmailTemplates from './components/dashboard/templates/EmailTemplates';
import MyContent from './components/dashboard/projects/my-content';
import ProductDescription from './components/tools/product-description/ProductDescription';
import QuickTemplate from './components/tools/quick-template/QuickTemplate';
import AiTools from './components/tools/ai-tools/AiTools';
import Chat from './components/tools/chat/Chat';
import ImageGeneration from './components/imageGen/ImageGeneration'; 
import AgentsPage from './components/aiChat/agent/AgentsPage2';
import AITools from './components/dashboard/AITools';
import BlogPost from './components/tools/blog-post/BlogPost';
import BlogIdeas from './components/tools/blog-ideas/BlogIdeas';
import BlogTitle from './components/tools/blog-title/BlogTitle';
import BlogIntro from './components/tools/blog-intro/BlogIntro';
import BlogOutline from './components/tools/blog-outline/BlogOutline';
import BlogSection from './components/tools/blog-section/BlogSection';
import BlogConclusion from './components/tools/blog-conclusion/BlogConclusion';
import MetaTitle from './components/tools/meta-title/MetaTitle';
import MetaDescription from './components/tools/meta-description/MetaDescription';
// import KeywordResearch from './components/tools/keyword-research/KeywordResearch';
import KeywordAnalysis from './components/tools/keyword-analysis/KeywordAnalysis';
import FacebookAd from './components/tools/facebook-ad/FacebookAd';
import LinkedinAd from './components/tools/linkedin-ad/LinkedinAd';
import InstagramPostIdeas from './components/tools/instagram-post-ideas/InstagramPostIdeas';
import TwitterGenerator from './components/tools/twitter-generator/TwitterGenerator';
import ColdEmail from './components/tools/cold-email/ColdEmail';
import WelcomeEmail from './components/tools/welcome-email/WelcomeEmail';
import FollowUpEmail from './components/tools/follow-up-email/FollowUpEmail';
import Login from './components/Login';
import Signup from './components/Signup';
import Reset from './components/Reset';
import VerifyEmail from './components/VerifyEmail';
import Profile from './components/views/settings/Profile';
import Admin from './components/views/settings/Admin';
import Success from './components/views/Success';
import Cancel from './components/views/Cancel';
import NotFound from './components/views/NotFound';
import ArticlePost from './components/tools/articlePost/ArticlePost';
import InlinePricing from './components/views/InlinePricing';
import AIChat from './components/aiChat/AIChat';
import RecentChats from './components/aiChat/RecentChats';
import PaymentStatus from './components/pricing/PaymentStatus';
import SubscriptionPage from './components/pricing/SubscriptionPage';
import PricingPage from './components/pricing/PricingPage';
import KeywordResearch from './components/aiChat/KeywordResearch';
import YouTubeDownloader from './components/aiChat/YouTubeDownloader';
import YouTubeThumbnail from './components/aiChat/YouTubeThumbnail';
import YouTubeTranscript from './components/aiChat/YouTubeTranscript';
import YouTubeShorts from './components/aiChat/YouTubeShorts';
import YouTubeToMP3 from './components/aiChat/YouTubeToMP3';

const routes = [
  // { path: '/', element: AIChat, protected: false },
  // { path: '/', element: () => <Navigate to="/chat" replace />, protected: false },
  { path: '/', element: AITools, protected: false },
  { path: '/aichat', element: AIChat, protected: false }, 
  { path: '/payment-status', element: PaymentStatus, protected: false },  
  { path: '/aitools', element: AITools, protected: false },
  { path: '/gpttest', element: GPTtest, protected: true },
  { path: '/subscription', element: SubscriptionPage, protected: false }, 
   { path: '/chat', element: AIChat, protected: false },
   { path: '/chat/:chatId', element: AIChat, protected: false }, 
   { path: '/recent-chats', element: RecentChats, protected: false },
   { path: '/keyword-research', element: KeywordResearch, protected: false },

  { path: '/youtube-video-download', element: YouTubeDownloader, protected: false },   
  { path: '/youtube-thumbnail-download', element: YouTubeThumbnail, protected: false },   
  { path: '/youtube-transcript-download', element: YouTubeTranscript, protected: false }, 
  { path: '/youtube-shorts-download', element: YouTubeShorts, protected: false }, 
  { path: '/youtube-mp3-download', element: YouTubeToMP3, protected: false }, 
 
  // { path: '/recent-chats', element: RecentChats, protected: false },
  { path: '/tools', element: Tools, protected: false },
  { path: '/tools/blog-content', element: BlogContent, protected: false },
  { path: '/tools/social-media', element: SocialMedia, protected: false },
  { path: '/tools/website-seo', element: WebsiteSEO, protected: false },
  { path: '/tools/marketing', element: Marketing, protected: false },
  { path: '/tools/other', element: OtherTools, protected: false },
  { path: '/templates', element: Templates, protected: false },
  { path: '/templates/business', element: BusinessTemplates, protected: false },
  { path: '/templates/marketing', element: MarketingTemplates, protected: false },
  { path: '/templates/careers', element: CareersTemplates, protected: false },
  { path: '/templates/hr', element: HrTemplates, protected: false },
  { path: '/templates/personal', element: PersonalTemplates, protected: false },
  { path: '/templates/emails', element: EmailTemplates, protected: false },
  { path: '/my-content', element: MyContent, protected: true },
  { path: '/product-description', element: ProductDescription, protected: false },
  { path: '/product-description/:id', element: ProductDescription, protected: true },
  { path: '/flexy-template', element: QuickTemplate, protected: false },
  { path: '/ai-tools', element: AiTools, protected: false },
  { path: '/image-generation', element: ImageGeneration, protected: true }, 
  { path: '/agents', element: AgentsPage, protected: true },

  // Content Creation Tools
  { path: '/blog-post', element: BlogPost, protected: false },
  { path: '/blog-post/:id', element: BlogPost, protected: true },
  { path: '/blog-ideas', element: BlogIdeas, protected: false },
  { path: '/blog-ideas/:id', element: BlogIdeas, protected: true },
  { path: '/blog-title', element: BlogTitle, protected: false },
  { path: '/blog-title/:id', element: BlogTitle, protected: true },
  { path: '/blog-intro', element: BlogIntro, protected: false },
  { path: '/blog-intro/:id', element: BlogIntro, protected: true },
  { path: '/blog-outline', element: BlogOutline, protected: false },
  { path: '/blog-outline/:id', element: BlogOutline, protected: true },
  { path: '/blog-section', element: BlogSection, protected: false },
  { path: '/blog-section/:id', element: BlogSection, protected: true },
  { path: '/blog-conclusion', element: BlogConclusion, protected: false },
  { path: '/blog-conclusion/:id', element: BlogConclusion, protected: true },

  // SEO Tools
  { path: '/meta-title', element: MetaTitle, protected: false },
  { path: '/meta-title/:id', element: MetaTitle, protected: true },
  { path: '/meta-description', element: MetaDescription, protected: false },
  { path: '/meta-description/:id', element: MetaDescription, protected: true },
  // { path: '/keyword-research', element: KeywordResearch, protected: false },
  // { path: '/keyword-research/:id', element: KeywordResearch, protected: true },
  { path: '/keyword-analysis', element: KeywordAnalysis, protected: true },
  { path: '/keyword-analysis/:id', element: KeywordAnalysis, protected: true },

  // Social Media Tools
  { path: '/facebook-ad', element: FacebookAd, protected: false },
  { path: '/facebook-ad/:id', element: FacebookAd, protected: true },
  { path: '/linkedin-ad', element: LinkedinAd, protected: false },
  { path: '/linkedin-ad/:id', element: LinkedinAd, protected: true },
  { path: '/instagram-post-ideas', element: InstagramPostIdeas, protected: false },
  { path: '/instagram-post-ideas/:id', element: InstagramPostIdeas, protected: true },
  { path: '/twitter-generator', element: TwitterGenerator, protected: false },
  { path: '/twitter-generator/:id', element: TwitterGenerator, protected: true },

  // Email Tools
  { path: '/cold-email', element: ColdEmail, protected: false },
  { path: '/cold-email/:id', element: ColdEmail, protected: true },
  { path: '/welcome-email', element: WelcomeEmail, protected: false },
  { path: '/welcome-email/:id', element: WelcomeEmail, protected: true },
  { path: '/follow-up-email', element: FollowUpEmail, protected: false },
  { path: '/follow-up-email/:id', element: FollowUpEmail, protected: true },

  // Authentication Routes
  { path: '/login', element: Login, protected: false },
  { path: '/login/:type', element: Login, protected: false },
  { path: '/signup', element: Signup, protected: false },
  { path: '/reset', element: Reset, protected: false },
  { path: '/verify-email', element: VerifyEmail, protected: false },
  { path: '/profile', element: Profile, protected: true },
  { path: '/pricing', element: PricingPage, protected: true },
  { path: '/admin', element: Admin, protected: true },

  { path: '/article-writer', element: ArticlePost, protected: true },
  { path: '/article-writer/:id', element: ArticlePost, protected: true },

  // Other Routes
  { path: '/success', element: Success, protected: false },
  { path: '/success/:id', element: Success, protected: false },
  { path: '/cancel', element: Cancel, protected: false },
  { path: '*', element: NotFound, protected: false }
];

export default routes;