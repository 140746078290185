import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../../firebase';
import { AVAILABLE_MODELS } from './ModelSelector';

class CreditService {
  constructor() {
    this.subscriptionCollection = 'subscription';
  }

  getModelLevel(modelName) {
    // Search through all providers and their models
    for (const provider of Object.values(AVAILABLE_MODELS)) {
      const model = provider.find(m => m.name === modelName);
      if (model) {
        return model.level.toLowerCase(); // Convert to lowercase to match credit types
      }
    }
    return 'essential'; // Default to essential if model not found
  }

  async validateAndDeductCredits(userId, type, model, refreshCallback) {
    if (!userId) {
      throw new Error('User ID is required');
    }
  
    try {
      // Get current subscription document
      const subscriptionRef = doc(db, this.subscriptionCollection, userId);
      const subscriptionSnap = await getDoc(subscriptionRef);
  
      if (!subscriptionSnap.exists()) {
        throw new Error('No subscription found');
      }
  
      const subscriptionData = subscriptionSnap.data();
      const credits = subscriptionData.credits || {};
  
      // Validate credits based on type
      if (type === 'chat') {
        const modelLevel = this.getModelLevel(model);
        const availableCredits = credits[modelLevel] || 0;
  
        if (availableCredits < 1) {
          throw new Error(`Insufficient ${modelLevel} credits`);
        }
  
        // Deduct 1 credit from the appropriate level
        await updateDoc(subscriptionRef, {
          [`credits.${modelLevel}`]: increment(-1)
        });
      } 
      else if (type === 'image') {
        const availableCredits = credits.images || 0;
  
        if (availableCredits < 1) {
          throw new Error('Insufficient image credits');
        }
  
        await updateDoc(subscriptionRef, {
          'credits.images': increment(-1)
        });
      }
  
      // Call refresh callback if provided
      if (typeof refreshCallback === 'function') {
        await refreshCallback();
      }
  
      // Get and return updated data
      const updatedSnapshot = await getDoc(subscriptionRef);
      return updatedSnapshot.data();
    } catch (error) {
      console.error('Error managing credits:', error);
      throw error;
    }
  }

  async checkModelAvailability(userId, modelName) {
    if (!userId || !modelName) {
      return false;
    }

    try {
      const subscriptionRef = doc(db, this.subscriptionCollection, userId);
      const subscriptionSnap = await getDoc(subscriptionRef);

      if (!subscriptionSnap.exists()) {
        return false;
      }

      const credits = subscriptionSnap.data().credits || {};
      const modelLevel = this.getModelLevel(modelName);
      
      return (credits[modelLevel] || 0) > 0;
    } catch (error) {
      console.error('Error checking model availability:', error);
      return false;
    }
  }
}

export default new CreditService();