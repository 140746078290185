import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ThemeProvider } from './components/aiChat/ThemeContext';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { LogoSvg } from "./assets/svg/SvgIcons";
import logoSmall from './components/aiChat/images/wordkraft-logo-icon.png';
import routes from './routes';
import ProtectedRoute from './components/ProtectedRoute';
import { FavoritesProvider } from './context/FavoritesContext';
import { SidebarProvider } from './context/SidebarContext';
import { SubscriberContext } from './context/subscriberContext';
import Sidebar from './components/aiChat/Sidebar';
import * as chatService from './components/aiChat/AIChatServices';
import useSubscription from './hooks/useSubscription';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppHeader from './AppHeader';  
import "./App.css";
import "./components/aiChat/theme.css";

const stripePromise = loadStripe("pk_live_51Kzc9vSJsVlrtsJgpAfNUX5BUXA8Rb3uKyIEFtFaSibM5rNeaXVRvCOiOXBic7GN2MD4SGliDtWJ8kZT5R6HhNz800iag3rG8U");

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, subscriber, setSubscriber } = useSubscription();

  // Sidebar states
  const [expanded, setExpanded] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Chat states
  const [recentChats, setRecentChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [error, setError] = useState(null);
  const [isNewChat, setIsNewChat] = useState(false);
  const [loading, setLoading] = useState(false);
  
  // Handle mobile responsiveness
  // useEffect(() => {
  //   const handleResize = () => {
  //     const isMobileView = window.innerWidth < 768;
  //     setIsMobile(isMobileView);
  //     setShowSidebar(!isMobileView);
  //     if (isMobileView && expanded) {
  //       setExpanded(false);
  //     }
  //   };
  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [expanded]);

  // Load current chat from URL
  useEffect(() => {
    const loadChatFromUrl = async () => {
      // Reset error state at the start of each load attempt
      setError(null);
      
      // Parse chat ID from URL
      const pathParts = window.location.pathname.split('/');
      const urlChatId = pathParts[pathParts.length - 1];
      
      // Early returns for invalid states
      if (!location.pathname.startsWith('/chat/')) {
        return;
      }
  
      if (!urlChatId || urlChatId === 'chat' || !user) {
        return;
      }
  
      // Set loading state
      setLoading(true);
  
      try {
        // Validate chat ID format before making the request
        if (!/^[a-zA-Z0-9-_]+$/.test(urlChatId)) {
          throw new Error('Invalid chat ID format');
        }
  
        // Check user authentication
        if (!user.uid) {
          throw new Error('User not authenticated');
        }
  
        const chat = await chatService.fetchChatById(urlChatId, user.uid);
        
        // Validate chat data
        if (!chat) {
          throw new Error('Chat not found');
        }
  
        if (!chat.id || !chat.metadata) {
          throw new Error('Invalid chat data structure');
        }
  
        // Set chat data if everything is valid
        setSelectedChat(chat);
        setIsNewChat(false);
  
      } catch (error) {
        console.error('Error loading chat:', error);
        
        // Handle specific error cases
        switch (error.message) {
          case 'Chat not found':
          case 'Chat not found or unauthorized':
            setError('This chat is not available or you don\'t have permission to access it');
            break;
          case 'User not authenticated':
            setError('Please sign in to access this chat');
            break;
          case 'Invalid chat ID format':
            setError('Invalid chat ID');
            break;
          case 'Invalid chat data structure':
            setError('Chat data is corrupted or invalid');
            break;
          default:
            setError('Failed to load chat. Please try again.');
        }
  
        // Reset states and redirect
        setSelectedChat(null);
        setIsNewChat(false);
        
        // Only navigate if we're still on a chat route
        if (location.pathname.startsWith('/chat/')) {
          navigate('/chat', { replace: true });
        }
  
      } finally {
        // Always reset loading state
        setLoading(false);
      }
    };
  
    // Debounce the load chat function to prevent rapid successive calls
    const debounceTimeout = setTimeout(() => {
      if (user) {
        loadChatFromUrl();
      } else {
        // Reset states if no user
        setSelectedChat(null);
        setIsNewChat(false);
        setError(null);
        setLoading(false);
      }
    }, 300);
  
    // Cleanup function
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [user, navigate, location.pathname]);

  const isChatPage = () => {
    return location.pathname === '/' || location.pathname === '/chat' || location.pathname.startsWith('/chat/');
  };

  // Fetch chats
  useEffect(() => {
    if (!user) {
      setChats([]);
      setRecentChats([]);
      setSelectedChat(null);
      return;
    }

    const unsubscribe = chatService.listenToChats(
      user.uid,
      (allChats) => {
        setChats(allChats);
        setRecentChats(allChats.slice(0, 10));
      },
      setError
    );

    return () => unsubscribe();
  }, [user]);

 // Handle mobile responsiveness
 useEffect(() => {
  const handleResize = () => {
    const isMobileView = window.innerWidth < 768;
    setIsMobile(isMobileView);
    
    // Set default states based on view
    if (isMobileView) {
      setExpanded(false);
      setShowSidebar(false);
    } else {
      setExpanded(true);
      setShowSidebar(true);
    }
  };
  
  handleResize(); // Run on mount
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  // Handle sidebar link clicks
  const handleSidebarAction = () => {
    if (isMobile) {
      setExpanded(false);
      setShowSidebar(false);
    }
  };

  const handleNewChat = () => {
    // Clear all chat-related state
    setSelectedChat(null);
    setIsNewChat(true);
    navigate('/chat');
    handleSidebarAction();
    if (isMobile) setShowSidebar(false);  
    // Clear any existing messages in AIChat
    window.dispatchEvent(new CustomEvent('clearChat'));
  };
  
  const handleChatSelect = (chat) => {
    setIsNewChat(false);
    setSelectedChat(chat);
    navigate(`/chat/${chat.id}`);
    handleSidebarAction();
    if (isMobile) setShowSidebar(false);
  };
  
  const handleLogoClick = () => {
    navigate('/');
    handleSidebarAction();
  }

  const handleDeleteChat = async (chatId, e) => {
    e?.stopPropagation();
    try {
      await chatService.deleteChat(chatId, user.uid);
      if (selectedChat?.id === chatId) {
        setSelectedChat(null);
        setIsNewChat(false);
        navigate('/chat');
      }
    } catch (error) {
      console.error('Delete chat error:', error);
      if (error.message === 'Unauthorized to delete this chat') {
        setError('You don\'t have permission to delete this chat');
      } else {
        setError('Failed to delete chat. Please try again.');
      }
    }
};

  const handleViewAllChats = () => {
    navigate('/recent-chats'); // Updated to match your routes.js
    handleSidebarAction();
  };

  // if (!user) {
  //   return (
  //     <div className="chat-container">
  //       <div className="empty-state">
  //         Please sign in to use the chat
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <Elements stripe={stripePromise}>
    <SubscriberContext.Provider value={{ subscriber, setSubscriber }}>
    <ThemeProvider>
      <SidebarProvider>
          <Container fluid className="layout-container">
            <FavoritesProvider userId={user?.uid || "default"}>
              <div className="layout-row">
              <AppHeader   
                 className={`${expanded ? 'expanded' : 'collapsed'} ${isChatPage() ? 'chat-window' : ''}`} 
                  expanded={expanded}
                  onToggleSidebar={() => setExpanded(!expanded)}
                  logoSmall={logoSmall}
                />
                <div className={`sidebar ${expanded ? 'expanded' : 'collapsed'}`}>
                  {/* <div className="sidebar-header">
                  <div className="logo-container" onClick={handleLogoClick}>
                      {expanded ? (
                        <LogoSvg className="sidebar-logo" />
                      ) : (
                        <img 
                          src={logoSmall} 
                          alt="Wordkraft" 
                          className="sidebar-logo-small"
                        />
                      )}
                    </div>
                    <button 
                      className="sidebar-toggle"
                      onClick={() => setExpanded(!expanded)}
                      aria-label={expanded ? 'Collapse sidebar' : 'Expand sidebar'}
                    >
                      {expanded ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
                    </button>
                  </div> */}

                  {expanded && (
                    <div className="sidebar-content">
                      <Sidebar 
                        showSidebar={showSidebar}
                        recentChats={recentChats}
                        selectedChat={selectedChat}
                        onNewChat={handleNewChat}
                        onChatSelect={handleChatSelect}
                        onDeleteChat={handleDeleteChat}
                        totalChats={chats.length}
                        isNewChat={isNewChat}
                        onViewAllChats={handleViewAllChats}
                      />
                    </div>
                  )}
                </div>

                <div className={`scrollable-body main-container ${expanded ? 'expanded' : 'collapsed'}`}>
                  <div className="container-wrapper">
                    <Routes>
                      {routes.map(route => (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={
                            route.protected ? (
                              <ProtectedRoute>
                                <route.element />
                              </ProtectedRoute>
                            ) : (
                              <route.element />
                            )
                          }
                        />
                      ))}
                    </Routes>
                  </div>
                </div>
              </div>
            </FavoritesProvider>
          </Container>
      </SidebarProvider>
      </ThemeProvider>  
    </SubscriberContext.Provider>
    </Elements>
  );
}

export default App;