import React, { useState } from 'react';
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col
} from 'react-bootstrap';
import { Youtube, Download } from 'lucide-react';
import axios from 'axios';

const YouTubeThumbnail = () => {
  const [url, setUrl] = useState('');
  const [thumbnails, setThumbnails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setThumbnails(null);

    try {
      // Use the correct API endpoint as defined in thumbnail-api.js
      const dev = 'https://yt-api-eight.vercel.app/api/youtube/thumbnail';
      const local = 'http://localhost:3001/api/youtube/thumbnail';  
      const response = await axios.get(`${dev}?url=${encodeURIComponent(url)}`); 
      setThumbnails(response.data.thumbnails);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to get thumbnails');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (quality) => {
    try {
      const dev = 'https://yt-api-eight.vercel.app/api/youtube/thumbnail';
      const local = 'http://localhost:3001/api/youtube/thumbnail';  
      // Use the correct download endpoint
      window.location.href = `${dev}/download?url=${encodeURIComponent(url)}&quality=${quality}`;
    } catch (err) {
      setError('Failed to download thumbnail');
    }
  };

  const qualityLabels = {
    maxres: 'Maximum Resolution (1920x1080)',
    high: 'High Quality (1280x720)',
    medium: 'Medium Quality (640x480)',
    standard: 'Standard Quality (480x360)',
    default: 'Default Quality (120x90)'
  };

  return (
    <Container className="py-5">
      <Card className="shadow">
        <Card.Header className="bg-primary text-white text-center py-3">
          <h2 className="mb-0 d-flex align-items-center justify-content-center">
            <Youtube size={32} className="me-2" />
            YouTube Thumbnail Downloader
          </h2>
        </Card.Header>

        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter YouTube URL..."
                  disabled={loading}
                />
                <Button 
                  type="submit" 
                  variant="primary" 
                  disabled={loading}
                  style={{ minWidth: '120px' }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Get Thumbnails'
                  )}
                </Button>
              </div>
            </Form.Group>

            {error && (
              <Alert variant="danger">
                {error}
              </Alert>
            )}

            {thumbnails && (
              <Row className="mt-4 g-4">
                {Object.entries(thumbnails).map(([quality, url]) => (
                  <Col key={quality} md={6}>
                    <Card>
                      <Card.Img variant="top" src={url} alt={`${quality} thumbnail`} />
                      <Card.Body>
                        <p className="fw-bold mb-3">{qualityLabels[quality]}</p>
                        <Button
                          variant="primary"
                          onClick={() => handleDownload(quality)}
                          className="w-100 d-flex align-items-center justify-content-center gap-2"
                        >
                          <Download size={16} />
                          Download {quality}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default YouTubeThumbnail;