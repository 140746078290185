import { db } from "../../../../firebase"; 
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc, 
  doc,
  arrayUnion,
  orderBy,
  setDoc,
  query, startAfter, limit as limitQuery 
} from "firebase/firestore";   
//const uid = localStorage.getItem("user");
//console.log(uid);
//import { collection, query, getDocs, startAfter, limit as limitQuery } from 'firebase/firestore';
class AIToolsDataService {   

 
  addProject = (uid, newProject) => {
   // console.log(newProject)    
    const projectRef = collection(db, `projects/${uid}/aiTools/`)
    return addDoc(projectRef, newProject);  
  } 
  updateProject = (uid, updateProject) => {
    console.log(updateProject.id)    
    const projectRef = doc(db, `projects/${uid}/aiTools/${updateProject.id}/`)
    return updateDoc(projectRef, {text: updateProject.text});  
  } 
  updateProjectId = (uid, pId) => {    
    const projectIdRef = doc(db, `projects/${uid}/aiTools/${pId}/`)
    updateDoc(projectIdRef, {id: pId});  
  }

  addProjectContent = (uid, newObj) => {
    const textObj = {text: newObj.text, projectId: newObj.projectId}  
    const projectCountRef = doc(db, `projects/${uid}/aiTools/${newObj.projectId}/`)
    updateDoc(projectCountRef, {files: newObj.files});  

    const projectRef = collection(db, `projects/${uid}/aiTools/${newObj.projectId}/content/`)
    return addDoc(projectRef, textObj);   
    //  const projectRef = doc(db, `userData/${uid}/projects/${newObj.projectId}`)
    //   updateDoc(projectRef, {content: arrayRemove({text: 'greater_virginia'})}); 
    //  return updateDoc(projectRef, {content: arrayUnion({text: 'greater_coral'})}); 
  }  

  getProjectData = (uid, pId) => {
    const projectRef = doc(db, `projects/${uid}/aiTools/${pId}/`); 
    return getDoc(projectRef)
  }

  removeContent = (uid, remObj) => { 
    const projectCountRef = doc(db, `projects/${uid}/aiTools/${remObj.projectId}/`)
    updateDoc(projectCountRef, {files: remObj.files});  
    const projectRef = doc(db, `projects/${uid}/aiTools/${remObj.projectId}/content/${remObj.id}`); 
    return deleteDoc(projectRef)
  }

  removeDocContent = (uid, remObj) => { 
    const projectCountRef = doc(db, `projects/${uid}/aiTools/${remObj.projectId}/`)
    updateDoc(projectCountRef, {files: remObj.files});  
    const projectRef = doc(db, `projects/${uid}/aiTools/${remObj.projectId}/content/${remObj.id}`); 
     deleteDoc(projectRef)
    const projRef = doc(db, `projects/${uid}/aiTools/${remObj.projectId}`); 
    return deleteDoc(projRef)
  }

  updateProjectContent = (uid, upObj) => { 
   // //console.log(upObj)
    const projectRef = doc(db, `projects/${uid}/aiTools/${upObj.projectId}/content/${upObj.id}`); 
    return updateDoc(projectRef, {text: upObj.text})
  } 

  // getAllProjectsBatch = (uid, startAfter, limit = 20) => {
  //   const projectRef = collection(db, `projects/${uid}/aiTools/`);
  //   let queryRef = query(projectRef, orderBy('creationDate'), limit(limit));
  
  //   if (startAfter) {
  //     queryRef = query(projectRef, orderBy('creationDate'), startAfter(startAfter), limit(limit));
  //   }  
  //   return getDocs(queryRef);
  // };
  
  getAllProjects = async (uid) => {
    const projectRef = collection(db, `projects/${uid}/aiTools/`);
    const baseQuery = query(projectRef, orderBy('creationDate', 'desc'));
    
    try {
      let allDocs = [];
      const querySnapshot = await getDocs(baseQuery);
      
      querySnapshot.forEach((doc) => {
        allDocs.push({ ...doc.data(), id: doc.id });
      });
      
      return {
        docs: allDocs.map(doc => ({
          data: () => doc,
          id: doc.id
        }))
      };
    } catch (error) {
      console.error("Error fetching all projects:", error);
      throw error;
    }
  }

  // getAllProjects = (uid) => {  
  //   const projectRef = collection(db, `projects/${uid}/aiTools/`)
  //   return getDocs(projectRef); 
  // } 
  getAllProjectContent = (uid, prjId) => {    
    const projectRef = collection(db, `projects/${uid}/aiTools/${prjId}/content/`); 
    return getDocs(projectRef)
  }
  deleteProjectContent = async (uid, prjID) => { 
      if(prjID){   
        const contentData = await this.getAllProjectContent(uid, prjID);  
        contentData.docs.map(file => {
           //console.log(file.id);  
           const projectRef = doc(db, `projects/${uid}/aiTools/${prjID}/content/${file.id}`); 
           deleteDoc(projectRef)
        })
        const projRef = doc(db, `projects/${uid}/aiTools/${prjID}`); 
        deleteDoc(projRef)
     }   
  }
  // addFavourites = (uid, newFav) => {
  //   console.log(newFav)   
  //   const projectRef = collection(db, `userData/${uid}/favourites/`)
  //   return addDoc(projectRef, newFav);  
  // } 
  toggleFavourite = async (uid, favItem) => {
    //console.log("Attempting to update favourite:", favItem);

    const favouritesDocRef = doc(db, `userData/${uid}/favourites`, 'favouriteList');

    try {
        const docSnap = await getDoc(favouritesDocRef);

        if (docSnap.exists()) {
            const favourites = docSnap.data().favourites || [];
            const favIndex = favourites.findIndex(item => item.toolPrompt === favItem.toolPrompt);

            if (favIndex !== -1) { // Found in favourites
                const updatedFavourites = [...favourites];
                updatedFavourites.splice(favIndex, 1); // Remove the item
                await updateDoc(favouritesDocRef, { favourites: updatedFavourites });
               // console.log("Favourite removed:", favItem);
            } else { // Not found, add it
                await updateDoc(favouritesDocRef, {
                    favourites: arrayUnion(favItem)
                });
              //  console.log("Favourite added:", favItem);
            }
        } else {
            await setDoc(favouritesDocRef, { favourites: [favItem] });
          //  console.log("Favourite list created with item:", favItem);
        }
    } catch (error) {
        console.error("Error updating favourite: ", error);
    }
};

// AiTools.services.js
getFavorites = async (uid) => {
 // console.log(uid)
  const favouritesDocRef = doc(db, `userData/${uid}/favourites`, 'favouriteList');
  try {
      const docSnap = await getDoc(favouritesDocRef);
      if (docSnap.exists()) {
        // In AiTools.services.js, inside the getFavorites function
//console.log("Fetched favorites:", docSnap.data().favourites);

          return docSnap.data().favourites || [];
      } else {
          return []; // Return empty array if no favorites
      }
  } catch (error) {
      console.error("Error fetching favourites: ", error);
      return [];
  }
};


}

export default new AIToolsDataService();