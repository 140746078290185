import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { LogoSvg, MyContentIcon } from "./assets/svg/SvgIcons";
import { ChevronLeft, ChevronRight, Sun, Moon, AlignLeft } from 'lucide-react';
import { useTheme } from './components/aiChat/ThemeContext'; 

const AppHeader = ({ 
  expanded,
  onToggleSidebar,
  logoSmall,
  className = ''
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleTheme } = useTheme();
  
  const handleLogoClick = () => {
    navigate('/');
  };

  const isAiToolsPage = location.pathname.includes('ai-tools');

  return (
    <header className={`app-header ${className}`}>
      <div className="header-content">
        <div className="header-left">
          <div className="logo-container d-flex" >
              <div className='cursor-pointer' onClick={handleLogoClick}>
                  {expanded ? (
                  <LogoSvg className="header-logo" />
                ) : (
                  <img 
                    src={logoSmall} 
                    alt="Wordkraft" 
                    className="header-logo-small"
                  />
                )}
              </div>
               <button 
            className="sidebar-toggle"
            onClick={onToggleSidebar}
            aria-label={expanded ? 'Collapse sidebar' : 'Expand sidebar'}
          >
            {expanded ? <AlignLeft size={16} /> : <AlignLeft size={16} />}
          </button>
          </div>
       
        </div>
        
        <div className="header-right">
    
        </div>

        <div className="header-right">
        {isAiToolsPage && (
            <Link to="/my-content" className="header-link">
              <MyContentIcon size={16}/>
              <span>My content</span>
            </Link>
          )}
          <button 
            className="theme-toggle-btn"
            onClick={toggleTheme}
            aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            {isDarkMode ? (
              <Sun size={18} className="theme-icon" />
            ) : (
              <Moon size={18} className="theme-icon" />
            )}
          </button>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;