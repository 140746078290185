import { db } from "../firebase";
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  increment,
  serverTimestamp 
} from "firebase/firestore";

class SubscriptionService {
  constructor() {
    this.collection = 'subscription';

    this.defaultCredits = {
      free: {
        essential: 5,
        advanced: 3,
        images: 2
      }
    };

    // Life Time Plan credits configuration
    this.lifetimePlanCredits = {
      advanced: 25,
      essential: 100,
      images: 10
    };
  }

  // Add this method for checking credit availability
  async checkCreditsAvailability(userId, modelLevel, requiredCredits = 1) {
    if (!userId) return false;
  
    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const subscriptionDoc = await getDoc(subscriptionRef);
  
      if (!subscriptionDoc.exists()) {
        return false;
      }
  
      const data = subscriptionDoc.data();
      // Normalize both the modelLevel and creditType to lowercase for comparison
      const normalizedModelLevel = modelLevel.toLowerCase();
      const creditType = normalizedModelLevel === 'advanced' ? 'advanced' : 'essential';
      const availableCredits = data.credits?.[creditType] || 0;
      
      console.log('Credit Check:', {
        modelLevel,
        normalizedModelLevel,
        creditType,
        availableCredits,
        requiredCredits
      });
      
      return availableCredits >= requiredCredits;
    } catch (error) {
      console.error('Error checking credits:', error);
      return false;
    }
  }

  // Add this method for updating credits after usage
  async updateCreditsAfterUsage(userId, usageData) {
    if (!userId || !usageData?.credits) return;
    console.log(userId)
    console.log(usageData)
    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const updates = {};
  
      // Normalize credit types and ensure we're using the correct case
      // Deduct essential credits if used
      if (usageData.credits.essential > 0 || usageData.credits.Essential > 0) {
        const essentialCredits = usageData.credits.essential || usageData.credits.Essential;
        console.log(essentialCredits)
        updates['credits.essential'] = increment(-essentialCredits);
      }
  
      // Deduct advanced credits if used
      if (usageData.credits.advanced > 0 || usageData.credits.Advanced > 0) {
        const advancedCredits = usageData.credits.advanced || usageData.credits.Advanced;
        updates['credits.advanced'] = increment(-advancedCredits);
      }
  
      console.log('Updating Credits:', {
        original: usageData.credits,
        updates
      });
  
      if (Object.keys(updates).length > 0) {
        updates.lastUpdated = serverTimestamp();
        await updateDoc(subscriptionRef, updates);
      }
  
      return true;
    } catch (error) {
      console.error('Error updating credits:', error);
      throw error;
    }
  }

  async initializeSubscription(userId) {
    if (!userId) return null;

    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (!subscriptionDoc.exists()) {
        const defaultData = {
          planId: 'free',
          status: 'active', //expired, canceled
          credits: this.defaultCredits.free,
          createdAt: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          lastCreditRefresh: serverTimestamp()
        };

        await setDoc(subscriptionRef, defaultData);
        return defaultData;
      }

      return subscriptionDoc.data();
    } catch (error) {
      console.error('Error initializing subscription:', error);
      throw error;
    }
  }

  async getSubscription(userId) {
    if (!userId) return null;

    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (!subscriptionDoc.exists()) {
        return await this.initializeSubscription(userId);
      }

      return subscriptionDoc.data();
    } catch (error) {
      console.error('Error getting subscription:', error);
      throw error;
    }
  }

  async updateCredits(userId, creditType, amount) {
    if (!userId || !creditType) return false;

    try {
      const subscriptionRef = doc(db, this.collection, userId);
      await updateDoc(subscriptionRef, {
        [`credits.${creditType}`]: increment(amount),
        lastUpdated: serverTimestamp()
      });

      return true;
    } catch (error) {
      console.error('Error updating credits:', error);
      throw error;
    }
  }

  // Add method to migrate Life Time Plan user
  async migrateLifeTimePlan(userId) {
    if (!userId) return null;
    
    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const existingSubscription = await getDoc(subscriptionRef);
      
      // Get user data
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      
      if (!userDataDoc.exists()) {
        return null;
      }
      
      const userData = userDataDoc.data();
      
      // Only proceed if it's a Life Time Plan
      if (userData.planType !== "Life Time Plan") {
        return null;
      }
      
      // Calculate next refresh date (1st of next month)
      const now = new Date();
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      
      // Prepare subscription data
      const subscriptionData = {
        planId: 'lifetime',
        status: 'active',
        credits: this.lifetimePlanCredits,
        createdAt: existingSubscription.exists() ? existingSubscription.data().createdAt : serverTimestamp(),
        lastUpdated: serverTimestamp(),
        lastCreditRefresh: serverTimestamp(),
        nextCreditRefreshDate: nextMonth,
        isLifetimePlan: true,
        planType: userData.planType,
        planStartDate: userData.planStartDate || serverTimestamp(),
        planEndDate: userData.planEndDate || null,
        migratedFromOldSystem: true,
        migratedAt: serverTimestamp()
      };

      if (!existingSubscription.exists()) {
        await setDoc(subscriptionRef, subscriptionData);
      } else {
        await updateDoc(subscriptionRef, {
          ...subscriptionData,
          lastUpdated: serverTimestamp()
        });
      }

      // Mark as migrated in userData
      await updateDoc(userDataRef, {
        subscriptionMigrated: true,
        migrationDate: serverTimestamp()
      });

      return subscriptionData;
    } catch (error) {
      console.error('Error migrating Life Time Plan user:', error);
      throw error;
    }
  }

  // Refresh credits for Life Time Plan
  async refreshLifeTimePlanCredits(userId) {
    if (!userId) return false;

    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (!subscriptionDoc.exists()) {
        return false;
      }

      const data = subscriptionDoc.data();
      
      // Only proceed if it's a lifetime plan
      if (!data.isLifetimePlan) {
        return false;
      }

      const now = new Date();
      const nextRefreshDate = data.nextCreditRefreshDate?.toDate() || new Date(now.getFullYear(), now.getMonth() + 1, 1);

      // Check if it's time to refresh credits
      if (now >= nextRefreshDate) {
        // Calculate next refresh date (1st of next month)
        const newNextRefreshDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        
        await updateDoc(subscriptionRef, {
          credits: this.lifetimePlanCredits,
          lastCreditRefresh: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          nextCreditRefreshDate: newNextRefreshDate
        });
        
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error refreshing Life Time Plan credits:', error);
      throw error;
    }
  }

  async refreshDailyCredits(userId) {
    if (!userId) return false;

    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (!subscriptionDoc.exists()) {
        return false;
      }

      const data = subscriptionDoc.data();

      // If it's a Life Time Plan, use the monthly refresh logic instead
      if (data.isLifetimePlan) {
        return await this.refreshLifeTimePlanCredits(userId);
      }

      const lastRefresh = data.lastCreditRefresh?.toDate() || new Date(0);
      const now = new Date();
      const timeDiff = now - lastRefresh;
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff >= 1) {
        const defaultCredits = this.defaultCredits[data.planId || 'free'];
        await updateDoc(subscriptionRef, {
          credits: defaultCredits,
          lastCreditRefresh: serverTimestamp(),
          lastUpdated: serverTimestamp()
        });
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error refreshing credits:', error);
      throw error;
    }
  }

  // Modified migrateOldSubscription to include Life Time Plan
  async migrateOldSubscription(userId) {
    if (!userId) return null;
    console.log('Migration check starting for user:', userId);  
    try {
      // First, check for Life Time Plan
      const userDataRef = doc(db, 'userData', userId);
      const userDataDoc = await getDoc(userDataRef);
      
      if (userDataDoc.exists()) {
        const userData = userDataDoc.data();
        if (userData.planType === "Life Time Plan") {
          // Migrate the Life Time Plan user
          const migrationResult = await this.migrateLifeTimePlan(userId);
          if (migrationResult) {
            // Check if credits need to be refreshed
            await this.refreshLifeTimePlanCredits(userId);
          }
          return migrationResult;
        }
      }

      // Check if user already has a subscription with stripeSubscriptionId
      const subscriptionRef = doc(db, this.collection, userId);
      const existingSubscription = await getDoc(subscriptionRef);
      
      if (existingSubscription.exists()) {
        const subData = existingSubscription.data();
        if (subData.stripeSubscriptionId) {
          console.log('Skipping migration: User already has subscription with stripeSubscriptionId');
          return null;
        }
      }
      
      if (!userDataDoc.exists()) {
        console.log('No userData document found');
        return null;
      }
  
      const userData = userDataDoc.data();
      const currentDate = Math.floor(Date.now() / 1000);
      let planEndTimestamp = parseInt(userData.planEndDate, 10);
  
      if (
        userData.premiumState === true && 
        !userData.subscriptionMigrated && 
        !isNaN(planEndTimestamp) && 
        planEndTimestamp > currentDate &&
        (userData.planType === "Unlimited Plan" || userData.planType === "Professional Plan")
      ) {
        const currentPeriodStart = new Date(parseInt(userData.planStartDate, 10) * 1000);
        const currentPeriodEnd = new Date(parseInt(userData.planEndDate, 10) * 1000);

        let subscriptionData = {
          ...(userData.planType === "Unlimited Plan" ? {
            planId: 'pro',
            credits: {
              essential: -1,
              advanced: 1500,
              images: 300
            }
          } : {
            planId: 'starter',
            credits: {
              essential: 1500,
              advanced: 300,
              images: 100
            }
          }),
          status: 'active',
          migratedFromOldSystem: true,
          migratedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          lastCreditRefresh: serverTimestamp(),
          currentPeriodStart, 
          currentPeriodEnd, 
        };     
  
        if (userData.planType) {
          subscriptionData.oldPlanType = userData.planType;
        }
  
        if (userData.current) {
          subscriptionData.oldStripeSubscriptionId = userData.current;
        }
  
        await setDoc(subscriptionRef, subscriptionData);
  
        await updateDoc(userDataRef, {
          subscriptionMigrated: true,
          migrationDate: serverTimestamp()
        });
  
        return subscriptionData;
      }
  
      return null;
    } catch (error) {
      console.error('Error migrating old subscription:', {
        error,
        errorMessage: error.message,
        stack: error.stack
      });
      return null;
    }
  }

  async updateSubscriptionOnRenewal(userId, subscriptionData) {
    if (!userId) return null;

    try {
      const subscriptionRef = doc(db, this.collection, userId);
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (subscriptionDoc.exists()) {
        const subData = subscriptionDoc.data();
        if (subData.stripeSubscriptionId) {
          console.log('Skipping renewal process: User already has subscription with stripeSubscriptionId');
          return null;
        }
      }

      if (!subscriptionDoc.exists()) {
        await setDoc(subscriptionRef, {
          ...subscriptionData,
          migratedFromOldSystem: true,
          migrationDate: serverTimestamp()
        });
        console.log('Created new subscription for old subscriber:', userId);
      } else {
        await updateDoc(subscriptionRef, {
          credits: subscriptionData.credits,
          lastUpdated: serverTimestamp(),
          lastCreditRefresh: serverTimestamp(),
          renewalDate: subscriptionData.renewalDate
        });
        console.log('Updated existing subscription for old subscriber:', userId);
      }

      return true;
    } catch (error) {
      console.error('Error updating subscription on renewal:', error);
      throw error;
    }
  }
}

export default new SubscriptionService();