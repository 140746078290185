// pricingPlans.js
export const usdPlans = {
    starter: {
      name: 'Starter',
      id: 'starter',
      description: 'Ideal for beginners looking to explore AI capabilities',
      price: {
        monthly: 9,
        yearly: 84,
        monthlyOld: 15,
        yearlyOld: 108,
        monthlyPriceId: '', 
        yearlyPriceId: ''
      },
      features: [
        {
          text: '1,500 Essential Messages / month',
          caption: 'Generate around 7,50,000 words',
          subtext: 'GPT-4o-mini, Gemini Flash, and more',
          hasTooltip: true
        },
        {
          text: '300 Advanced Messages / month',
          caption: 'Generate around 1,50,000 words',
          subtext: 'GPT-4o, Claude 3.5, Gemini 1.5 and more',
          hasTooltip: true
        },
        {
          text: '100 Image Generations / month',
          subtext: 'Flux and Stable Diffusion'
        }
      ],
      tag: ''
    },
    pro: {
      name: 'Pro',
      id: 'pro',
      description: 'Perfect for power users needing extra flexibility',
      price: {
        monthly: 29,
        yearly: 228,
        monthlyOld: 36,
        yearlyOld: 288,
        monthlyPriceId: '',
        yearlyPriceId: ''
      },
      features: [
        {
          text: '**Unlimited** Essential Messages',
          caption: 'Generate unlimited words',
          subtext: 'GPT-4o-mini, Gemini Flash, and more',
          hasTooltip: true
        },
        {
          text: '1,500 Advanced Messages / month',
          caption: 'Generate around 7,50,000 words',
          subtext: 'GPT-4o, Claude 3.5, Gemini 1.5 and more',
          hasTooltip: true
        },
        {
          text: '300 Image Generations / month',
          subtext: 'Flux and Stable Diffusion'
        }
      ],
      tag: '82% Pick This Plan'
    },
    expert: {
      name: 'Expert',
      id: 'expert',
      description: 'Designed for professionals who desire unlimited AI access',
      price: {
        monthly: 59,
        yearly: 468,
        monthlyOld: 69,
        yearlyOld: 499,
        monthlyPriceId: '',
        yearlyPriceId: ''
      },
      features: [
        {
          text: '**Unlimited** Essential Messages',
          caption: 'Generate unlimited words',
          subtext: 'GPT-4o-mini, Gemini Flash, and more',
          hasTooltip: true
        },
        {
          text: '**Unlimited** Advanced Messages',
          caption: 'Generate unlimited words',
          subtext: 'GPT-4o, Claude 3.5, Gemini 1.5 and more', 
          hasTooltip: true
        },
        {
          text: '1,000 Image Generations / month',
          subtext: 'Flux and Stable Diffusion'
        }
      ],
      tag: 'Best Value'
    }
  };
  
  export const inrPlans = {
    starter: {
      ...usdPlans.starter,
      price: {
        monthly: 990,
        yearly: 9000,
        monthlyOld: 1250,
        yearlyOld: 11880,
        monthlyPriceId: '',
        yearlyPriceId: '' 
      }
    },
    pro: {
      ...usdPlans.pro,
      price: {
        monthly: 2500,
        yearly: 18600,
        monthlyOld: 2900,
        yearlyOld: 30000,
        monthlyPriceId: '',
        yearlyPriceId: ''
      }
    },
    expert: {
      ...usdPlans.expert,
      price: {
        monthly: 4900,
        yearly: 35880,
        monthlyOld: 5900,
        yearlyOld: 39500,
        monthlyPriceId: '',
        yearlyPriceId: ''
      }
    }
  };
  
  // Helper functions for pricing calculations
  export const getMonthlyPrice = (plan) => plan.price.monthly; 
  export const getYearlyPrice = (plan) => plan.price.yearly;
  export const getYearlyMonthlyPrice = (plan) => Math.round(plan.price.yearly / 12);
  export const getCurrencySymbol = (countryName) => countryName === "India" ? "₹" : "$";
  
  // Get plans based on location
  export const getPlans = (countryName) => countryName === "India" ? inrPlans : usdPlans;
  
  // Get price ID based on billing cycle
  export const getPriceId = (plan, billingCycle) => 
    billingCycle === 'yearly' ? plan.price.yearlyPriceId : plan.price.monthlyPriceId;