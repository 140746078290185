import React, { useState, useRef, useEffect } from 'react';
import { ChevronUp, ChevronDown, Wallet, Crown } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import './CreditDisplay.css';

const CreditDisplay = ({ subscriptionData }) => {
  const [showDetails, setShowDetails] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowDetails(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const formatCredit = (value) => {
    return Number(value || 0).toFixed(0);
  };

  return (
    <div className="user-menu user-credits" ref={menuRef}>
      <button 
        className="user-menu-button"
        onClick={() => setShowDetails(!showDetails)}
      >
        <Wallet size={16} />
        <span className="d-flex items-center gap-2">
  <span className="badge badge-essential">
    E: {subscriptionData?.credits?.essential === -1 ? '∞' : formatCredit(subscriptionData?.credits?.essential) || 0}
  </span>
  <span className="badge badge-advanced">
    A: {subscriptionData?.credits?.advanced === -1 ? '∞' : formatCredit(subscriptionData?.credits?.advanced) || 0} 
  </span>
  <span className="badge badge-images">
    I: {formatCredit(subscriptionData?.credits?.images)}
  </span>
</span>

      </button>
      
      {showDetails && (
        <ul className="user-menu-dropdown">
          <li>
            <div className="sidebar-link" onClick={() => setShowDetails(false)}>
              <div className="flex items-center gap-4">
                <span>Essential Messages</span>
              </div>
              <span className="badge badge-essential">{subscriptionData?.credits?.essential === -1 ? '∞' : formatCredit(subscriptionData?.credits?.essential) || 0}</span>
            </div>
          </li>
          <li>
            <div className="sidebar-link" onClick={() => setShowDetails(false)}>
              <div className="flex items-center gap-2">
                <span>Advanced Messages</span>
              </div>
              <span className="badge badge-advanced">{subscriptionData?.credits?.advanced === -1 ? '∞' : formatCredit(subscriptionData?.credits?.advanced) || 0}</span>
            </div>
          </li>
          <li>
            <div className="sidebar-link" onClick={() => setShowDetails(false)}>
              <div className="flex items-center gap-2">
                <span>Image Credits</span>
              </div>
              <span className="badge badge-images">{formatCredit(subscriptionData?.credits?.images)}</span>
            </div>
          </li>
          {/* <li>
            <NavLink to="/pricing" className="sidebar-link" onClick={() => setShowDetails(false)}>
              <Crown size={16} /> Get More Credits
            </NavLink>
          </li> */}
        </ul>
      )}
    </div>
  );
};

export default CreditDisplay;