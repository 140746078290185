import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, PencilRuler } from 'lucide-react';

const AiToolsSubmenu = ({ toolsData }) => {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    if (category) {
      setActiveCategory(category.toLowerCase());
    }
  }, [location.search]);

  const categoryEmojis = {
    "advertising": "📢",
    "blog": "📰",
    "copywriting": "✍️",
    "ecommerce": "🛒",
    "email": "📧",
    "film": "🎥",
    "hr": "👥",
    "idea": "💡",
    "repurpose": "♻️",
    "image": "🖼️",
    "music": "🎶",
    "seo": "🔍",
    "sales": "💰",
    "social media": "🌐",
    "support": "🛠️",
    "website": "🌍",
    "writing assistant": "📝",
    "youtube": "▶️",
    "education": "🎓",
    "podcast": "🎙️",
    "story writing": "📜",
    "extra": "🌟"
  };

  const groupByCategory = (toolsData) => {
    const allTools = Object.values(toolsData).flat();
    return allTools.reduce((acc, tool) => {
      const category = tool.category.toLowerCase();
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(tool);
      return acc;
    }, {});
  };

  const groupedTools = groupByCategory(toolsData);

  // If there's no active category or the category doesn't exist in our tools, return null
  if (!activeCategory || !groupedTools[activeCategory]) {
    return null;
  }

  const categoryTools = groupedTools[activeCategory];
  const currentUrl = `${location.pathname}${location.search}`;

  return (
    <div className="aiTools-sub">
      <ul>
        <li>
          <Link to="/aitools" className="sidebar-link">
            <span className="linkTitle">
              <span className="emoji-icon">🔧</span>
              <span className='all-tools'>All Tools</span>
            </span>
          </Link>
        </li>
      </ul>

      {/* Selected category and its tools */}
      <ul>
        <li className="sidebar-link active">
          <span className="linkTitle">
            <span className="emoji-icon">
              {categoryEmojis[activeCategory]}
            </span>
            {activeCategory} {"Tools"}
          </span>
          <span className="count">{categoryTools.length}</span>
          <ChevronDown />
        </li>
        <ul>
          {categoryTools.map(tool => {
            let path = `/ai-tools/?prompt=${tool.prompt}`;
            if (tool.prompt === '208') {
              path = '/article-writer';
            } else if (tool.prompt === '209') {
              path = '/keyword-research';
            }

            const isActive = currentUrl === path;

            return (
              <li key={tool.prompt}>
                <Link 
                  to={path} 
                  className={`sidebar-link${isActive ? ' active' : ''}`}
                >
                     <span className="emoji-icon">{tool.icon}</span>
                     <span>{tool.title.replace(/^(YouTube|Youtube)\s+/, '')}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </ul>
    </div>
  );
};

export default AiToolsSubmenu;