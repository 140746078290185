const WritingAssistantData = {  
    grammarChecker: {
        prompt: "1818",
        icon: "✏️",
        title: "Grammar Checker",
        category: "Writing Assistant",
        caption: "Check and correct grammatical errors in your text to enhance clarity and readability.",
        inputs: [
            { inputTitle: `Enter Text to Check`, inputCaption: `Paste the text you want to check for grammatical errors.`, type: 'textarea', inputExample: `This are the best solution for your problem.` }
        ]
    },
    paragraphGenerator: {
        prompt: "1811",
        icon: "📝",
        title: "Paragraph Generator",
        category: "Writing Assistant",
        caption: "Create well-structured and coherent paragraphs on a given topic or idea.",
        inputs: [
            { inputTitle: `Topic or Idea`, inputCaption: `What is the topic or idea you want to generate a paragraph about?`, type: 'text', inputExample: `Importance of daily exercise` }
        ]
    },
    paragraphRewriter: {
        prompt: "1812",
        icon: "🔄",
        title: "Paragraph Rewriter",
        category: "Writing Assistant",
        caption: "Rewrite paragraphs to improve structure, clarity, and style without changing the intended meaning.",
        inputs: [
            { inputTitle: `Original Paragraph`, inputCaption: `Paste the paragraph you want to rewrite.`, type: 'textarea', inputExample: `Daily exercise is very important for your health. It helps you stay fit, improve your mood, and reduce your risk of many diseases.` }
        ]
    },
    rewordingTool: {
        prompt: "1814",
        icon: "✍️",
        title: "Rewording Tool",
        category: "Writing Assistant",
        caption: "Reword sentences or paragraphs to improve expression and avoid redundancy.",
        inputs: [
            { inputTitle: `Text to Reword`, inputCaption: `Paste the text that needs rewording.`, type: 'textarea', inputExample: `It's essential to drink plenty of water every day to stay hydrated and maintain good health.` }
        ]
    },
    sentenceRewriterTool: {
        prompt: "1815",
        icon: "🔁",
        title: "Sentence Rewriter Tool",
        category: "Writing Assistant",
        caption: "Enhance the quality and clarity of your sentences by rewriting them more effectively.",
        inputs: [
            { inputTitle: `Sentence to Rewrite`, inputCaption: `Enter the sentence you want to rewrite.`, type: 'text', inputExample: `Eating fruits daily is good for health.` }
        ]
    },  
    paraphrasingContent: {
        prompt: "1801",
        icon: "🔄",
        title: "Paraphrasing Content",
        category: "Writing Assistant",
        caption: `Effortlessly rephrase your text with this tool, ensuring a new version that retains the original message.`,
        inputs: [
            { inputTitle: `Can you share the text you'd like paraphrased?`, inputCaption: `Paste the text you want to paraphrase here.`, rowCount:9, type: 'textarea', limit:5500, note:'Max 5500 char', inputExample: `In today's fast-paced world, managing stress is more important than ever. Finding time for relaxation and hobbies can significantly improve your mental health.` },
            { inputTitle: `How should the new version sound?`, inputCaption: 'Choose a tone for the paraphrased text (e.g., formal, casual).', required: 'false', inputExample: `formal` }
        ]
    },
    passiveVoiceChanger: {
        prompt: "1807",
        icon: "🔄", 
        title: "Passive Voice Changer",
        category: "Writing Assistant",
        caption: "Transform your sentences from passive to active voice effortlessly.",
        inputs: [
            { inputTitle: "Enter your text to transform from passive to active voice.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `The book was read by her every night.` }
        ]
    }, 
    sentenceExpand: {
        prompt: "1808",
        icon: "📖", 
        title: "Sentence Expand",
        category: "Writing Assistant",
        caption: "Expand your sentences for more detail and depth.",
        inputs: [
            { inputTitle: "Enter your text to expand.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `He's good at singing.` }
        ]
    },    
    sentenceShorten: {
        prompt: "1809",
        icon: "✂️", 
        title: "Sentence Shorten",
        category: "Writing Assistant",
        caption: "Concisely shorten your sentences for clarity and brevity.",
        inputs: [
            { inputTitle: "Enter your text to shorten.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `Due to the fact that the weather was cold, she decided to wear a thick jacket.` }
        ]
    },
    sentenceRephraser: {
        prompt: "1810",
        icon: "🔀", 
        title: "Sentence Rephraser",
        category: "Writing Assistant",
        caption: "Rephrase your sentences for enhanced clarity and style.",
        inputs: [
            { inputTitle: "Enter your text to rephrase.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `Making an investment in the stock market is a way to potentially increase your wealth.` }
        ]
    },
    textImprover: {
        prompt: "1803",
        icon: "✨",
        title: "Text Improver",
        category: "Writing Assistant",
        caption: `Enhance your writing by removing errors and unnecessary parts, making it crisp and reader-friendly.`,
        inputs: [
            { inputTitle: `Enter the text that you want to re-write or improve.`, inputCaption: `Paste the text you wish to improve.`, rowCount:9, type: 'textarea', limit:5500, note:'Max 5500 char', inputExample: `Despite the fact that he was tired, he decided that he would continue working on the project.` }
        ]
    }, 
    summarize: {
        prompt: "1804",
        icon: "📋",
        title: "Summarize",
        category: "Writing Assistant",
        caption: `Distill lengthy information into concise, easy-to-grasp summaries, enriched with emojis and hashtags.`,
        inputs: [
            { inputTitle: `Enter the Text you want to summarize`, inputCaption: `Paste the text you need summarized.`, rowCount:9, type: 'textarea', limit:5500, note:'Max 5500 char', inputExample: `Global warming is the long-term heating of Earth's climate system observed since the pre-industrial period due to human activities, predominantly fossil fuel burning, which increases heat-trapping greenhouse gas levels in Earth's atmosphere.` }
        ]
    }, 
    rewriteArticle: {
        prompt: "1805",
        icon: "🔁",
        title: "Rewrite Article (AI Detector Bypass)",
        category: "Writing Assistant",
        caption: `Revamp your articles or blog posts with this tool, ensuring they pass AI detection while maintaining originality.`,
        inputs: [
            { inputTitle: `Provide your Article/Blogpost or any other content to rewrite.`, inputCaption: `Paste your article or blog post here.`, type: 'textarea', rowCount:9, limit:7500, note:'Max 7500 char', inputExample: `Blockchain technology is transforming industries with its decentralized approach, offering security, transparency, and efficiency in transactions.` }
        ]
    },
    
    firstToThirdPersonConverter: {
        prompt: "430",
        icon: "🔄",
        title: "First to Third Person Converter",
        category: "Writing Assistant",
        caption: "Easily convert narratives from the first person to the third person perspective for varied content needs.",
        inputs: [
            { inputTitle: `Text in First Person`, inputCaption: `Enter the text that needs to be converted to third person.`, type: 'textarea', inputExample: `I believe that the new policy will benefit everyone in the long run.` }
        ]
    },
     
    addEmojis: {
        prompt: "1802",
        icon: "😊", 
        title: "Add Emojis to Text",
        category: "Writing Assistant",
        caption: `Enhance your text with the perfect emojis to make it more captivating and expressive.`,
        inputs: [
            { inputTitle: `Enter your text to add emojis.`, inputCaption: `Type or paste your text here.`, type: 'textarea', inputExample: `I love going to the beach on sunny days.` }
        ]
    }, 
    emojiTranslator: {
        prompt: "1817",
        icon: "😊",
        title: "Emoji Translator",
        category: "Writing Assistant",
        caption: "Translate your text into a fun and expressive emoji-laden language.",
        inputs: [
            { inputTitle: `Enter Text to Translate`, inputCaption: `Type the text you want to translate into emojis.`, type: 'text', inputExample: `Happy birthday! Wish you all the best.` }
        ]
    },
    bulletPointConverter: {
        prompt: "1819",
        icon: "🔄",
        title: "Bullet Point Converter",
        category: "Writing Assistant",
        caption: "Convert between bullet points and paragraph format seamlessly.",
        inputs: [
            { inputTitle: "Conversion Direction", inputCaption: "Choose 'bullet-to-text' or 'text-to-bullet'", type: 'select', options: ['bullet-to-text', 'text-to-bullet'] },
            { inputTitle: "Content to Convert", inputCaption: "Enter the text you want to convert", type: 'textarea', inputExample: "• First point\n• Second point\n• Third point" }
        ]
    },
    
    essayTitleGenerator: {
        prompt: "1820",
        icon: "📝",
        title: "Essay Title Generator",
        category: "Writing Assistant",
        caption: "Generate engaging and appropriate essay titles for your topic.",
        inputs: [
            { inputTitle: "Essay Topic/Theme", inputCaption: "What is your essay about?", type: 'text', inputExample: "Impact of Social Media on Mental Health" },
            { inputTitle: "Academic Level", inputCaption: "Select your academic level", type: 'select', options: ['High School', 'Undergraduate', 'Graduate', 'PhD'] },
            { inputTitle: "Special Requirements", inputCaption: "Any specific requirements for the title?", type: 'text', required: false, inputExample: "Must include a colon" }
        ]
    },
    
    topicSentenceGenerator: {
        prompt: "1821",
        icon: "📌",
        title: "Topic Sentence Generator",
        category: "Writing Assistant",
        caption: "Create effective topic sentences for your paragraphs.",
        inputs: [
            { inputTitle: "Main Topic", inputCaption: "What is the paragraph about?", type: 'text', inputExample: "Benefits of Exercise" },
            { inputTitle: "Context", inputCaption: "Additional context for the paragraph", type: 'textarea', inputExample: "Part of an essay about healthy lifestyle choices" }
        ]
    },
    
    introductionGenerator: {
        prompt: "1822",
        icon: "✨",
        title: "Introduction Generator",
        category: "Writing Assistant",
        caption: "Generate compelling introductions for your writing.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "What is your piece about?", type: 'text', inputExample: "Renewable Energy Sources" },
            { inputTitle: "Target Audience", inputCaption: "Who will be reading this?", type: 'text', inputExample: "College students" },
            { inputTitle: "Tone", inputCaption: "Desired tone of the introduction", type: 'select', options: ['Academic', 'Professional', 'Casual', 'Persuasive'] }
        ]
    },
    
    resumeBulletGenerator: {
        prompt: "1823",
        icon: "📋",
        title: "Resume Bullet Point Generator",
        category: "Writing Assistant",
        caption: "Transform your experiences into powerful resume bullet points.",
        inputs: [
            { inputTitle: "Experience/Achievement", inputCaption: "Describe what you did", type: 'textarea', inputExample: "Led a team of 5 developers to complete a website redesign" },
            { inputTitle: "Industry/Field", inputCaption: "What industry is this for?", type: 'text', inputExample: "Information Technology" }
        ]
    },
    
    professionalSummaryGenerator: {
        prompt: "1824",
        icon: "👔",
        title: "Professional Summary Generator",
        category: "Writing Assistant",
        caption: "Create compelling professional summaries for your resume or profile.",
        inputs: [
            { inputTitle: "Field/Industry", inputCaption: "Your professional field", type: 'text', inputExample: "Digital Marketing" },
            { inputTitle: "Years of Experience", inputCaption: "How many years of experience?", type: 'number', inputExample: "5" },
            { inputTitle: "Key Achievements", inputCaption: "List your main achievements", type: 'textarea', inputExample: "Increased sales by 50%, Led team of 10" }
        ]
    },
    
    discussionGenerator: {
        prompt: "1825",
        icon: "💭",
        title: "Discussion Post & Reply Generator",
        category: "Writing Assistant",
        caption: "Generate thoughtful discussion posts and responses.",
        inputs: [
            { inputTitle: "Discussion Topic/Question", inputCaption: "What are you responding to?", type: 'textarea', inputExample: "How does artificial intelligence impact privacy?" },
            { inputTitle: "Key Points", inputCaption: "Points you want to include", type: 'textarea', inputExample: "Data protection, ethical considerations" }
        ]
    },
    
    emailAddressGenerator: {
        prompt: "1826",
        icon: "📧",
        title: "Email Address Generator",
        category: "Writing Assistant",
        caption: "Generate professional email address variations.",
        inputs: [
            { inputTitle: "Name/Business", inputCaption: "Enter name or business", type: 'text', inputExample: "John Smith" },
            { inputTitle: "Purpose", inputCaption: "What's this email for?", type: 'select', options: ['Personal', 'Business'] }
        ]
    },
    
    iceBreakerGenerator: {
        prompt: "1827",
        icon: "🧊",
        title: "Ice Breaker Generator",
        category: "Writing Assistant",
        caption: "Create engaging ice breakers for any situation.",
        inputs: [
            { inputTitle: "Setting/Group Type", inputCaption: "What's the context?", type: 'text', inputExample: "Team Building Workshop" },
            { inputTitle: "Group Size", inputCaption: "How many people?", type: 'number', inputExample: "15" },
            { inputTitle: "Purpose", inputCaption: "Goal of the ice breaker", type: 'text', inputExample: "Getting to know team members" }
        ]
    },
    
    managementSpeechGenerator: {
        prompt: "1828",
        icon: "🎤",
        title: "Management Speech Generator",
        category: "Writing Assistant",
        caption: "Generate effective management speeches.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "What's the speech about?", type: 'text', inputExample: "Annual Company Performance" },
            { inputTitle: "Occasion", inputCaption: "What's the occasion?", type: 'text', inputExample: "Year-end meeting" },
            { inputTitle: "Key Points", inputCaption: "Main points to cover", type: 'textarea', inputExample: "Achievements, challenges, future goals" }
        ]
    },
    
    alliterationGenerator: {
        prompt: "1829",
        icon: "🔤",
        title: "Alliteration Generator",
        category: "Writing Assistant",
        caption: "Create creative alliterative phrases.",
        inputs: [
            { inputTitle: "Theme", inputCaption: "What's the theme?", type: 'text', inputExample: "Ocean" },
            { inputTitle: "Starting Letter/Sound", inputCaption: "Preferred starting letter", type: 'text', inputExample: "S" }
        ]
    },
    
    metaphorSimileGenerator: {
        prompt: "1830",
        icon: "🎭",
        title: "Metaphor & Simile Generator",
        category: "Writing Assistant",
        caption: "Generate creative metaphors and similes.",
        inputs: [
            { inputTitle: "Topic", inputCaption: "What do you need figurative language for?", type: 'text', inputExample: "Success" },
            { inputTitle: "Purpose/Context", inputCaption: "How will this be used?", type: 'text', inputExample: "Motivational speech" }
        ]
    },
    
    textSimplifier: {
        prompt: "1831",
        icon: "📝",
        title: "Text Simplifier",
        category: "Writing Assistant",
        caption: "Simplify complex text while maintaining meaning.",
        inputs: [
            { inputTitle: "Text to Simplify", inputCaption: "Enter your text", type: 'textarea', inputExample: "The implementation of the policy resulted in unprecedented ramifications." },
            { inputTitle: "Target Reading Level", inputCaption: "Desired reading level", type: 'select', options: ['Elementary', 'Middle School', 'High School', 'General Adult'] }
        ]
    },
    
    reportWriter: {
        prompt: "1834",
        icon: "📊",
        title: "Report Writer",
        category: "Writing Assistant",
        caption: "Generate professional reports with structured content.",
        inputs: [
            { inputTitle: "Report Topic", inputCaption: "What's the report about?", type: 'text', inputExample: "Market Analysis 2024" },
            { inputTitle: "Key Findings/Data", inputCaption: "Main points and data", type: 'textarea', inputExample: "Sales increased 25%, New market opportunities" },
            { inputTitle: "Required Sections", inputCaption: "What sections need to be included?", type: 'text', inputExample: "Executive Summary, Findings, Recommendations" }
        ]
    },
    
    thankYouGenerator: {
        prompt: "1835",
        icon: "🙏",
        title: "Thank You Note Generator",
        category: "Writing Assistant",
        caption: "Create heartfelt thank you notes.",
        inputs: [
            { inputTitle: "Occasion/Purpose", inputCaption: "What are you thanking for?", type: 'text', inputExample: "Job Interview" },
            { inputTitle: "Recipient", inputCaption: "Who is this for?", type: 'text', inputExample: "Hiring Manager" },
            { inputTitle: "Specific Points", inputCaption: "What specific points to mention?", type: 'textarea', inputExample: "Discussion about company culture" }
        ]
    },
    apologyLetterGenerator: {
        prompt: "1836",
        icon: "🤝",
        title: "Apology Letter Generator",
        category: "Writing Assistant",
        caption: "Generate sincere and effective apology letters for personal or professional situations.",
        inputs: [
            { 
                inputTitle: "Situation", 
                inputCaption: "Describe what happened and what you're apologizing for", 
                type: 'textarea', 
                inputExample: "Missed an important client meeting due to calendar mix-up" 
            },
            { 
                inputTitle: "Recipient", 
                inputCaption: "Who is this apology letter for?", 
                type: 'text', 
                inputExample: "Client Name/Manager/Colleague" 
            },
            { 
                inputTitle: "Additional Context", 
                inputCaption: "Any specific actions taken or planned to rectify the situation", 
                type: 'textarea', 
                inputExample: "Rescheduled meeting and implemented new calendar checking procedure",
                required: false
            },
            {
                inputTitle: "Letter Type",
                inputCaption: "Select the type of apology letter",
                type: 'select',
                options: ['Professional', 'Personal'],
                inputExample: "Professional"
            }
        ]
    },
    painPointBuilder: {
        prompt: "1837",
        icon: "🎯",
        title: "Pain Point Builder",
        category: "Writing Assistant",
        caption: "Develop comprehensive pain points for products, services, or situations.",
        inputs: [
            { inputTitle: "Subject", inputCaption: "What product/service/situation needs pain point analysis?", type: 'text', inputExample: "Project Management Software" },
            { inputTitle: "Target Audience", inputCaption: "Who experiences these pain points?", type: 'text', inputExample: "Small Business Owners" },
            { inputTitle: "Context", inputCaption: "Any specific context or industry?", type: 'text', inputExample: "Remote Work Environment" }
        ]
    },
    letterOfRecommendation: {
        prompt: "1838",
        icon: "📝",
        title: "Letter of Recommendation Generator",
        category: "Writing Assistant",
        caption: "Generate professional and compelling letters of recommendation.",
        inputs: [
            { inputTitle: "Position/Purpose", inputCaption: "What position or purpose is this recommendation for?", type: 'text', inputExample: "Software Developer Position" },
            { inputTitle: "Candidate Name", inputCaption: "Who is the recommendation for?", type: 'text', inputExample: "John Smith" },
            { inputTitle: "Key Achievements/Skills", inputCaption: "List notable achievements and skills", type: 'textarea', inputExample: "Led team of 5, increased efficiency by 40%" },
            { inputTitle: "Relationship to Candidate", inputCaption: "Your role in relation to the candidate", type: 'text', inputExample: "Direct Supervisor for 3 years" }
        ]
    },
    
    contractGenerator: {
        prompt: "1839",
        icon: "📄",
        title: "Contract Generator",
        category: "Writing Assistant",
        caption: "Create professional contracts with customizable terms and conditions.",
        inputs: [
            { inputTitle: "Contract Type", inputCaption: "What type of contract is needed?", type: 'text', inputExample: "Service Agreement" },
            { inputTitle: "Parties Involved", inputCaption: "List all parties in the contract", type: 'textarea', inputExample: "Company A (Service Provider), Company B (Client)" },
            { inputTitle: "Key Terms", inputCaption: "Outline main terms and conditions", type: 'textarea', inputExample: "Payment terms, delivery schedule, scope of work" },
            { inputTitle: "Special Conditions", inputCaption: "Any specific conditions or clauses", type: 'textarea', inputExample: "Non-disclosure agreement, termination clauses" }
        ]
    },
    
    legalDraftCreator: {
        prompt: "1840",
        icon: "⚖️",
        title: "Legal Draft Creator",
        category: "Writing Assistant",
        caption: "Generate professional legal documents with proper formatting and terminology.",
        inputs: [
            { inputTitle: "Document Type", inputCaption: "Type of legal document needed", type: 'text', inputExample: "Purchase Agreement" },
            { inputTitle: "Key Requirements", inputCaption: "Essential elements to include", type: 'textarea', inputExample: "Property details, purchase price, closing date" },
            { inputTitle: "Jurisdiction", inputCaption: "Relevant legal jurisdiction", type: 'text', inputExample: "California, United States" },
            { inputTitle: "Specific Terms", inputCaption: "Any specific terms or conditions", type: 'textarea', inputExample: "Payment schedule, contingencies" }
        ]
    },
    
    hardshipLetter: {
        prompt: "1841",
        icon: "✉️",
        title: "Hardship Letter Generator",
        category: "Writing Assistant",
        caption: "Create compelling hardship letters that effectively communicate your situation.",
        inputs: [
            { inputTitle: "Purpose", inputCaption: "Reason for hardship letter", type: 'text', inputExample: "Mortgage Loan Modification" },
            { inputTitle: "Circumstances", inputCaption: "Describe the hardship situation", type: 'textarea', inputExample: "Unexpected medical expenses and temporary job loss" },
            { inputTitle: "Supporting Details", inputCaption: "Additional relevant information", type: 'textarea', inputExample: "Medical bills, unemployment documentation" },
            { inputTitle: "Requested Action", inputCaption: "What are you asking for?", type: 'textarea', inputExample: "Three-month payment deferral" }
        ]
    },
    
    documentTemplate: {
        prompt: "1842",
        icon: "📋",
        title: "Document Template Creator",
        category: "Writing Assistant",
        caption: "Create customizable document templates for various purposes.",
        inputs: [
            { inputTitle: "Document Type", inputCaption: "Type of template needed", type: 'text', inputExample: "Meeting Minutes" },
            { inputTitle: "Purpose", inputCaption: "Intended use of the template", type: 'text', inputExample: "Weekly team meetings" },
            { inputTitle: "Required Sections", inputCaption: "List necessary sections", type: 'textarea', inputExample: "Attendees, Agenda, Action Items, Next Steps" },
            { inputTitle: "Formatting Requirements", inputCaption: "Specific formatting needs", type: 'textarea', inputExample: "Company letterhead, numbered sections" }
        ]
    },
    
    professionalLetter: {
        prompt: "1843",
        icon: "📨",
        title: "Professional Letter Writer",
        category: "Writing Assistant",
        caption: "Generate well-structured professional letters for various purposes.",
        inputs: [
            { inputTitle: "Letter Type", inputCaption: "Type of professional letter", type: 'text', inputExample: "Business Proposal" },
            { inputTitle: "Purpose/Goal", inputCaption: "Main objective of the letter", type: 'textarea', inputExample: "Request partnership opportunity" },
            { inputTitle: "Key Points", inputCaption: "Main points to cover", type: 'textarea', inputExample: "Company background, proposed benefits, next steps" },
            { inputTitle: "Recipient Details", inputCaption: "Information about the recipient", type: 'textarea', inputExample: "CEO of target company" }
        ]
    },
    
    appealLetter: {
        prompt: "1844",
        icon: "🔍",
        title: "Appeal Letter Generator",
        category: "Writing Assistant",
        caption: "Create persuasive appeal letters for various situations.",
        inputs: [
            { inputTitle: "Appeal Type", inputCaption: "Type of appeal", type: 'text', inputExample: "Academic Dismissal Appeal" },
            { inputTitle: "Situation Details", inputCaption: "Describe the situation", type: 'textarea', inputExample: "Failed courses due to family emergency" },
            { inputTitle: "Supporting Evidence", inputCaption: "List supporting documentation", type: 'textarea', inputExample: "Medical records, family statements" },
            { inputTitle: "Desired Outcome", inputCaption: "What are you requesting?", type: 'textarea', inputExample: "Reinstatement for next semester" }
        ]
    },
    
    businessDocument: {
        prompt: "1845",
        icon: "💼",
        title: "Business Document Generator",
        category: "Writing Assistant",
        caption: "Generate professional business documents with proper formatting.",
        inputs: [
            { inputTitle: "Document Type", inputCaption: "Type of business document", type: 'text', inputExample: "Executive Summary" },
            { inputTitle: "Business Purpose", inputCaption: "Intended use of document", type: 'textarea', inputExample: "Quarterly business review" },
            { inputTitle: "Key Information", inputCaption: "Essential content to include", type: 'textarea', inputExample: "Financial highlights, achievements, goals" },
            { inputTitle: "Format Requirements", inputCaption: "Specific formatting needs", type: 'textarea', inputExample: "Company template, charts, tables" }
        ]
    },
}
export default WritingAssistantData;
