const YoutubeToolsData = {  
    YoutubeIdeaContentPlanner: {
        prompt: "1901",
        icon: "💡",
        title: "Youtube Idea & Content Planner",
        category: "youtube",
        caption: "Generate captivating YouTube video concepts, detailed outlines, and key discussion points, customized to fit your unique niche.",
        inputs: [{ inputTitle: 'What specific topic do you want your YouTube videos to focus on?', inputCaption: 'Enter a topic for your videos, such as Finance, Fitness, Cooking, Travel, etc.', inputExample: `Sustainable Living Tips` }]
    },    
    titleThumbnailIdeas: {
        prompt: "1902",
        icon: "🎬", 
        title: "Youtube Title & Thumbnail Ideas",
        category: "youtube",
        caption: "Leverage AI to craft eye-catching YouTube video titles and thumbnail designs for enhanced viewer engagement.",
        inputs: [{ inputTitle: 'Enter your keyword(s)', inputCaption: 'Type keywords or phrases related to your video content', type: 'textarea', inputExample: `Quick and Healthy Breakfast Recipes` }]
    },       
    scriptforYouTubeVideo: {
        prompt: "1510",
        icon: "🎬",
        title: "YouTube Video Script Generator",
        category: "youtube",
        caption: "Create engaging YouTube scripts that captivate your audience.",
        inputs: [
            { inputTitle: `What is your YouTube Video title?`, inputCaption: 'Enter the title of your YouTube video', inputExample: `10 Easy Yoga Poses for Beginners` },
            { inputTitle: `What are the key talking points?`, inputCaption: 'List the main points to cover', type: "textarea", required: 'false', inputExample: `Introduction to Yoga, Benefits, Step-by-step guide` },
            { inputTitle: `Who is your target audience?`, inputCaption: 'Describe your target audience', required: 'false', inputExample: `Beginners looking to start their yoga journey` }
        ]
    },
    youtubeDescriptionTags: {
        prompt: "1904",
        icon: "📝",
        title: "YouTube Description & Tags Generator",
        category: "youtube",
        caption: "Generate SEO-optimized descriptions and tags for maximum visibility.",
        inputs: [{ inputTitle: 'Enter your video title & main points', inputCaption: 'Write your video title and key topics', type: 'textarea', inputExample: `Ultimate Guide to Solo Travel: Tips and Destinations` }]
    },
    timestamps: {
        prompt: "1905",
        icon: "⏱️", 
        title: "YouTube Timestamps Generator",
        category: "youtube",
        caption: "Create organized timestamps for your video content.",
        inputs: [{ inputTitle: 'Enter your video script with time markers', inputCaption: 'Paste your video script with approximate timings', type: 'textarea', inputExample: `00:00 Intro, 01:30 Benefits of Solo Travel` }]
    },
    shortsScript: {
        prompt: "1906",
        icon: "🎥",
        title: "YouTube Shorts Script Creator",
        category: "youtube",
        caption: "Create engaging scripts specifically for YouTube Shorts format.",
        inputs: [{ inputTitle: `Enter your main topic`, inputCaption: 'What is your Shorts video about?', type: 'textarea', inputExample: `5 Quick Exercise Tips` }]
    },
    seoTitleOptimizer: {
        prompt: "1907",
        icon: "🔍",
        title: "YouTube SEO Title Optimizer",
        category: "youtube",
        caption: "Optimize your video titles for maximum search visibility.",
        inputs: [
            { inputTitle: `Enter your current title`, inputCaption: 'Your existing video title', type: 'textarea', inputExample: `How to Make a Perfect Cup of Coffee` },
            { inputTitle: `Target keywords`, inputCaption: 'Enter your target keywords', type: 'textarea', inputExample: `coffee brewing, barista tips` }
        ]
    },
    tagGenerator: {
        prompt: "1908",
        icon: "🏷️",
        title: "YouTube Tag Generator",
        category: "youtube",
        caption: "Generate relevant tags to improve video discoverability.",
        inputs: [{ inputTitle: `Video title or topic`, inputCaption: 'Enter your video title or main topic', type: 'textarea', inputExample: `DIY Home Decor Ideas` }]
    },
    hashtagGenerator: {
        prompt: "1909",
        icon: "#️⃣",
        title: "YouTube Hashtag Generator",
        category: "youtube",
        caption: "Create effective hashtags for better video reach.",
        inputs: [{ inputTitle: `Video topic`, inputCaption: 'What is your video about?', type: 'text', inputExample: `Vegan Cooking` }]
    },
    chapterGenerator: {
        prompt: "1910",
        icon: "📑",
        title: "YouTube Chapter Generator",
        category: "youtube",
        caption: "Create organized chapters for long-form videos.",
        inputs: [
            { inputTitle: `Video outline`, inputCaption: 'Enter your video outline or main points', type: 'textarea', inputExample: `Introduction to Topic\nKey Points Discussion\nPractical Examples\nConclusion` }
        ]
    },
    descriptionTemplate: {
        prompt: "1911",
        icon: "📝",
        title: "YouTube Description Template Generator",
        category: "youtube",
        caption: "Create professional video description templates.",
        inputs: [
            { inputTitle: `Channel niche`, inputCaption: 'What is your channel about?', type: 'text', inputExample: `Tech Reviews` },
            { inputTitle: `Standard links/social media`, inputCaption: 'Your standard links and social media handles', type: 'textarea', required: 'false', inputExample: `Website: example.com\nTwitter: @handle` }
        ]
    },
    hookGenerator: {
        prompt: "1912",
        icon: "🎯",
        title: "YouTube Hook Script Generator",
        category: "youtube",
        caption: "Create attention-grabbing video opening hooks.",
        inputs: [{ inputTitle: `Video topic`, inputCaption: 'What is your video about?', type: 'textarea', inputExample: `Making Passive Income Online` }]
    },
    tutorialScript: {
        prompt: "1913",
        icon: "📚",
        title: "YouTube Tutorial Script Generator",
        category: "youtube",
        caption: "Create clear and structured tutorial video scripts.",
        inputs: [
            { inputTitle: `Tutorial topic`, inputCaption: 'What are you teaching?', type: 'text', inputExample: `Photoshop for Beginners` },
            { inputTitle: `Key steps`, inputCaption: 'List the main steps', type: 'textarea', inputExample: `1. Interface Overview\n2. Basic Tools\n3. First Project` }
        ]
    },
    reviewScript: {
        prompt: "1914",
        icon: "🎬",
        title: "YouTube Review Script Generator",
        category: "youtube",
        caption: "Create balanced and informative review video scripts.",
        inputs: [
            { inputTitle: `Product/Service to review`, inputCaption: 'What are you reviewing?', type: 'text', inputExample: `iPhone 15 Pro` },
            { inputTitle: `Key aspects to cover`, inputCaption: 'What aspects will you review?', type: 'textarea', inputExample: `Design\nCamera\nPerformance\nBattery Life` }
        ]
    },
    shortsConverter: {
        prompt: "1915",
        icon: "✂️",
        title: "YouTube Long-form to Shorts Converter",
        category: "youtube",
        caption: "Convert long-form video content into Shorts format.",
        inputs: [{ inputTitle: `Original video content`, inputCaption: 'Paste your long-form video content', type: 'textarea', inputExample: `Full Tutorial on Photography Basics` }]
    },
    commentGenerator: {
        prompt: "1916",
        icon: "💬",
        title: "YouTube Comment Generator",
        category: "youtube",
        caption: "Generate engaging responses to video comments.",
        inputs: [
            { inputTitle: `Comment to respond to`, inputCaption: 'Enter the comment', type: 'textarea', inputExample: `Great video! Can you make more content about this topic?` }
        ]
    },
    communityPost: {
        prompt: "1917",
        icon: "❤️",
        title: "YouTube Community Post Creator",
        category: "youtube",
        caption: "Create engaging community posts for your channel.",
        inputs: [{ inputTitle: `Post topic`, inputCaption: 'What do you want to share with your community?', type: 'textarea', inputExample: `Upcoming Video Series Announcement` }]
    },
    pollGenerator: {
        prompt: "1918",
        icon: "📊",
        title: "YouTube Poll Generator",
        category: "youtube",
        caption: "Create engaging polls for community engagement.",
        inputs: [
            { inputTitle: `Poll topic`, inputCaption: 'What do you want to ask your audience?', type: 'text', inputExample: `Next Video Topic` },
            { inputTitle: `Poll options`, inputCaption: 'List your poll options', type: 'textarea', inputExample: `Option 1: Tutorial\nOption 2: Review\nOption 3: Behind the Scenes` }
        ]
    },
    ctaGenerator: {
        prompt: "1919",
        icon: "🎯",
        title: "YouTube Call-to-Action Generator",
        category: "youtube",
        caption: "Create effective calls-to-action for your videos.",
        inputs: [{ inputTitle: `Video goal`, inputCaption: 'What action do you want viewers to take?', type: 'text', inputExample: `Subscribe to Channel` }]
    },
    endScreenGenerator: {
        prompt: "1920",
        icon: "📋",
        title: "YouTube End Screen Generator",
        category: "youtube",
        caption: "Create effective end screen layouts and content.",
        inputs: [
            { inputTitle: `Video type`, inputCaption: 'What type of video is this?', type: 'text', inputExample: `Tutorial Video` },
            { inputTitle: `Related videos`, inputCaption: 'List related videos to showcase', type: 'textarea', required: 'false', inputExample: `Beginner's Guide\nAdvanced Tips` }
        ]
    },
    clickbaitGenerator: {
        prompt: "1921",
        icon: "🧲",
        title: "YouTube Clickbait Title Generator",
        category: "youtube",
        caption: "Create attention-grabbing titles that deliver on promises.",
        inputs: [
            { inputTitle: `Video content`, inputCaption: 'What is your video about?', type: 'textarea', inputExample: `Testing Viral TikTok Life Hacks` },
            { inputTitle: `Key revelation/outcome`, inputCaption: 'What is the main surprise or result?', type: 'text', inputExample: `Only 2 Actually Worked!` }
        ]
    }
}

export default YoutubeToolsData;