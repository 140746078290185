import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CircleCheck, HelpCircle } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import { 
  getPlans, 
  getCurrencySymbol, 
  getPriceId, 
  getYearlyMonthlyPrice 
} from './pricingPlans';
import axios from 'axios';
import './PricingModal.css';

const PricingModal = ({ show, onClose }) => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [locationState, setLocationState] = useState({
    countryName: "",
    countryCode: "",
  });
  
  const auth = getAuth();
  const plans = getPlans(locationState.countryName);
  const [processingPlan, setProcessingPlan] = useState(null);

  useEffect(() => {
    const getGeoInfo = () => {
      axios
        .get("https://ipapi.co/json")
        .then((response) => {
          let data = response.data;
          setLocationState({
            countryName: data.country_name,
            countryCode: data.country_code,
          });
        })
        .catch((error) => {
          console.error("Error fetching location:", error);
        });
    };
    
    getGeoInfo();
  }, []);

  const getPrice = (plan) => {
    if (billingCycle === 'yearly') {
      return getYearlyMonthlyPrice(plan);
    }
    return plan.price.monthly;
  };

  const handleSubscribe = async (planId) => {
    try {
      setProcessingPlan(planId);
      setError(null);

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('Please sign in to subscribe');
      }

      const plan = plans[planId];
      if (!plan) {
        throw new Error('Invalid plan selected');
      }
      
      const stripePriceId = getPriceId(plan, billingCycle);

      const local = 'http://localhost:3001/api/subscription/create-checkout-session';
      const dev = 'https://wk-server.vercel.app/api/subscription/create-checkout-session'
      const response = await axios.post(dev, {
        userId: currentUser.uid,
        priceId: stripePriceId,
        planId,
        interval: billingCycle,
        successUrl: window.location.origin + '/dashboard?subscription=success',
        cancelUrl: window.location.origin + '/dashboard?subscription=cancelled',
        currency: locationState.countryName === "India" ? "inr" : "usd"
      });

      window.location.href = response.data.url;
      
    } catch (error) {
      setError(error.message);
      setProcessingPlan(null);
    }
  };

  const renderFeature = (feature) => (
    <div className="feature-item">
      <CircleCheck size={16} className="text-success" />
      <div className="feature-content">
        <div className="feature-text">
          {feature.text.includes('**') ? (
            <span dangerouslySetInnerHTML={{
              __html: feature.text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            }} />
          ) : feature.text}
          {/* {feature.hasTooltip && (
            <HelpCircle size={14} className="text-muted ms-2 help-icon" />
          )} */}
        </div>
        {feature.subtext && (
          <>
          <div className="feature-subtext caption text-muted"><span>{feature.caption}</span></div>
          <div className="feature-subtext text-muted">{feature.subtext}</div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal 
      show={show} 
      onHide={onClose} 
      dialogClassName="pricing-modal-wide"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="w-100 text-center">
          <div className="billing-selector">
            <button
              className={`billing-option ${billingCycle === 'monthly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly
            </button>
            <button
              className={`billing-option ${billingCycle === 'yearly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('yearly')}
            >
              Yearly <span className="save-badge ms-2">Save 30%</span>
            </button>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="plans-grid">
          {Object.entries(plans).map(([key, plan]) => (
            <div 
              key={key} 
              className={`plan-card ${plan.tag === '82% Pick This Plan' ? 'highlight' : ''}`}
            >
              {plan.tag && (
                <div className={`plan-tag ${key === 'expert' ? 'best-value' : ''}`}>
                  {plan.tag}
                </div>
              )}
              <div className="plan-header">
                <h3>{plan.name}</h3>
                <p className="plan-description">{plan.description}</p>
                <div className="plan-price">
                  <div className='price-wrap'>
                    <div className='price-amount-wrap'>
                      <span className="text-gray-400 line-through me-1">
                        {getCurrencySymbol(locationState.countryName)}
                        {billingCycle === 'yearly' ? plan.price.yearlyOld / 12 : plan.price.monthlyOld}
                      </span>
                      <span className='plan-amount'>{getCurrencySymbol(locationState.countryName)}{getPrice(plan)}</span>
                      <span className="period">/mo</span>
                    </div>
                    <span className="billing-info">
                      {/* Billed {billingCycle} */}
                      {billingCycle === 'yearly' && (
                        <span className="ms-1">
                          ({getCurrencySymbol(locationState.countryName)}{plan.price.yearly}/yr)
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                
                {auth.currentUser ? (
                  <Button
                    variant={key === 'pro' ? 'primary' : key === 'expert' ? 'purple' : 'dark'}
                    className="w-100 mt-3"
                    size="lg"
                    onClick={() => handleSubscribe(key)}
                    disabled={processingPlan !== null}
                  >
                    {processingPlan === key ? 'Processing...' : `Choose ${plan.name}`}
                  </Button>
                ) : (
                  <Button
                    variant={key === 'pro' ? 'primary' : key === 'expert' ? 'purple' : 'dark'}
                    className="w-100 mt-3"
                    size="lg"
                    onClick={() => {/* Add your sign in logic here */}}
                  >
                    Sign in to Subscribe
                  </Button>
                )}
              </div>

              <div className="plan-features">
                {plan.features.map((feature, index) => renderFeature(feature))}
              </div>
            </div>
          ))}
        </div>

        {error && (
          <div className="alert alert-danger mt-3">
            {error}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PricingModal;