import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Plus, CircleHelp,X } from 'lucide-react';
import './FAQStyle.css';

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);
  
  const faqs = [
    {
      question: "What is Wordkraft AI?",
      answer: "Wordkraft AI is a unified platform that connects leading AI models in one interface. It offers pro-level AI tools for text and image generation, agents, and continuous updates. Ideal for AI enthusiasts, Wordkraft AI boosts creativity and productivity while staying current with the latest advancements."
    },
    {
      question: "Is there a cost to use Wordkraft AI?",
      answer: "We offer a free trial, after which you can choose from three pricing tiers: Starter, Pro, and Expert. For detailed pricing information, please visit the pricing section on our landing page."
    },
    {
      question: "What kind of AI models are available on Wordkraft AI?",
      answer: <div>
        Wordkraft AI offers a wide range of AI models, including:<br/><br/>
        OpenAI: GPT-4o-mini, GPT-4o, GPT-3.5, GPT-o1-mini<br/>
        Anthropic: Claude-3.5 (Sonnet, Haiku)<br/>
        Google: Gemini-1.5 (Flash and Pro)<br/>
        Meta: LLaMA 3.1 and 3.2 models (ranging from 8B to 405B parameters)<br/>
        Mistral: Small to large versions of Mistral models<br/>
        Qwen: Qwen-2-57B-Chat<br/>
        Perplexity: Perplexity
      </div>
    },
    {
      question: "Are there any rate limits on the plans that offer unlimited messages?",
      answer: "Our Unlimited plan offers unrestricted usage for typical workflows. While we maintain rate limits for system stability, these safeguards only prevent abuse and rarely affect normal usage. If you encounter any issues, contact us for a hassle-free refund."
    },
    {
      question: "Can I use Wordkraft AI for commercial purposes?",
      answer: "Yes, Wordkraft AI can be used for both personal and commercial projects. The content and outputs you generate using our platform are yours to use and distribute, in accordance with our terms of service."
    },
    {
        question: "Is customer support available?",
        answer: "Yes! For assistance, use the chat widget at the bottom right of our website or email us at support@wordkraft.ai"
      },
      {
        question: "How is my data handled on Wordkraft AI?",
        answer: "Your data privacy and security are protected through robust measures. You retain full ownership of all data and outputs created on our platform, with processing handled according to our privacy policy."
      }, 
  ];

  return (
    <div className="faq-section">
      <Container>
        <div className="faq-header">
          <div className="faq-label">
            <CircleHelp size={18}/>
            <span>FAQ's</span>
          </div>
          <h2 className="faq-title">Frequently Asked Questions</h2>
          <p className="faq-description">
            We're here to help! Find answers to common questions below, or feel free to reach out for more information.
          </p>
        </div>

        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <button
                className={`faq-question ${openQuestion === index ? 'question-expanded' : ''}`}
                onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
              >
                <span>{faq.question}</span>
                {openQuestion === index ? (
                  <X className="icon" />
                ) : (
                  <Plus className="icon" />
                )}
              </button>
              {openQuestion === index && (
                <div className="faq-answer">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default FAQSection;