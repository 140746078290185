import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner, Alert, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Search, CheckCircle2, Brain, Sparkles, ChevronDown } from 'lucide-react';
import './chat.css';
 
// Model information for tooltips
const MODEL_INFO = {
  // Gemini Models
  'gemini-1.5-flash': 'Fast, efficient model for quick responses',
  'gemini-1.5-flash-8b': '8B parameter model optimized for speed',
  'gemini-1.5-pro': 'Advanced model with enhanced capabilities',
  'gemini-1.0-pro': 'Stable, well-rounded performance model',
  
  // OpenAI Models
  'gpt-3.5-turbo': 'Fast and cost-effective model',
  'gpt-4o-mini': 'Compact version of GPT-4',
  'gpt-4': 'Powerful general-purpose model',
  'gpt-4o': 'Enhanced GPT-4 with additional capabilities',
  'gpt-o1-preview': 'Preview of next-gen OpenAI model',
  'gpt-o1-mini': 'Efficient next-gen model variant'
};
const getProviderColor = (provider) => {
  const colors = {
    gemini: 'primary',
    openai: 'success'
  };
  return colors[provider] || 'primary';
};
const CopyButton = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <button 
      className="copy-button"
      onClick={handleCopy}
    >
      {copied ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
        </svg>
      )}
    </button>
  );
};

const MessageBubble = ({ message }) => {
  return (
    <div className={`message ${message.role === 'user' ? 'user-message' : 'ai-message'}`}>
      <div className={`message-content ${message.role === 'user' ? 'user-markdown' : ''}`}>
        <ReactMarkdown
          components={{
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '');
              const language = match ? match[1] : 'text';
              
              return !inline ? (
                <div className="code-block-container">
                  <div className="code-block-header">
                    <span className="code-language">{language.toUpperCase()}</span>
                    <CopyButton textToCopy={String(children)} />
                  </div>
                  <SyntaxHighlighter
                    style={vscDarkPlus}
                    language={language}
                    PreTag="div"
                    customStyle={{
                      background: 'transparent',
                      padding: '0px',
                      margin: '0',
                    }}
                    codeTagProps={{
                      style: {
                        fontFamily: 'monospace',
                        background: 'transparent',
                      }
                    }}
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                </div>
              ) : (
                <code 
                  style={{ 
                    fontFamily: 'monospace',
                    background: '#f0f0f0',
                    color: message.role === 'user' ? '#fff' : '#333'
                  }}
                >
                  {children}
                </code>
              );
            }
          }}
        >
          {message.content}
        </ReactMarkdown>
        
        {/* Copy button for entire message content */}
        <CopyButton textToCopy={message.content} />
      </div>
    </div>
  );
};
 
const ModelSelector = ({ selectedModel, onModelSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const wrapperRef = useRef(null);
  const searchInputRef = useRef(null);

  const modelConfig = {
    'Google Gemini Models': {
      icon: <Sparkles className="gemini-icon model-icon" />,
      models: [
        'gemini-1.5-flash',
        'gemini-1.5-flash-8b',
        'gemini-1.5-pro',
        'gemini-1.0-pro'
      ]
    },
    'OpenAI Models': {
      icon: <Brain className="openai-icon model-icon" />,
      models: [
        'gpt-3.5-turbo',
        'gpt-4o-mini',
        'gpt-4',
        'gpt-4o',
        'gpt-o1-preview',
        'gpt-o1-mini'
      ]
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const handleModelSelect = (model) => {
    onModelSelect(model);
    setIsOpen(false);
    setSearchTerm('');
  };

  const filterModels = () => {
    const results = {};
    Object.entries(modelConfig).forEach(([category, config]) => {
      const filteredModels = config.models.filter(model => 
        model.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredModels.length > 0) {
        results[category] = {
          ...config,
          models: filteredModels
        };
      }
    });
    return results;
  };

  return (
    <div className="model-selector-container" ref={wrapperRef}>
      <div 
        className="model-select-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedModel && modelConfig[Object.keys(modelConfig).find(
          category => modelConfig[category].models.includes(selectedModel)
        )]?.icon}
        <span>{selectedModel || 'Select a model'}</span>
        <ChevronDown 
          className={`ml-auto transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </div>

      {isOpen && (
        <div className="model-dropdown">
          <div className="search-container relative">
            <Search className="search-icon" />
            <input
              ref={searchInputRef}
              type="text"
              className="search-input"
              placeholder="Search models..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>

          <div className="models-list">
            {Object.entries(filterModels()).map(([category, config]) => (
              <div key={category}>
                <div className="model-category">
                  {config.icon}
                  {category}
                </div>
                {config.models.map((model) => (
                  <div
                    key={model}
                    className={`model-option ${selectedModel === model ? 'selected' : ''}`}
                    onClick={() => handleModelSelect(model)}
                  >
                    {config.icon}
                    {model}
                    {selectedModel === model && (
                      <CheckCircle2 className="selected-check" />
                    )}
                  </div>
                ))}
              </div>
            ))}
            {Object.keys(filterModels()).length === 0 && (
              <div className="p-4 text-center text-gray-500">
                No models found matching "{searchTerm}"
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};


function GPTTest() {
  // State management 
const [messages, setMessages] = useState([]);
const [inputMessage, setInputMessage] = useState('');
const [provider, setProvider] = useState('gemini');
const [selectedModel, setSelectedModel] = useState('');
const [availableModels, setAvailableModels] = useState({
  gemini: [],
  openai: []
});
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');
const messagesEndRef = useRef(null);


  // Fetch available models on component mount
  useEffect(() => {
    fetchAvailableModels();
  }, []);

  // Scroll to bottom when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchAvailableModels = async () => {
    try {
      const response = await fetch('http://localhost:3001/models');
      const data = await response.json();
      const filteredData = {
        gemini: data.gemini || [],
        openai: data.openai || []
      };
      setAvailableModels(filteredData);
    } catch (error) {
      console.error('Failed to fetch models:', error);
      setError('Could not fetch available models');
    }
  };

  const getProviderFromModel = (model) => {
    return model.startsWith('gemini') ? 'gemini' : 'openai';
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
    setProvider(getProviderFromModel(model));
  };


  // Fetch available models on component mount
  useEffect(() => {
    fetchAvailableModels();
  }, []);

  // Scroll to bottom when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Update selected model when provider changes
  useEffect(() => {
    if (availableModels[provider]?.length > 0) {
      setSelectedModel(availableModels[provider][0]);
    }
  }, [provider, availableModels]);

 



  const formatRequestData = (provider, messages, model) => {
    const baseRequest = {
      model: model
    };
  
    switch (provider) {
      case 'gemini':
        return {
          ...baseRequest,
          contents: messages.map((msg) => ({
            parts: [
              {
                text: msg.content
              }
            ]
          }))
        };
      case 'openai':
        return {
          ...baseRequest,
          messages: messages.map((msg) => ({
            role: msg.role,
            content: msg.content
          }))
        };
      default:
        return baseRequest;
    }
  };
  
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || loading) return;
  
    const newUserMessage = {
      role: 'user',
      content: inputMessage,
      timestamp: new Date().toISOString(),
      provider: provider,
      model: selectedModel
    };
  
    const updatedMessages = [...messages, newUserMessage]; // Include entire chat history
    setMessages(updatedMessages);
    setInputMessage('');
    setLoading(true);
    setError('');
  
    const endpoint = provider === 'openai' 
      ? 'http://localhost:3001/generate-openai'
      : 'http://localhost:3001/generate-content';
    
    const requestData = formatRequestData(provider, updatedMessages, selectedModel);
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      
      const data = await response.json();
      
      if (data.error) {
        setError(data.error.message || 'An error occurred');
      } else {
        let generatedText;
        
        if (data.candidates) {
          generatedText = data.candidates[0]?.content?.parts?.[0]?.text;
        } else if (data.content) {
          generatedText = data.content[0]?.text;
        }
  
        if (generatedText) {
          const newAiMessage = {
            role: 'assistant',
            content: generatedText,
            timestamp: new Date().toISOString(),
            provider: provider,
            model: selectedModel
          };
          setMessages(prev => [...prev, newAiMessage]);
        } else {
          throw new Error(`Invalid response format from ${provider}`);
        }
      }
    } catch (error) {
      console.error('Error in generating content:', error);
      setError(`Failed to get response: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  

  const renderModelTooltip = (model) => (
    <Tooltip id={`tooltip-${model}`}>
      {MODEL_INFO[model] || 'AI language model'}
    </Tooltip>
  );

  return (
    <Container fluid className="chat-container">
      <Row className="h-100">
        <Col md={3} className="model-sidebar">
          <Card className="h-100 border-0">
            <Card.Body className="p-3">
              <Form.Group className="mb-3">
                <Form.Label>Select Model</Form.Label>
                <ModelSelector
                  selectedModel={selectedModel}
                  onModelSelect={handleModelSelect}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>

        <Col md={9} className="chat-main">
          <Card className="chat-card">     
            <Card.Body className="chat-messages">
              {error && (
                <Alert variant="danger" className="mb-3">
                  {error}
                </Alert>
              )}

              <div className="messages-container">
                {messages.map((message, index) => (
                  <MessageBubble key={index} message={message} />
                ))}
                {loading && (
                  <div className="ai-message">
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </Card.Body>

            <Card.Footer className="p-3">
              <Form onSubmit={handleSendMessage}>
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="Type your message..."
                    disabled={loading}
                  />
                  <Button 
                    type="submit"
                    variant={getProviderColor(provider)}
                    disabled={loading || !inputMessage.trim()}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      'Send'
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GPTTest;