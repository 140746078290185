import React, { useState } from 'react';
import { Card, Container, Row, Col, Button, Badge, Alert, Modal } from 'react-bootstrap';
import PricingModal from './PricingModal';

const SubscriptionManager = ({ subscription, onManageBilling, onCancelSubscription, onUpgrade }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    let date;
    if (timestamp.seconds) {
      date = new Date(timestamp.seconds * 1000);
    } else if (timestamp.toDate) {
      date = timestamp.toDate();
    } else if (typeof timestamp === 'string') {
      date = new Date(timestamp);
    } else {
      return 'Invalid Date';
    }

    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const calculateRemainingDays = (endDate) => {
    if (!endDate) return null;
    
    let end;
    if (endDate.seconds) {
      end = new Date(endDate.seconds * 1000);
    } else if (endDate.toDate) {
      end = endDate.toDate();
    } else if (typeof endDate === 'string') {
      end = new Date(endDate);
    } else {
      return null;
    }

    const now = new Date();
    const diffTime = end - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const renderStatusBadge = (status) => {
    const variants = {
      active: 'success',
      past_due: 'warning',
      canceled: 'danger',
      inactive: 'secondary',
    };

    return (
      <Badge bg={variants[status] || 'secondary'}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  const handleUpgradeClick = () => {
    onUpgrade();
  };

  const remainingDays = calculateRemainingDays(subscription?.currentPeriodEnd);

  return (
    <Container> 
      <Card className="border-none p-3"> 
        <Card.Body>
          {/* Status Section */}
          {subscription?.planId !== "free" && 
          <div className="mb-4">
            <h6>Account Status</h6>
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div>
                {renderStatusBadge(subscription?.status || 'inactive')}
                {/* {subscription?.status === 'active' && remainingDays && (
                  <span className="text-muted ms-2">
                    ({remainingDays} days remaining)
                  </span>
                )} */}
              </div>
              <div>
              {/* {subscription?.status === 'active' && subscription?.planId !== "free" && (
                    <Button
                      variant="outline-light"
                      onClick={onManageBilling}
                      size="sm"
                    >
                      Manage Billing
                    </Button>
                  )} */}
              </div>
            </div>
          </div>
          }

          {/* Plan Details */}
          <div className="mb-4">
            <h6>Current Plan</h6>
            <div className="d-flex justify-content-between align-items-center text-muted">
              <h5 className="mb-0">{subscription?.planId?.toUpperCase() || 'No active plan'}</h5>
              <span className="text-muted text-capitalize">{subscription?.interval || ''}</span>
            </div>
          </div>

          {/* Important Dates */}
          {subscription?.planId  != 'free' && (
            <div className="mb-4">
              <h6>Important Dates</h6>
              <Row className="mb-2 text-muted">
                <Col>Subscription Started:</Col>
                <Col className="text-end">
                  {formatDate(subscription?.currentPeriodStart)}
                </Col>
              </Row>
              <Row className="mb-2 text-muted">
                <Col>Current Period Ends:</Col>
                <Col className="text-end">
                  {formatDate(subscription?.currentPeriodEnd)}
                </Col>
              </Row> 
            </div>
          )}

          {/* Credits Section */}
          {subscription?.status === 'active' && (
            <div className="mb-4">
              <h6>Available Credits</h6>
              <Row className="mb-2 text-muted">
                <Col>Essential Messages:</Col>
                <Col className="text-end">
                  {subscription?.credits?.essential === -1 ? 'Unlimited' : subscription?.credits?.essential || 0}
                </Col>
              </Row>
              <Row className="mb-2 text-muted">
                <Col>Advanced Messages:</Col>
                <Col className="text-end">
                  {subscription?.credits?.advanced === -1 ? 'Unlimited' : subscription?.credits?.advanced || 0}
                </Col>
              </Row>
              <Row className="mb-2 text-muted">
                <Col>Image Generations:</Col>
                <Col className="text-end">
                  {subscription?.credits?.images || 0} per month
                </Col>
              </Row>
            </div>
          )}

          {/* Status Alerts */}
          {!subscription?.status || subscription?.planId === 'free' ? (
            <Alert variant="info">
              You don't have an active subscription. Upgrade now to access all features!
            </Alert>          
          ) : subscription?.status === 'canceled' && (
            <Alert variant="danger">
              Your subscription has been cancelled. Subscribe again to restore access to all features.
            </Alert>
          )}

          {/* Action Buttons */}
          {subscription?.planId != 'free' ? (
            <Row className="mt-4">
              <Col>
                <Button
                  variant="outline-primary"
                  className="w-100"
                  onClick={handleUpgradeClick}
                >
                  Upgrade Plan
                </Button>
              </Col>
              <Col>
                {/* <Button
                  variant="outline-primary"
                  className="w-100"
                  onClick={() => setShowCancelModal(true)}
                >
                  Cancel Subscription
                </Button> */}
                {subscription?.status === 'active' && subscription?.planId !== "free" && (
                    <Button
                      variant="outline-primary"
                      className="w-100"
                      onClick={onManageBilling} 
                    >
                      Cancel Subscription
                    </Button>
                  )}

              </Col>
            </Row>
          ) : (
            <Button
              variant="primary"
              className="w-100 mt-4"
              onClick={handleUpgradeClick}
            >
              {subscription?.status === 'canceled' ? 'Reactivate Subscription' : 'Upgrade Now'}
            </Button>
          )}
        </Card.Body>
      </Card>

      {/* Cancel Confirmation Modal */}
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Subscription?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel your subscription? You'll lose access to your current features and credits at the end of your billing period.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Keep Subscription
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onCancelSubscription();
              setShowCancelModal(false);
            }}
          >
            Yes, Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pricing Modal */}
      <PricingModal 
        show={showPricingModal} 
        onClose={() => setShowPricingModal(false)} 
      />
    </Container>
  );
};

export default SubscriptionManager;