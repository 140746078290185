import { db, storage } from "../../firebase";
import { 
  collection,
  query,
  orderBy,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  where,
  increment
} from "firebase/firestore";
import { 
  ref, 
  uploadString,
  getDownloadURL,
  deleteObject 
} from "firebase/storage";
import CreditService from '../aiChat/CreditService';

class ImageGenerationService {
  constructor() {
    this.imageCollection = 'generatedImages';
    this.userCreditsCollection = 'userCredits';
  }

  // Clean undefined and null values
  cleanData(obj) {
    const clean = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined && obj[key] !== null) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          clean[key] = this.cleanData(obj[key]);
        } else {
          clean[key] = obj[key];
        }
      }
    });
    return clean;
  }

  // Generate unique filename
  generateFileName(userId) {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000);
    return `${timestamp}_${random}`;
  }

  // Store image in Firebase Storage and Firestore
  // Update store generated image to handle credits
async storeGeneratedImage(userId, imageData, metadata, refreshCallback, onInsufficientCredits) {
  if (!userId || !imageData) {
    throw new Error('User ID and image data are required');
  }

  try {
    // First validate and deduct credits before generating
    //await CreditService.validateAndDeductCredits(userId, 'image', null, refreshCallback);


    // Then store the image
    const fileName = this.generateFileName(userId);
    const storageRef = ref(storage, `generated-images/${userId}/${fileName}`);
    
    const base64Data = imageData.split(',')[1] || imageData;
    await uploadString(storageRef, base64Data, 'base64');
    
    const downloadURL = await getDownloadURL(storageRef);    

    // Store metadata in Firestore
    const cleanedData = this.cleanData({
      userId,
      fileName,
      storageRef: `generated-images/${userId}/${fileName}`,
      downloadURL,
      prompt: metadata.prompt,
      model: metadata.model,
      provider: metadata.provider,
      format: metadata.format,
      width: metadata.width,
      height: metadata.height,
      settings: metadata.settings,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    const docRef = await addDoc(collection(db, this.imageCollection), cleanedData);

    return {
      id: docRef.id,
      ...cleanedData,
      downloadURL
    };

  } catch (error) {
    if (error.message.includes('Insufficient')) {
       // Call the callback to show pricing modal
       if (onInsufficientCredits) {
        onInsufficientCredits();
      }
      //throw new Error('Please purchase more image credits to continue generating images');
    }
    console.error('Error storing generated image:', error);
    throw error;
  }
}

  // Get single image data
  async getImage(imageId, userId) {
    try {
      const docRef = doc(db, this.imageCollection, imageId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const imageData = docSnap.data();
        if (imageData.userId !== userId) {
          throw new Error('Unauthorized access to image');
        }
        return {
          id: docSnap.id,
          ...imageData
        };
      }
      return null;
    } catch (error) {
      console.error('Error getting image:', error);
      throw error;
    }
  }

  // Get user's generated images with optional pagination
  async getUserImages(userId) {
    try {
      // Temporarily simplify the query until index is created
      const q = query(
        collection(db, this.imageCollection),
        where('userId', '==', userId)
      );
  
      const querySnapshot = await getDocs(q);
      const images = [];
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Handle potential null/undefined timestamps
        const timestamp = data.createdAt 
          ? (data.createdAt.toDate?.() || data.createdAt)
          : new Date();
          
        images.push({
          id: doc.id,
          ...data,
          timestamp: timestamp
        });
      });
  
      // Sort the images client-side for now
      return images.sort((a, b) => b.timestamp - a.timestamp);
    } catch (error) {
      console.error('Error getting user images:', error);
      if (error.message.includes('requires an index')) {
        // Extract index creation URL from error
        const match = error.message.match(/(https:\/\/console\.firebase\.google\.com\S+)/);
        const indexUrl = match ? match[0] : null;
        
        throw new Error(
          `Please create the required index first. ${
            indexUrl 
              ? `\nClick here to create it: ${indexUrl}`
              : ''
          }`
        );
      }
      throw error;
    }
  }

  // Delete image from both Storage and Firestore
  async deleteImage(imageId, userId) {
    try {
      const docRef = doc(db, this.imageCollection, imageId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Image not found');
      }

      const imageData = docSnap.data();
      if (imageData.userId !== userId) {
        throw new Error('Unauthorized access to image');
      }

      // Delete from Storage
      const storageRef = ref(storage, imageData.storageRef);
      await deleteObject(storageRef);

      // Delete from Firestore
      await deleteDoc(docRef);

      return true;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  }

  // Delete multiple images
  async deleteMultipleImages(imageIds, userId) {
    try {
      const deletePromises = imageIds.map(imageId => this.deleteImage(imageId, userId));
      await Promise.all(deletePromises);
      return true;
    } catch (error) {
      console.error('Error deleting multiple images:', error);
      throw error;
    }
  }

  // Update image metadata
  async updateImageMetadata(imageId, userId, updates) {
    try {
      const docRef = doc(db, this.imageCollection, imageId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Image not found');
      }

      const imageData = docSnap.data();
      if (imageData.userId !== userId) {
        throw new Error('Unauthorized access to image');
      }

      const cleanUpdates = this.cleanData({
        ...updates,
        updatedAt: serverTimestamp()
      });

      await updateDoc(docRef, cleanUpdates);
      return true;
    } catch (error) {
      console.error('Error updating image metadata:', error);
      throw error;
    }
  }

  // Get total number of images for a user
  async getUserImageCount(userId) {
    try {
      const q = query(
        collection(db, this.imageCollection),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      console.error('Error getting user image count:', error);
      throw error;
    }
  }
  
  // Update user credits method
async updateUserCredits(userId, creativeCredits) {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    // Get reference to subscription document
    const subscriptionRef = doc(db, 'subscription', userId);
    const subscriptionSnap = await getDoc(subscriptionRef);

    if (subscriptionSnap.exists()) {
      // Update existing credits document with decrement
      await updateDoc(subscriptionRef, {
        'credits.images': increment(-1) // Decrement images credit by 1
      });

      // Return updated credits
      const updatedDoc = await getDoc(subscriptionRef);
      return updatedDoc.exists() ? updatedDoc.data() : null;
    } else {
      throw new Error('Subscription document not found');
    }

  } catch (error) {
    console.error('Error updating user credits:', error);
    throw error;
  }
}

}

export default new ImageGenerationService();