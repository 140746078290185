const TravelData = {
    itineraryGenerator: {
        prompt: "51001",
        icon: "🗺️",
        title: "Itinerary Generator",
        category: "Travel Planning",
        caption: "Create detailed daily itineraries for any city or destination.",
        inputs: [
            { inputTitle: 'Destination', inputCaption: 'City or location to visit.', type: 'text', inputExample: "Paris, France" },
            { inputTitle: 'Duration', inputCaption: 'Length of stay.', type: 'text', inputExample: "5 days" },
            { inputTitle: 'Interests', inputCaption: 'Preferred activities and interests.', type: 'textarea', inputExample: "Art, History, Food" },
            { inputTitle: 'Pace', inputCaption: 'Preferred pace of travel.', type: 'text', inputExample: "Relaxed/Moderate/Fast" }
        ]
    },

    travelPlanCreator: {
        prompt: "51002",
        icon: "✈️",
        title: "Travel Plan Creator",
        category: "Travel Planning",
        caption: "Generate comprehensive travel plans including transportation, accommodation, and activities.",
        inputs: [
            { inputTitle: 'Destinations', inputCaption: 'List of places to visit.', type: 'textarea', inputExample: "London, Paris, Rome" },
            { inputTitle: 'Total Duration', inputCaption: 'Total length of trip.', type: 'text', inputExample: "14 days" },
            { inputTitle: 'Budget', inputCaption: 'Approximate budget per person.', type: 'text', inputExample: "$3000" },
            { inputTitle: 'Travel Style', inputCaption: 'Preferred style of travel.', type: 'text', inputExample: "Luxury/Comfort/Budget" }
        ]
    },

    eventPlanningAssistant: {
        prompt: "51003",
        icon: "🎉",
        title: "Event Planning Assistant",
        category: "Travel Planning",
        caption: "Plan detailed events with timelines, vendors, and logistics.",
        inputs: [
            { inputTitle: 'Event Type', inputCaption: 'Type of event to plan.', type: 'text', inputExample: "Wedding" },
            { inputTitle: 'Guest Count', inputCaption: 'Number of expected guests.', type: 'text', inputExample: "100" },
            { inputTitle: 'Budget', inputCaption: 'Total event budget.', type: 'text', inputExample: "$15000" },
            { inputTitle: 'Date', inputCaption: 'Event date or timeframe.', type: 'text', inputExample: "June 15, 2024" }
        ]
    },

    dietPlanGenerator: {
        prompt: "51004",
        icon: "🥗",
        title: "Diet Plan Generator",
        category: "Travel Planning",
        caption: "Create personalized diet plans based on goals and preferences.",
        inputs: [
            { inputTitle: 'Goal', inputCaption: 'Primary health/fitness goal.', type: 'text', inputExample: "Weight loss" },
            { inputTitle: 'Dietary Restrictions', inputCaption: 'Any food restrictions or preferences.', type: 'textarea', inputExample: "Vegetarian, no nuts" },
            { inputTitle: 'Current Weight', inputCaption: 'Current weight and target.', type: 'text', inputExample: "180lbs, target: 160lbs" },
            { inputTitle: 'Activity Level', inputCaption: 'Daily activity level.', type: 'text', inputExample: "Moderately active" }
        ]
    },

    mealPlanner: {
        prompt: "51005",
        icon: "🍽️",
        title: "Meal Planner",
        category: "Travel Planning",
        caption: "Plan weekly meals with shopping lists and prep instructions.",
        inputs: [
            { inputTitle: 'Meals Per Day', inputCaption: 'Number of meals to plan.', type: 'text', inputExample: "3 meals + 2 snacks" },
            { inputTitle: 'Dietary Requirements', inputCaption: 'Specific diet or restrictions.', type: 'textarea', inputExample: "Low-carb, high-protein" },
            { inputTitle: 'Cooking Time', inputCaption: 'Available time for cooking.', type: 'text', inputExample: "30 minutes per meal" },
            { inputTitle: 'Servings', inputCaption: 'Number of people to cook for.', type: 'text', inputExample: "Family of 4" }
        ]
    },

    scheduleOrganizer: {
        prompt: "51006",
        icon: "📅",
        title: "Schedule Organizer",
        category: "Travel Planning",
        caption: "Create organized daily, weekly, or monthly schedules.",
        inputs: [
            { inputTitle: 'Schedule Type', inputCaption: 'Type of schedule needed.', type: 'text', inputExample: "Weekly" },
            { inputTitle: 'Activities', inputCaption: 'List of activities to schedule.', type: 'textarea', inputExample: "Work, gym, study, social" },
            { inputTitle: 'Priority Level', inputCaption: 'Priority of each activity.', type: 'textarea', inputExample: "Work: High, Social: Medium" },
            { inputTitle: 'Time Constraints', inputCaption: 'Any specific time constraints.', type: 'textarea', inputExample: "Work: 9-5, Gym: mornings" }
        ]
    },

    travelBudgetCalculator: {
        prompt: "51007",
        icon: "💰",
        title: "Travel Budget Calculator",
        category: "Travel Planning",
        caption: "Calculate detailed travel budgets with cost breakdowns.",
        inputs: [
            { inputTitle: 'Destination(s)', inputCaption: 'Places to visit.', type: 'textarea', inputExample: "Tokyo, Kyoto" },
            { inputTitle: 'Duration', inputCaption: 'Length of stay.', type: 'text', inputExample: "10 days" },
            { inputTitle: 'Travel Style', inputCaption: 'Preferred level of comfort.', type: 'text', inputExample: "Mid-range" },
            { inputTitle: 'Activities', inputCaption: 'Planned activities and tours.', type: 'textarea', inputExample: "Museum visits, food tour, temple tour" }
        ]
    },

    accommodationFinder: {
        prompt: "51008",
        icon: "🏨",
        title: "Accommodation Finder",
        category: "Travel Planning",
        caption: "Find suitable accommodation based on preferences and requirements.",
        inputs: [
            { inputTitle: 'Location', inputCaption: 'Desired location.', type: 'text', inputExample: "Downtown Barcelona" },
            { inputTitle: 'Budget', inputCaption: 'Per night budget.', type: 'text', inputExample: "$150" },
            { inputTitle: 'Requirements', inputCaption: 'Specific needs or preferences.', type: 'textarea', inputExample: "WiFi, pool, kitchen" },
            { inputTitle: 'Duration', inputCaption: 'Length of stay.', type: 'text', inputExample: "5 nights" }
        ]
    },

    localGuideGenerator: {
        prompt: "51009",
        icon: "🗺️",
        title: "Local Guide Generator",
        category: "Travel Planning",
        caption: "Create personalized local guides with insider tips and recommendations.",
        inputs: [
            { inputTitle: 'City', inputCaption: 'City to explore.', type: 'text', inputExample: "Prague" },
            { inputTitle: 'Interests', inputCaption: 'Areas of interest.', type: 'textarea', inputExample: "History, local food, photography" },
            { inputTitle: 'Duration', inputCaption: 'Time available for exploration.', type: 'text', inputExample: "3 days" },
            { inputTitle: 'Experience Type', inputCaption: 'Desired type of experiences.', type: 'text', inputExample: "Off the beaten path" }
        ]
    },

    activityPlanner: {
        prompt: "51010",
        icon: "🎯",
        title: "Activity Planner",
        category: "Travel Planning",
        caption: "Plan detailed activities and experiences for your trip.",
        inputs: [
            { inputTitle: 'Location', inputCaption: 'Where activities will take place.', type: 'text', inputExample: "Bali" },
            { inputTitle: 'Activity Types', inputCaption: 'Preferred types of activities.', type: 'textarea', inputExample: "Adventure, culture, relaxation" },
            { inputTitle: 'Group Size', inputCaption: 'Number of participants.', type: 'text', inputExample: "4 adults" },
            { inputTitle: 'Physical Level', inputCaption: 'Physical activity level.', type: 'text', inputExample: "Moderate" }
        ]
    }
};

export default TravelData;