import React from 'react';
import openaiIcon from './images/openai.jpeg';
import claudeIcon from './images/claude.jpeg';
import geminiIcon from './images/gemini.jpeg';
import perplexityIcon from './images/perplexity.jpeg';
import mistralIcon from './images/mistral.jpeg';
import llamaIcon from './images/llama.jpeg';

const AIModelDisplay = ({ onModelClick }) => {
  const models = [
    { name: 'Claude', icon: claudeIcon },
    { name: 'Gemini', icon: geminiIcon },
    { name: 'Llama', icon: llamaIcon },
    { name: 'Mistral', icon: mistralIcon },
    { name: 'OpenAI', icon: openaiIcon },
    { name: 'Perplexity', icon: perplexityIcon }
  ];

  return (
    <div className="ai-models-logos">
        <h6>Start chat with your favorite ai model</h6>
      <div className="logos-wrap">
        {models.map((model) => (
          <div key={model.name} className="logo-items"> 
              <img
                src={model.icon}
                alt={`${model.name} logo`}
                className="w-full h-full object-cover"
                onClick={onModelClick}
              /> 
            <span className="text-sm text-gray-600 font-medium">{model.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AIModelDisplay;