import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Form, Badge, Alert, Container, Spinner } from 'react-bootstrap';
import { Bot, Paperclip, Send, X } from 'lucide-react'; 
import { useUserAuth, refreshSubscription } from "../../context/UserAuthContext";
import { ModelSelector, getProviderIcon } from './ModelSelector';
import SystemPromptSettings from './SystemPromptSettings';
import * as chatService from './AIChatServices';
import AgentFirebaseService from './agent/AgentFirebaseService'; 
import './AIChat.css';
import ChatMessage from './ChatMessage';  
import ChatInput from './ChatInput';  
import ChatLanding from './ChatLanding';
import ThinkingIndicator from './ThinkingIndicator';
import AgentSelector from './agent/AgentSelector';
import PricingModal from '../pricing/PricingModal';
import AIModelDisplay from './AIModelDisplay'; 
import InlineLogin from '../InlineLogin'; 
import ModelSelectorDrawer from './ModelSelectorDrawer'; 
import AgentSelectorDrawer from './AgentSelectorDrawer';
import CombinedModelAgentSelector from './CombinedModelAgentSelector';

const ChatLayout = ({ isLanding, children }) => {
  return (
    <div className={`chat-layout ${isLanding ? 'landing-mode' : 'chat-mode'}`}>
      {children}
    </div>
  );
};

// Main AIChat Component
const AIChat = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { user, refreshSubscription } = useUserAuth();
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);  
  const [error, setError] = useState(null);
  const [selectedModel, setSelectedModel] = useState('gemini-1.5-flash');
  const [showSidebar, setShowSidebar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isNewChat, setIsNewChat] = useState(false);
  const messagesEndRef = useRef(null);
  const [recentChats, setRecentChats] = useState([]);
  const [systemPrompt, setSystemPrompt] = useState("");
  const location = useLocation();
  
  const [favoriteAgents, setFavoriteAgents] = useState([]);
  const [isLoadingFavorites, setIsLoadingFavorites] = useState(false);
  const [favoriteAgentCache, setFavoriteAgentCache] = useState({});

  const [isLandingView, setIsLandingView] = useState(true);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const [showModelDrawer, setShowModelDrawer] = useState(false);
  const [showAgentDrawer, setShowAgentDrawer] = useState(false);
  const [showCombinedSelector, setShowCombinedSelector] = useState(false);

  useEffect(() => {
    const handleClearChat = () => {
      setMessages([]);
      setSelectedChat(null);
      setIsNewChat(true);
      setFiles([]);
      setError(null);
      setSystemPrompt("");
      setSelectedModel('gemini-1.5-flash');
    };

    window.addEventListener('clearChat', handleClearChat);
    return () => window.removeEventListener('clearChat', handleClearChat);
  }, []);
  
  useEffect(() => {
    // Set landing view when no chatId or explicitly at /chat
    setIsLandingView(!chatId || location.pathname === '/chat');
  }, [chatId, location.pathname]);

  // Load chat from URL parameter
  useEffect(() => {
    if (!chatId || location.pathname === '/chat') {
      // If no chat ID, clear everything
      setMessages([]);
      setSelectedChat(null);
      setIsNewChat(true);
      setFiles([]);
      setSystemPrompt("");      
      setSelectedModel('gemini-1.5-flash');
      setError(null);
    } else if (user) {
      // Load existing chat
      setLoading(true); // Add loading state
      chatService.fetchChatById(chatId, user.uid)
        .then(chat => {
          setSelectedChat(chat);
          setIsNewChat(false);
          if (chat.metadata?.systemPrompt) {
            setSystemPrompt(chat.metadata.systemPrompt);
          } else {
            setSystemPrompt("");  
          }
          if (chat.metadata?.model) {
            setSelectedModel(chat.metadata.model);
          }
        }) 
        .catch(error => {
          console.error('Error loading chat:', error);
          if (error.message === 'Chat not found or unauthorized') {
            setError('This chat is not available or you don\'t have permission to access it');
          } else {
            setError('Failed to load chat. Please try again.');
          }
          navigate('/chat');
        })
        .finally(() => {
          setLoading(false);
        });
    }
}, [chatId, user, navigate, location.pathname]);

  // Mobile responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setShowSidebar(window.innerWidth >= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [allAgents, setAllAgents] = useState([]);

  // Add this new useEffect to fetch favorite agents
  useEffect(() => {
    const loadAgentsData = async () => {
      if (!user?.uid) return; 
      try {
        // First load all agents
        const allAgentsData = await AgentFirebaseService.getAgents(user.uid);
        //console.log('All agents loaded:', allAgentsData);
        setAllAgents(allAgentsData);
  
        // Then set favorite agents from the loaded agents
        const favoriteAgentsData = allAgentsData.filter(agent => agent.isFavorite);
        //console.log('Favorite agents filtered:', favoriteAgentsData);
        setFavoriteAgents(favoriteAgentsData);
      } catch (error) {
        console.error('Error loading agents:', error);
        setError('Failed to load agents');
      } finally {
        //setLoadingAgents(false);
      }
    };
  
    loadAgentsData();
  }, [user?.uid]);

  // Fetch chats
  useEffect(() => {
    if (!user) {
      setChats([]);
      setRecentChats([]);
      setSelectedChat(null);
      return;
    }
  
    const unsubscribe = chatService.listenToChats(
      user.uid,
      (allChats) => {
        setChats(allChats);
        setRecentChats(allChats.slice(0, 10)); // Get only recent 10 chats
      },
      setError
    );
  
    return () => unsubscribe();
  }, [user]);

  // Fetch messages
  useEffect(() => {
    if (!selectedChat?.id) return;
    
    const unsubscribe = chatService.listenToMessages(
      selectedChat.id,
      messages => {
        setMessages(messages);
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    );
    
    return () => unsubscribe();
  }, [selectedChat]);

  const handleNewChat = () => {
    setSelectedChat(null);
    setMessages([]);
    setIsNewChat(true);
    navigate('/chat');
    if (isMobile) setShowSidebar(false);
  };

  const handleChatSelect = (chat) => {
    setIsNewChat(false);
    setSelectedChat(chat);    
    setSystemPrompt(chat.metadata?.systemPrompt || "");
    navigate(`/chat/${chat.id}`);
    if (isMobile) setShowSidebar(false);
  };

  const handleDeleteChat = async (chatId, e) => {
    e?.stopPropagation();
    try {
      await chatService.deleteChat(chatId, user.uid);
      if (selectedChat?.id === chatId) {
        setSelectedChat(null);
        setMessages([]);
        setIsNewChat(false);
        navigate('/chat');
      }
    } catch (error) {
      console.error('Delete chat error:', error);
      setError('Failed to delete chat: ' + error.message);
    }
  };

    // Add handler for agent selection
    const handleAgentSelect = async (agent) => {
      if (!user?.uid) return;
  
      try {
        const chatId = await AgentFirebaseService.createAgentChat(agent, user.uid);
        navigate(`/chat/${chatId}`);
      } catch (error) {
        console.error('Error creating chat with agent:', error);
        setError('Failed to create chat: ' + error.message);
      }
    };

  const handleSubmit = async (text) => {
    if (!user) {
      setError('Please sign in to send messages');
      return;
    }

    if (!text.trim() && files.length === 0) {
      return;
    }

    setMessageLoading(true);
    try {
      let currentChatId = selectedChat?.id;

      if (!currentChatId) {
        // Create new chat
        currentChatId = await chatService.createChat(text, user.uid, selectedModel);
        if (!currentChatId) return;
        
        // Fetch and set the newly created chat
        const newChat = await chatService.fetchChatById(currentChatId, user.uid);
        setSelectedChat(newChat);
        setIsNewChat(false);
        navigate(`/chat/${currentChatId}`);
      }

      await chatService.sendMessage({
        text,
        files,
        chatId: currentChatId,
        userId: user.uid,
        model: selectedModel,
        systemPrompt,
        refreshSubscription,
        metadata: {
          agent: selectedChat?.metadata?.agent || { name: 'Default Agent' },
        },
        onInsufficientCredits: () => setShowPricingModal(true) 
      });

      setFiles([]);
    } catch (err) {
      console.error('Submit message error:', err);
      setError(err.message);
    } finally {
      setMessageLoading(false);
    }
};
const handleRetry = async (text) => {
  if (!user) {
    setError('Please sign in to send messages');
    return;
  }

  setMessageLoading(true);
  try {
    await handleSubmit(text); // This will add a new response
  } catch (err) {
    console.error('Retry message error:', err);
    setError(err.message);
  } finally {
    setMessageLoading(false);
  }
};
// Add these states in the AIChat component
const [showInlineLogin, setShowInlineLogin] = useState(false);
const [pendingMessage, setPendingMessage] = useState('');

// Add this handler
const handleLoginSuccess = (user) => {
  if (pendingMessage) {
    handleSubmit(pendingMessage);
    setPendingMessage('');
  }
};

  // Render authentication gate
  // if (!user) {
  //   return (
  //     <div className="chat-container">
  //       <div className="empty-state">
  //         Please sign in to use the chat
  //       </div>
  //     </div>
  //   );
  // }

  if (loading) {
    return (
      <div className="chat-container">
        <div className="empty-state text-center">
          <Spinner animation="border" variant="primary" className="mb-3" />
          <p className="text-muted mb-0">Loading...</p>
        </div>
      </div>
    );
  }

  // Main render
  return (
    <div className="chat-container">
      <div className="chat-main">
      <div className="chat-header">
  <div className='chat-head-content'>
  <div className='selector-container me-3 mt-3'>
  <Button
    variant="primary-outline"
    className="combined-selector-btn d-flex align-items-center gap-2"
    onClick={() => setShowCombinedSelector(true)}
  > 
    {selectedChat?.metadata?.agent ? (
      <>
        <Bot size={16} />
        {selectedChat.metadata.agent.name}
      </>
    ) : selectedModel ? (
      <>
        <img
          src={getProviderIcon(selectedModel.split('-')[0])}
          alt="Model icon"
          className="model-icon"
          style={{ width: '20px', height: '20px' }}
        />
        {selectedModel}
      </>
    ) : (
      'Select AI Model / Agent'
    )}
  </Button>

  <CombinedModelAgentSelector
    show={showCombinedSelector}
    onHide={() => setShowCombinedSelector(false)}
    onModelSelect={(model) => setSelectedModel(model)}
    onAgentSelect={handleAgentSelect}
    selectedModel={selectedModel}
    selectedAgent={selectedChat?.metadata?.agent}
    allAgents={allAgents}
    favoriteAgents={favoriteAgents}
  />
</div>
  </div>

</div>
        <ChatLayout isLanding={isLandingView}>
          <div className="messages">
            {isLandingView ? (              
              <Container className="landing-content">                
                  <div className='mb-4 text-center'>
                    <div>
                   {selectedModel && <div className='d-flex flex-column align-items-center land-ai-model' onClick={() => setShowCombinedSelector(true)}>
                    <img
                      src={getProviderIcon(selectedModel.split('-')[0])}
                      alt="Model icon"
                      className="model-icon"
                      style={{ width: '40px', height: '40px' }}
                    />
                    <h6 className='ai-model-name my-2'>{selectedModel}</h6>
                   </div>}
                    </div>
                  <h1 class="landing-title mb-2">What can I help with?</h1>
                  {/* <p class="landing-des lead text-muted">Access world-class AI models and specialized agents to enhance your potential.</p> */}
                  </div>
                <div className="chat-input-centered">
                  <ChatInput
                    // onSubmit={handleSubmit}
                    onSubmit={(text) => {
                      if (!user) {
                        setPendingMessage(text);
                        setShowInlineLogin(true);
                        return;
                      }
                      handleSubmit(text);
                    }}
                    loading={loading}
                    files={files}
                    onFileAdd={newFiles => setFiles([...files, ...newFiles])}
                    onFileRemove={index => setFiles(files.filter((_, i) => i !== index))}
                  /> 
                </div>
                <div className="agents-collection mt-4"> 
                  <div className="agent-content">
                  {favoriteAgents.slice(0, 4).map(agent => (
                      <div 
                        key={agent.id} 
                        className="agents"
                        onClick={() => handleAgentSelect(agent)}
                      >
                        {agent.name}
                      </div>
                    ))}
                  </div>
                    
                    <AIModelDisplay onModelClick={() => setShowCombinedSelector(true)} />
                </div>
              </Container>
            ) : (
              <>
               {messages.map((msg, index) => (
                  <ChatMessage
                    key={msg.id}
                    message={msg}
                    messages={messages}
                    isAgentChat={selectedChat?.metadata?.isAgentChat === true} 
                    agent={selectedChat?.metadata?.agent} 
                    onRetry={handleRetry}
                    isLastMessage={index === messages.length - 1 && msg.sender === 'ai'}
                  />
                ))}
                {messageLoading && <div><ThinkingIndicator isLoading={messageLoading} /></div>}
              </>
            )}
            <div ref={messagesEndRef} />
          </div>
          
          {!isLandingView && (
            <div className="chat-input-bottom">
              <ChatInput
                // onSubmit={handleSubmit}
                onSubmit={(text) => {
                  if (!user) {
                    setPendingMessage(text);
                    setShowInlineLogin(true);
                    return;
                  }
                  handleSubmit(text);
                }}
                loading={messageLoading}
                files={files}
                onFileAdd={newFiles => setFiles([...files, ...newFiles])}
                onFileRemove={index => setFiles(files.filter((_, i) => i !== index))}
              />
            </div>
          )}
        </ChatLayout>

        {error && (
          <Alert 
            variant="danger" 
            onClose={() => setError(null)} 
            dismissible
            className="error-alert"
          >
            {error}
          </Alert>
        )}
          <PricingModal 
            show={showPricingModal} 
            onClose={() => {
              setShowPricingModal(false);
              setError('');
            }}
          />
          <InlineLogin
  show={showInlineLogin}
  onHide={() => setShowInlineLogin(false)}
  onLoginSuccess={handleLoginSuccess}
/>
      </div>
    </div>
  );
};

export default AIChat;