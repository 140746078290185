import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const ThinkingIndicator = ({ isLoading }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  
  const thinkingMessages = [
    "Analyzing your request...",
    "Thinking deeply, stand by...",
    "Processing information...",
    "Generating response..."
  ];
  
  useEffect(() => {
    if (!isLoading) return;
    
    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % thinkingMessages.length);
    }, 2000);
    
    return () => clearInterval(interval);
  }, [isLoading]);
  
  if (!isLoading) return null;
  
  return (
    <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
      {/* Loading dots */}
      <div className="flex space-x-1">
        <div 
          className="w-2 h-2 bg-blue-600 rounded-full animate-bounce" 
          style={{ animationDelay: '0ms' }}
        />
        <div 
          className="w-2 h-2 bg-blue-600 rounded-full animate-bounce" 
          style={{ animationDelay: '150ms' }}
        />
        <div 
          className="w-2 h-2 bg-blue-600 rounded-full animate-bounce" 
          style={{ animationDelay: '300ms' }}
        />
      </div>
      {/* Message text */}
      <span className="text-gray-700 font-small font-italic thinking">
         <Spinner animation="border" role="status" size="sm" className='m-0'>         
        </Spinner>
        <span className='pt-1 text-muted text'>{thinkingMessages[messageIndex]}</span>
      </span>
    </div>
  );
};

export default ThinkingIndicator;