import React, { useState, useRef, useEffect } from 'react';
import { 
  Container, 
  Form, 
  Button, 
  Spinner,
  Table,
  Pagination,
  Image
} from 'react-bootstrap'; 
import { ArrowUp, ArrowDown, Download, SlidersHorizontal } from 'lucide-react';
import './KeywordResearch.css';
import { 
  COUNTRY_CODES,
  getCountryName,
  getCountryFlagUrl,
  searchCountries,
  POPULAR_COUNTRIES 
} from './countryData';
import { 
  LANGUAGE_CODES,
  getLanguageName,
  getLanguageCode,
  searchLanguages,
  POPULAR_LANGUAGES 
} from './languageData';
import * as XLSX from 'xlsx';

const ITEMS_PER_PAGE = 50;

    
const KeywordResearch = () => {
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('US');
  const [countrySearch, setCountrySearch] = useState('');
  const [lang, setLang] = useState('en');
  const [langSearch, setLangSearch] = useState('');
  const [minSearchVol, setMinSearchVol] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const [showLangDropdown, setShowLangDropdown] = useState(false);
  const countryDropdownRef = useRef(null);
  const langDropdownRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [originalResults, setOriginalResults] = useState([]); // Store unfiltered data

  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState({
    minVolume: '',
    maxVolume: '',
    competition: '',
    keywordContain: ''
  });

  useEffect(() => {
    if (filters.maxVolume && filters.minVolume && parseInt(filters.maxVolume) < parseInt(filters.minVolume)) {
      return;  
    }
    applyFilters();  
  }, [filters]);  
  

  const getCompetitionColor = (value) => {
    switch(value?.toUpperCase()) {
      case 'HIGH':
        return 'text-danger';
      case 'MEDIUM':
        return 'text-warning';
      case 'LOW':
        return 'text-success';
      default:
        return '';
    }
  };

  const applyFilters = () => {
    let filteredResults = [...originalResults]; // Use the unfiltered data
  
    if (filters.minVolume) {
      filteredResults = filteredResults.filter(item => item.volume >= filters.minVolume);
    }
    if (filters.maxVolume) {
      filteredResults = filteredResults.filter(item => item.volume <= filters.maxVolume);
    }
    if (filters.competition) {
      filteredResults = filteredResults.filter(
        item => item.competition_level.toLowerCase() === filters.competition.toLowerCase()
      );
    }
    if (filters.keywordContain) {
      filteredResults = filteredResults.filter(item =>
        item.text.toLowerCase().includes(filters.keywordContain.toLowerCase())
      );
    }
  
    setResults(filteredResults); // Update the displayed results
    setCurrentPage(1); // Reset to the first page
  };
  
  
  const clearAllFilters = () => {
    const resetFilters = {
      minVolume: '',
      maxVolume: '',
      competition: '',
      keywordContain: '',
    };
    setFilters(resetFilters);
    applyFilters(resetFilters); // Apply the reset filters to update the table
  };
  
  

  
  const filteredCountries = Object.entries(COUNTRY_CODES)
    .filter(([code, name]) => 
      !countrySearch || 
      name.toLowerCase().includes(countrySearch.toLowerCase()) ||
      code.toLowerCase().includes(countrySearch.toLowerCase())
    );

  const filteredLanguages = Object.entries(LANGUAGE_CODES)
    .filter(([code, name]) => 
      !langSearch || 
      name.toLowerCase().includes(langSearch.toLowerCase()) ||
      code.toLowerCase().includes(langSearch.toLowerCase())
    );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getSortedResults = () => {
    if (!results.length) return [];
    
    let sortedData = [...results];
    if (sortField) {
      sortedData.sort((a, b) => {
        switch(sortField) {
          case 'volume':
            return sortDirection === 'asc' 
              ? a.volume - b.volume 
              : b.volume - a.volume;
          case 'competition':
            const competitionOrder = { 'LOW': 1, 'MEDIUM': 2, 'HIGH': 3 };
            const valueComparison = 
              competitionOrder[a.competition_level?.toUpperCase()] - competitionOrder[b.competition_level?.toUpperCase()];
            return sortDirection === 'asc' ? valueComparison : -valueComparison;
          default:
            return 0;
        }
      });
    }
    return sortedData;
  };

  const getPaginatedResults = () => {
    const sortedResults = getSortedResults();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return sortedResults.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };
  const totalPages = Math.ceil(getSortedResults().length / ITEMS_PER_PAGE);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (countryDropdownRef.current && !countryDropdownRef.current.contains(event.target)) {
        setShowCountryDropdown(false);
      }
      if (langDropdownRef.current && !langDropdownRef.current.contains(event.target)) {
        setShowLangDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = async () => {
    if (!keyword.trim()) return;
    
    setLoading(true);
    setError(null);
    setCurrentPage(1);
    
    try {
      const local = `http://localhost:3001/api/keywords`;
      const dev = 'https://wk-server.vercel.app/api/keywords';
      const response = await fetch(dev, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          keyword,
          location,
          lang,
          min_search_vol: minSearchVol ? parseInt(minSearchVol) : undefined,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch keyword data');
      }
  
      const data = await response.json();
      setOriginalResults(data); // Save unfiltered data
      setResults(data); // Set the initial results
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  

  const handleDownloadExcel = () => {
    // Prepare the data for export
    const worksheetData = results.map(item => ({
      Keyword: item.text,
      Volume: item.volume,
      Competition: item.competition_level,
      Trend: item.trend,
      'Low Bid': item.low_bid,
      'High Bid': item.high_bid,
    }));
  
    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Keywords');
  
    // Generate a file and trigger the download
    XLSX.writeFile(workbook, 'KeywordData.xlsx');
  };
  
    
  return (
    <Container className="keyword-research-container">
      <h2 className="tool-title page-title">Keyword Research</h2>      
      <Form className="keyword-form">
        <div className="input-row">
          <div className="input-group d-flex ">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Enter a keyword..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>

          <div className="input-group country-select d-flex" ref={countryDropdownRef}>         
            <div className="input-wrapper">
              {location && (
                <Image 
                  src={getCountryFlagUrl(location)}
                  width="24"
                  height="18"
                  alt={`${getCountryName(location)} flag`}
                  className="input-icon"
                />
              )}
              <input
                type="text"
                className="form-control search-input with-icon"
                placeholder="Country..."
                value={countrySearch}
                onChange={(e) => {
                  setCountrySearch(e.target.value);
                  setShowCountryDropdown(true);
                }}
                onFocus={() => setShowCountryDropdown(true)}
              />
            </div>
            {showCountryDropdown && (
              <div className="custom-dropdown">
                {filteredCountries.map(([code, name]) => (
                  <div
                    key={code}
                    className="dropdown-item"
                    onClick={() => {
                      setLocation(code);
                      setCountrySearch(`${name} (${code})`);
                      setShowCountryDropdown(false);
                    }}
                  >
                    <Image 
                      src={getCountryFlagUrl(code)}
                      width="24"
                      height="18"
                      alt={`${name} flag`}
                      className="me-2"
                    />
                    {name} ({code})
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="input-group lang-select d-none" ref={langDropdownRef}>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Language..."
              value={langSearch}
              onChange={(e) => {
                setLangSearch(e.target.value);
                setShowLangDropdown(true);
              }}
              onFocus={() => setShowLangDropdown(true)}
            />
            {showLangDropdown && (
              <div className="custom-dropdown">
                {filteredLanguages.map(([code, name]) => (
                  <div
                    key={code}
                    className="dropdown-item"
                    onClick={() => {
                      setLang(code);
                      setLangSearch(`${name} (${code})`);
                      setShowLangDropdown(false);
                    }}
                  >
                    {name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="input-group d-none">
            <input
              type="number"
              className="form-control search-input"
              placeholder="Min volume..."
              value={minSearchVol}
              onChange={(e) => setMinSearchVol(e.target.value)}
            />
          </div>

          <Button 
            variant="primary" 
            onClick={handleSearch}
            disabled={loading || !keyword.trim()}
            className="search-button"
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Searching...
              </>
            ) : (
              'Search Keywords'
            )}
          </Button>
        </div>
      </Form>

      {error && (
        <div className="alert alert-danger mt-3">
          {error}
        </div>
      )}

    

      {results.length > 0 && (
        <div className="results-section">
          <div className="results-header d-flex justify-content-between">
            <div>
              Showing {(currentPage - 1) * ITEMS_PER_PAGE + 1} - {Math.min(currentPage * ITEMS_PER_PAGE, results.length)} of {results.length} results
            </div>
            <div className='d-flex justify-content-between gap-3 aligne-content-center'>
            <div className="active-filters">
  {filters.minVolume && (
    <span className="filter-tag">
      Volume-min: {filters.minVolume}
      <button
  className="clear-filter"
  onClick={() => setFilters({ ...filters, minVolume: '' })}
>
  X
</button>
    </span>
  )}
  {filters.maxVolume && (
    <span className="filter-tag">
      Volume-max: {filters.maxVolume}
      <button
        className="clear-filter"
        onClick={() => setFilters({ ...filters, maxVolume: '' })}
      >
        X
      </button>
    </span>
  )}
  {filters.competition && (
    <span className="filter-tag">
      Competition: {filters.competition}
      <button
        className="clear-filter"
        onClick={() => setFilters({ ...filters, competition: '' })}
      >
        X
      </button>
    </span>
  )}
  {filters.keywordContain && (
    <span className="filter-tag">
      Contains: {filters.keywordContain}
      <button
        className="clear-filter"
        onClick={() => setFilters({ ...filters, keywordContain: '' })}
      >
        X
      </button>
    </span>
  )}
</div>


            <Button 
                    variant="secondary-outline" 
                    onClick={() => setFilterVisible(!filterVisible)}
                    className="m-0 p-0 shadow-none float-right btn btn-link"
                >
                   <SlidersHorizontal size={16}/>  {filterVisible ? "Hide Filters" : "Show Filters"}
                </Button>
                <Button
    variant="secondary-outline"
    onClick={handleDownloadExcel}
    className="m-0 p-0 shadow-none float-right btn btn-link"
  >
    <Download size={18} />
  </Button>
            </div>
          </div>
          <div>   

{filterVisible && (
  <div className="filter-box mt-2 mb-3 d-flex">
    <div className="input-group filter-form">
      <input
        type="number"
        placeholder="Min Volume"
        value={filters.minVolume}
        onChange={(e) => setFilters({ ...filters, minVolume: e.target.value })}
        className="form-control"
      />
      <input
        type="number"
        placeholder="Max Volume"
        value={filters.maxVolume}
        onChange={(e) => setFilters({ ...filters, maxVolume: e.target.value })}
        className="form-control"
      />
      <select
        value={filters.competition}
        onChange={(e) => setFilters({ ...filters, competition: e.target.value })}
        className="form-control"
      >
        <option value="">Select Competition</option>
        <option value="low">Low</option>
        <option value="medium">Medium</option>
        <option value="high">High</option>
      </select>
      <input
        type="text"
        placeholder="Keyword Contains"
        value={filters.keywordContain}
        onChange={(e) => setFilters({ ...filters, keywordContain: e.target.value })}
        className="form-control"
      />
       <Button variant="secondary" className="float-right shadow-none" onClick={clearAllFilters}>
Clear All Filters
</Button>
<Button variant="primary" onClick={applyFilters} className="mt-2 d-none">
      Apply Filters
    </Button>
    </div>
  
   
  </div>
)}
</div>
          <Table responsive hover className="results-table">
          <thead>
  <tr>
    <th>Keyword</th>
    <th onClick={() => handleSort('volume')}>
      Volume {sortField === 'volume' && (sortDirection === 'asc' ? <ArrowUp /> : <ArrowDown />)}
    </th>
    <th onClick={() => handleSort('competition')}>
      Competition {sortField === 'competition' && (sortDirection === 'asc' ? <ArrowUp /> : <ArrowDown />)}
    </th>
    <th>Trend</th>
    <th>Low Bid</th>
    <th>High Bid</th>
  </tr>
</thead>

          <tbody>
  {getPaginatedResults().map((item, index) => (
    <tr key={index}>
      <td>{item.text}</td>
      <td>{item.volume}</td>
      <td className={getCompetitionColor(item.competition_level)}>
        {item.competition_level}
      </td>
      <td>{item.trend}</td>
      <td>${item.low_bid.toFixed(2)}</td>
      <td>${item.high_bid.toFixed(2)}</td>
    </tr>
  ))}
</tbody>

        </Table>

          <div className="pagination-container">
            <Pagination>
              <Pagination.First 
                onClick={() => setCurrentPage(1)} 
                disabled={currentPage === 1}
              />
              <Pagination.Prev 
                onClick={() => setCurrentPage(p => p - 1)} 
                disabled={currentPage === 1}
              />
              
              {[...Array(totalPages)].map((_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              )).slice(
                Math.max(0, currentPage - 3),
                Math.min(totalPages, currentPage + 2)
              )}

              <Pagination.Next 
                onClick={() => setCurrentPage(p => p + 1)} 
                disabled={currentPage === totalPages}
              />
              <Pagination.Last 
                onClick={() => setCurrentPage(totalPages)} 
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      )}
    </Container>
  );
};

export default KeywordResearch;